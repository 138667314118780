import Alert from '@attendance-web-app/components/Common/Alert';
import axios from 'axios';

export const downloadCsv = (date: string, department: string, name: string, period: string) => {
  axios
    .get(`/attendance`, {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv'
      },
      params: {
        date: date,
        department: department,
        name: name,
        period: period
      }
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'EmployeeAttendance.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Alert('success', 'CSV file has been downloaded successfully');
    })
    .catch(error => {
      Alert('error', error.message);
    });
};

export const downloadIndividualCsv = (
  date: string,
  department: string,
  name: string,
  period: string,
  employeeId: string
) => {
  axios
    .get(`/attendance/${employeeId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv'
      },
      params: {
        date: date,
        department: department,
        name: name,
        period: period
      }
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'EmployeeAttendance.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Alert('success', 'CSV file has been downloaded successfully');
    })
    .catch(error => {
      Alert('error', error.message);
    });
};
