import { Icons } from '@attendance-web-app/assets/Icons/Icons';

interface LoaderInterface {
  isLoading?: boolean;
  children?: React.ReactNode;
  isAbsolute?: boolean;
}

const LoadingScreen = ({ isLoading, children, isAbsolute = true }: LoaderInterface) => {
  if (isLoading) {
    return (
      <div className={`loading-screen ${isAbsolute ? 'positionAbsolute' : ''}`}>
        <Icons.Loading height={50} width={50} />
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

export const NewLoadingScreen = ({ isLoading, children, isAbsolute = true }: LoaderInterface) => {
  return (
    <>
      {isLoading && (
        <div className={`loading-screen ${isAbsolute ? 'positionAbsolute' : ''}`}>
          <Icons.Loading height={50} width={50} />
        </div>
      )}
      {children}
    </>
  );
};
export default LoadingScreen;
