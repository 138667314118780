import CommonDetails from '@attendance-web-app/components/Common/CommonDetails';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import DepartmentDetail from '@attendance-web-app/components/_pages/_DepartmentSummary/DepartmentDetail';
import SubDepartmentTable from '@attendance-web-app/components/_pages/_SubDepartment/SubDepartmentTable';
import { useAddSubDepartment } from '@attendance-web-app/components/_pages/_SubDepartment/hooks/useAddSubDepartment';
import { useManageSubDepartmentList } from '@attendance-web-app/components/_pages/_SubDepartment/hooks/useManageSubDepartmentList';
import { useUpdateSubDepartment } from '@attendance-web-app/components/_pages/_SubDepartment/hooks/useUpdateSubDepartment';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import { IDepartment } from '@attendance-web-app/types/department/type';
import { changeNestedDepartmentToActual } from '@attendance-web-app/utils';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
interface DefaultValues {
  subDepartmentName: string;
  subDepartmentId: number | null;
}
export default function SubDepartmentList() {
  const { departmentId, departmentName } = useParams();

  const location = useLocation();
  const { subDepartmentListData, subDepartmentLoading, refetchSubDepartmentList } = useManageSubDepartmentList(
    departmentId?.toString() || ''
  );
  const { ...department } = location.state as IDepartment;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm<DefaultValues>({
    defaultValues: {
      subDepartmentName: '',
      subDepartmentId: null
    }
  });
  const onSuccessHandler = () => {
    setValue('subDepartmentId', null);
    setValue('subDepartmentName', '');
  };

  const { mutate: addSubDepartment } = useAddSubDepartment(departmentId?.toString() || '', onSuccessHandler);
  const { mutate: updateSubDepartment } = useUpdateSubDepartment(departmentId?.toString() || '', onSuccessHandler);

  const { openAdvanceFilter, showAdvancedFilter, cancelAdvancedFilter } = useAdvanceFilterSidebar();

  const onSearchChange = (searchSubDepartmentByName: string) => {
    refetchSubDepartmentList({
      name: searchSubDepartmentByName ?? '',
      departmentId,
      limit: 30
    });
  };
  const onPressAdd = () => {
    openAdvanceFilter();
  };
  const onCancelHandler = () => {
    cancelAdvancedFilter();
    onSuccessHandler();
  };

  const onPressAddSubDepartment = (data: any) => {
    const { subDepartmentName, subDepartmentId } = data;
    if (!departmentId) {
      return;
    }
    if (!subDepartmentId && !subDepartmentId) {
      const payload = {
        name: subDepartmentName,
        departmentId: +departmentId,
        isActive: true
      };
      addSubDepartment(payload);
    }
    if (subDepartmentId) {
      const payload = {
        name: subDepartmentName,
        subDepartmentId
      };
      updateSubDepartment(payload);
    }
    cancelAdvancedFilter();
  };

  return (
    <CommonDetails
      detailsComponent={
        <DepartmentDetail
          name={department?.name || ''}
          hodName={department?.hod?.employee?.name || ''}
          hodEmail={department?.hod?.employee?.email || ''}
          departmentId={department?.departmentIdNo || ''}
          hodDesignation={department?.hod?.employee?.designation?.name || ''}
          employeeCount={department?.employeeCount || 0}
          subDepartmentCount={department?.subDepartmentCount || 0}
        />
      }
      title="Sub Department Lists"
      onSearchChange={onSearchChange}
      onPressAdd={onPressAdd}
      addButtonTitle="Add Sup Department"
    >
      <SubDepartmentTable
        departmentIdNo={department?.departmentIdNo}
        isSubDepartmentLoading={subDepartmentLoading}
        subDepartmentListData={subDepartmentListData}
        setSelectedDepartment={setValue}
        openAdvanceFilter={openAdvanceFilter}
      />
      {showAdvancedFilter && (
        <CustomModal
          showModal={showAdvancedFilter}
          modalTitle={'Add Sub Department'}
          handleHideModal={onCancelHandler}
          footerComponent={
            <>
              <button className="btn-tertiary" onClick={onCancelHandler}>
                Cancel
              </button>
              <button className="btn " form="add-new-sub-department">
                {getValues('subDepartmentId') ? 'Edit Sub Department' : 'Add Sub Department'}
              </button>
            </>
          }
        >
          <CustomMultiDropDownSelect
            isEditable={false}
            title="Select Department"
            options={[]}
            selectedOptions={[
              {
                label: changeNestedDepartmentToActual(departmentName) ?? '',
                value: departmentId ?? ''
              }
            ]}
            onChangeSelectHandler={() => {}}
          />
          <form onSubmit={handleSubmit(onPressAddSubDepartment)} id="add-new-sub-department">
            <Form.Group className="col-12 form-group-name mt-4">
              <Form.Control
                type="text"
                placeholder="Enter sub department name."
                {...register('subDepartmentName', {
                  required: true,
                  validate: value => value.length > 4 || 'Invalid sub department name.',
                  pattern: {
                    value: /^[a-zA-Z\s&.\-]{4,}$/,
                    message: 'Sub Department Name: Invalid format'
                  }
                })}
                isInvalid={!!errors.subDepartmentName}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-name   ${errors.subDepartmentName ? 'text-danger' : ''}`}
              >
                Sub Department Name<span className="asteriskVendor">*</span>
              </label>
              {errors.subDepartmentName && <ErrorLabel message={errors.subDepartmentName.message || 'Required'} />}
            </Form.Group>
          </form>
        </CustomModal>
      )}
    </CommonDetails>
  );
}
