import { getEmployeeList } from '@attendance-web-app/pages/employeeList/hooks/useEmployeeApiHook';
import { IEmployee } from '@attendance-web-app/types/employee/type';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { IParams } from '@attendance-web-app/utils/type';

interface useManageEmployeeListProps {
  employeeList: {
    employee: IEmployee[];
    totalPages: number;
    totalCount: number;
  };
  refetchEmployeeList: (refetchParams: IParams) => Promise<any>;
}
export const useManageEmployeeList = ({
  departmentId,
  designationId
}: {
  departmentId?: string;
  designationId?: string;
}): useManageEmployeeListProps => {
  const { data: employeeList, refetchQuery: refetchEmployeeList } = useCustomListApi({
    queryKey: ['employeeList', departmentId ?? ''],
    getApiFn: getEmployeeList,
    enabled: false,
    params: {
      name: '',
      department: departmentId,
      designation: designationId,
      limit: 10,
      offset: 0
    }
  }) as any;

  return {
    employeeList,
    refetchEmployeeList
  };
};
