import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { MouseEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Drawer = ({ open, handleClose }: { open: boolean; handleClose: MouseEventHandler<HTMLDivElement> }) => {
  const sideDrawerClass = ['side_drawer'];

  if (open) {
    sideDrawerClass.push('show');
  }

  const navigate = useNavigate();
  const location = useLocation();

  const [activeButton, setActiveButton] = useState(() => {
    if (location.pathname === '/employee-list') return 'employee';

    return 'attendance';
  });

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const isButtonActive = (buttonName: string) => {
    return buttonName === activeButton;
  };

  return (
    <div className={sideDrawerClass.join(' ')}>
      <div className="close" onClick={handleClose}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L7.58579 9L0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L9 10.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L10.4142 9L17.7071 1.70711Z"
            fill="#595959"
          />
        </svg>
      </div>
      <div className="text">
        <button
          className={isButtonActive('attendance') ? 'btn-attendance' : 'btn-attendance-disabled'}
          onClick={() => {
            handleButtonClick('attendance');
            navigate('/dashboard');
          }}
        >
          {isButtonActive('attendance') ? (
            <Icons.Calendar height={24} fill="#2074b9" />
          ) : (
            <Icons.Calendar height={24} fill="#595959" />
          )}
          Attendace Log
        </button>
        <br />
        <button
          className={isButtonActive('employee') ? 'btn-attendance' : 'btn-attendance-disabled'}
          onClick={() => {
            handleButtonClick('employee');
            navigate('/employee-list');
          }}
        >
          {isButtonActive('employee') ? (
            <Icons.Users height={24} stroke="#2074B9" />
          ) : (
            <Icons.Users height={24} stroke="#595959" />
          )}
          Employee List
        </button>
      </div>
    </div>
  );
};

export default Drawer;
