import { IgetHodListArgs, IgetHodListResponse } from '@attendance-web-app/types/hod/type';
import axios, { AxiosResponse } from 'axios';
export const getHodList = async (params?: IgetHodListArgs): Promise<AxiosResponse<IgetHodListResponse, Error>> => {
  try {
    const response = await axios.get('/hod', {
      params: {
        ...params,
        name: params?.name ?? '',
        limit: params?.limit ?? 10,
        offset: params?.offset ?? 0
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
const getspecificHod = async (name: string): Promise<AxiosResponse<IgetHodListResponse, Error>> => {
  return axios.get(`/hod`, { params: { name } });
};

export default {
  getHodList,
  getspecificHod
};
