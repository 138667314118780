import {
  FiltersActionType,
  SetDateAction,
  SetDepartmentAction,
  SetSearchAction,
  SetTimePeriodAction,
  setLimitAction,
  setOffsetAction
} from './filtersAction.d';

export const SetDate = (payload: string): SetDateAction => ({
  type: FiltersActionType.setHeaderDate,
  payload
});

export const SetDepartment = (payload: string): SetDepartmentAction => ({
  type: FiltersActionType.setHeaderDepartment,
  payload
});

export const SetSearch = (payload: string): SetSearchAction => ({
  type: FiltersActionType.setHeaderSearch,
  payload
});

export const SetTimePeriod = (payload: string): SetTimePeriodAction => ({
  type: FiltersActionType.setTimePeriod,
  payload
});
export const setContextLimit = (payload: number): setLimitAction => ({
  type: FiltersActionType.setLimit,
  payload
});
export const setContextOffset = (payload: number): setOffsetAction => ({
  type: FiltersActionType.setOffset,
  payload
});

export const setApplyFilter = (payload: { date: string; timePeriod: string }) => {
  return {
    type: FiltersActionType.setApplyFilter,
    payload
  };
};
