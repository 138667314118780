import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import { SpendingAdvanceFilterProps } from '@attendance-web-app/types/spendingList/type';
import React from 'react';

const SpendingAdvanceFilter: React.FC<SpendingAdvanceFilterProps> = ({
  onApplyHandler,
  onChangeSelectHandler,
  selectedDepartmentTypes = [],
  departmentTypes,
  designationTypes,
  vendors,
  selectedVendorTypes = [],
  selectedDesignationTypes = [],
  cancelAdvancedFilter,
  showAdvancedFilter,
  openAdvanceFilter
}) => {
  const hideSidebar = () => {
    cancelAdvancedFilter();
  };
  const onApplyMainHandler = () => {
    onApplyHandler();
    cancelAdvancedFilter();
  };

  return (
    <CustomModal
      showModal={showAdvancedFilter}
      handleMenuOpen={openAdvanceFilter}
      handleHideModal={hideSidebar}
      modalTitle="Advance Filter"
      footerComponent={
        <>
          <button className="btn-tertiary" onClick={hideSidebar}>
            Cancel
          </button>
          <button
            className="btn "
            onClick={onApplyMainHandler}
            disabled={
              selectedDepartmentTypes.length <= 0 &&
              selectedDesignationTypes.length <= 0 &&
              selectedVendorTypes.length <= 0
            }
          >
            Apply Filter
          </button>
        </>
      }
    >
      <div className="advance-filter-content attendanceAdvanceFilter">
        <div className="search-content w-100">
          <CustomMultiDropDownSelect
            title="Select Department"
            options={departmentTypes}
            selectedOptions={selectedDepartmentTypes}
            onChangeSelectHandler={onChangeSelectHandler}
          />
          <CustomMultiDropDownSelect
            title="Select Designation"
            options={designationTypes}
            selectedOptions={selectedDesignationTypes}
            isEditable={(selectedDepartmentTypes?.length as number) > 0}
            onChangeSelectHandler={onChangeSelectHandler}
          />
          <CustomMultiDropDownSelect
            title="Select Vendor"
            options={vendors}
            selectedOptions={selectedVendorTypes}
            onChangeSelectHandler={onChangeSelectHandler}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default SpendingAdvanceFilter;
