import { useState } from 'react';

export const useShowMobileHeader = () => {
  const [openMobileHeader, setOpenMobileHeader] = useState(false);
  const handleMobileHeaderOpen = () => {
    setOpenMobileHeader(true);
    document.body.style.overflowY = 'hidden';
  };

  const handleMobileHeaderClose = () => {
    setOpenMobileHeader(false);
    document.body.style.overflowY = 'auto';
  };

  return {
    openMobileHeader,
    handleMobileHeaderOpen,
    handleMobileHeaderClose
  };
};
