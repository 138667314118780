import { Option } from '@attendance-web-app/global-state/reducers/attendanceReducer.d';

interface fetchLogsPayload {
  filterStateDate?: string;
  search?: string;
  limit?: number;
  offset?: number;
  departmentId?: number;
  role?: number | null;
  actionTypes: string;
}

export const getFormattedTimeFromDate = (timestamp: string | null): string => {
  if (!timestamp) {
    return '--:--';
  }
  const date = new Date(timestamp);
  const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const minutes = date.getMinutes().toString().length > 1 ? date.getMinutes() : '0' + date.getMinutes();
  const meridiem = date.getHours() > 12 ? 'PM' : 'AM';
  return `${hour}:${minutes} ${meridiem}`;
};

export const checkIfListIsNotEmpty = (list?: any) => {
  return list && list?.length > 0;
};
export const getIosStringSliceDate = (inputDate?: string) => {
  const finalDate =
    (inputDate && new Date(inputDate).toISOString().slice(0, 10)) ?? new Date().toISOString().slice(0, 10);

  return finalDate;
};
export const getTimePeriod = (timePeriod?: string) => {
  switch (timePeriod) {
    case 'week':
      return 'Weekly';
    case 'month':
      return 'Monthly';
    default:
      return 'Daily';
  }
};
export const getTimePeriodForPayload = (timePeriod?: string) => {
  switch (timePeriod) {
    case 'Weekly':
      return 'week';
    case 'Monthly':
      return 'month';
    case 'Daily':
      return 'daily';
    default:
      return 'daily';
  }
};

export const getSelectOptionTypeEnumFromTitle = (title: string) => {
  switch (title) {
    case 'Select Type':
      return 'LOG_TYPE';
    case 'Select Department':
      return 'DEPARTMENT_TYPE';
    case 'Select Designation':
      return 'DESIGNATION_TYPE';
    case 'Select HOD':
      return 'HOD_TYPE';
    case 'Select Supervisor':
      return 'SUPERVISOR_TYPE';
    default:
      return '';
  }
};
export const onOptionCheckboxChange = (selectedOptions: Option[], option: Option) => {
  const selectedIndex = selectedOptions?.findIndex((selectedOption: Option) => selectedOption?.value === option.value);
  let newSelectedOptions = [];
  if (selectedIndex > -1) {
    newSelectedOptions = [...selectedOptions?.slice(0, selectedIndex), ...selectedOptions.slice(selectedIndex + 1)];
  } else {
    newSelectedOptions = [...selectedOptions, option];
  }

  return newSelectedOptions;
};
