import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import BalanceSheetCard from '@attendance-web-app/components/_pages/_BalanceSheet/BalanceSheetCard';
import BalanceSheetHeader from '@attendance-web-app/components/_pages/_BalanceSheet/BalanceSheetHeader';
import BalanceSheetTable from '@attendance-web-app/components/_pages/_BalanceSheet/BalanceSheetTable';
import { formatAmountToStandardFormat } from '@attendance-web-app/utils';
import { useBalanceSheet } from './hooks/useBalanceSheet';

const BalanceSheet = () => {
  const { yearlyTransaction, monthlyTransaction, onYearChange, isLoading, handleExport, year }: any = useBalanceSheet();

  const yearlyTransactionData = yearlyTransaction?.data ?? {
    remainingBalance: 0,
    totalPaid: 0,
    totalTransactions: 0
  };
  const monthlyTransactionData = monthlyTransaction?.data ?? [
    { month: 'January', totalTransactions: 0, totalPaid: 0, remainingBalance: 0, payments: [] }
  ];

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      <BalanceSheetHeader onYearChange={onYearChange} handleExport={handleExport} year={year} />

      {/* Transaction Summary cards*/}
      <div className="balance-sheet-cardContainer">
        <BalanceSheetCard
          title="Total Transaction"
          amount={formatAmountToStandardFormat(yearlyTransactionData.totalTransactions) as any}
          icon={<Icons.transactionIcon height={24} width={24} />}
        />
        <BalanceSheetCard
          title="Total Paid to Vendor"
          amount={formatAmountToStandardFormat(yearlyTransactionData.totalPaid) as any}
          icon={<Icons.paidToVendorIcon height={24} width={24} />}
        />
        <BalanceSheetCard
          title="Balance to pay"
          amount={formatAmountToStandardFormat(yearlyTransactionData.remainingBalance) as any}
          icon={<Icons.payToVendorIcon height={24} width={24} />}
        />
      </div>

      {/* Transaction history table*/}
      <div className="balance-sheet-table">
        <BalanceSheetTable monthlyTransactionData={monthlyTransactionData} />
      </div>
    </>
  );
};

export default BalanceSheet;
