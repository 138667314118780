import Alert from '@attendance-web-app/components/Common/Alert';
export const signOutHelper = () => {
  localStorage.removeItem('admin_user');
  sessionStorage.removeItem('access_token');
  window.location.href = '/login';
};
export const handleErrorMessage = (error: any) => {
  if (error.code === 'ERR_NETWORK') {
    Alert('error', 'Check your network connection');
  } else if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error?.response?.status === 401) {
      signOutHelper();
    } else if (error?.response?.data?.message) {
      Alert('error', error.response.data.message);
    } else {
      if (error?.message) {
        Alert('error', error?.message);
      }
    }
    return;
  } else if (error?.message) {
    Alert('error', error?.message);
  }
};
