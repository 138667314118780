import React from 'react';

interface PropsType {
  message: string;
}
const ErrorLabel: React.FC<PropsType> = ({ message }) => {
  return <div className="invalid-feedback text-capitalize">{message}</div>;
};

export default ErrorLabel;
