import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { IDesignationListHeaderProps } from '@attendance-web-app/types/designation/type';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import CustomSearchBar from '../_header/LogSearchBar';
import DesignationAddModal from './DesignationAddModal';
import DesignationUploadError from './DesignationUploadErrorModal';

const DesignationListHeader = ({
  departmentDetails,
  fetchDesignationData,
  setIsDesignationAdded,
  setSearchKeyword
}: IDesignationListHeaderProps) => {
  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState('');
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [errorData, setErrorData] = useState([]);

  const onSearchChange = (searchValue: string = '') => {
    fetchDesignationData(searchValue);
    setSearchKeyword(searchValue);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="designation-list-header">
        <div className="designation-list-page d-flex justify-content-between">
          <div className="designation-list-search">
            <CustomSearchBar onChangeSearchHandler={onSearchChange} hasIcon={false} />
          </div>
          <button className="btn invite-button  body-large-regular" onClick={() => setModalShow(true)}>
            <Icons.PlusIcon height={16} width={16} />
            <span className="body-regular invite-text"> Add Designation </span>
          </button>

          <DesignationAddModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            departmentId={departmentDetails.departments[0].id}
            setSuccessMsg={setSuccessMsg}
            setUploadErrorMsg={setUploadErrorMsg}
            setErrorData={setErrorData}
            setIsDesignationAdded={setIsDesignationAdded}
            fetchDesignationData={fetchDesignationData}
          />
        </div>
      </div>
      {successMsg && (
        <Alert
          variant="success"
          dismissible
          onClose={() => setSuccessMsg('')}
          className="d-flex justify-content-between mx-3 body-large-regular"
          style={{ width: '97%' }}
        >
          <span>
            <Icons.Check height={24} /> &nbsp; {successMsg}
          </span>
          <span className="upload-error-msg">
            {uploadErrorMsg} &nbsp; &nbsp;
            <span className="btn-inline  alert-view-details" onClick={handleOpenModal}>
              View details
            </span>
            {showModal && (
              <DesignationUploadError
                handleCloseModal={handleCloseModal}
                showModal={showModal}
                uploadErrorData={errorData}
              />
            )}
          </span>
        </Alert>
      )}
    </>
  );
};

export default DesignationListHeader;
