import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { primaryFuseMachines } from '@attendance-web-app/assets/logo';
import { isAdminUser } from '@attendance-web-app/utils';
import { Icon } from '@iconify/react';
import { useNavbarMainHook } from '../hooks/useNavbarMainHook';
import RedirectSchedulerModal from './RedirectSchedulerModal';
import SignOut from './Signout';

const NavbarMain = ({ navBarTitle = '' }: { isSidebarOpen?: boolean; hasLeft?: boolean; navBarTitle?: string }) => {
  const {
    navigate,
    adminUser,
    handleMenuToggle,
    userRole,
    isMenuOpen,
    signOut,
    userDetails,
    dropdownRef,
    onSchedulerClickHandler,
    isSchedulerOpen
  } = useNavbarMainHook();

  const onArrowLeftClickHandler = () => {
    navigate(-1);
  };
  return (
    <nav className={`navbarMain`} ref={dropdownRef}>
      {userDetails?.role?.role !== 'individual' && (
        <img
          src={primaryFuseMachines}
          className={`navbarMain__logo`}
          style={
            navBarTitle
              ? {
                  display: 'none'
                }
              : {}
          }
        />
      )}
      <div className="navbarMain__left">
        {navBarTitle ? (
          <>
            <Icon
              icon="ph:arrow-left-bold"
              height={25}
              width={25}
              onClick={onArrowLeftClickHandler}
              style={{ cursor: 'pointer' }}
            />
            <p className="navbarTitle body-regular">{navBarTitle === 'null' ? '' : navBarTitle}</p>
          </>
        ) : userDetails?.role?.role === 'individual' ? (
          <img src={primaryFuseMachines} className={`sidebarMain__imageContainer--image `} />
        ) : (
          <div />
        )}
      </div>

      <div className="navbarMain__right">
        <div className="navbarMain__bellIcon ">
          {isAdminUser() ? (
            <div className="scheduler">
              <Icons.clockIcon height={21} width={21} onClick={onSchedulerClickHandler} style={{ cursor: 'pointer' }} />
              {isSchedulerOpen ? <RedirectSchedulerModal /> : null}
              {/* <div className={`status ${statusData?.data?.running ? 'runningColor' : 'stoppedColor'}`} /> */}
            </div>
          ) : null}
          {/* <Icons.bellIcon height={21} width={21} onClick={onNotificationClickHandler} style={{ cursor: 'pointer' }} /> */}
        </div>

        <div className="navbarMain__avatarContainer" onClick={handleMenuToggle}>
          <div className="navbarMain__avatarContainer--avatar">
            <img src={adminUser?.picture} alt="image" />
          </div>

          <div className="navbarMain__avatarContainer--user mobile">
            <p className="userName body-regular">
              {adminUser?.given_name} {adminUser?.family_name}
            </p>
            <div className="action">
              <p className="body-small-regular">{userRole}</p>
              <Icons.arrowDown />
            </div>
          </div>
        </div>
      </div>

      {isMenuOpen ? <SignOut adminUser={adminUser} userRole={userRole} signOut={signOut} /> : null}
      {/* <SchedulerModalContainer isSchedulerOpen={isSchedulerOpen} onSchedulerClickHandler={onSchedulerClickHandler} /> */}
    </nav>
  );
};

export default NavbarMain;
