import { AttendanceAction, AttendanceActionType } from '../actions/attendanceAction.d';
import { IEmployeeAttendance } from './attendanceReducer.d';

export const initialAttendanceReducerState: IEmployeeAttendance = {
  selectedAttendanceList: [],
  attendance: [],
  totalCount: 0,
  totalPages: null,
  departmentSelectedOption: {
    selected: '',
    value: []
  },
  designationSelectedOption: {
    selected: '',
    value: []
  },
  hodSelectedOption: {
    selected: '',
    value: []
  },
  supervisorSelectedOption: {
    selected: '',
    value: []
  },
  shiftSelectedOption: {
    value: '',
    label: ''
  },
  hodTypes: [],
  departmentTypes: [],
  designationTypes: [],
  paramsValue: {
    date: '',
    name: '',
    limit: 10,
    offset: 0,
    department: '',
    period: '',
    designation: '',
    hod: '',
    supervisor: '',
    shift: '',
    dropOffOption: ''
  }
};

export const attendanceReducer = (state: IEmployeeAttendance, action: AttendanceAction): IEmployeeAttendance => {
  switch (action.type) {
    case AttendanceActionType.Add:
      return {
        ...state,
        ...action.payload
      };
    case AttendanceActionType.SAVE_PARAMS:
      return {
        ...state,
        paramsValue: {
          ...state.paramsValue,
          ...action.payload
        }
      };
    case AttendanceActionType.RESET_CONTEXT:
      return {
        ...state,
        departmentSelectedOption: {
          selected: '',
          value: []
        },
        designationSelectedOption: {
          selected: '',
          value: []
        },
        hodSelectedOption: {
          selected: '',
          value: []
        },
        supervisorSelectedOption: {
          selected: '',
          value: []
        },
        hodTypes: [],
        departmentTypes: [],
        designationTypes: [],
        shiftSelectedOption: {
          value: '',
          label: ''
        },
        selectedDropOffServices: {
          value: '',
          label: ''
        },
        paramsValue: {
          ...state.paramsValue,
          department: '',
          designation: '',
          hod: '',
          supervisor: '',
          shift: '',
          dropOffOption: ''
        }
      };

    default:
      return state;
  }
};
