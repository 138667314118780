import Alert from '@attendance-web-app/components/Common/Alert';
import { ISpendingTransaction } from '@attendance-web-app/services/spendingList.services';
import { Icon } from '@iconify/react';
import React from 'react';
import { downloadCsv, generateSpendingTransactionsCsvContent } from './hooks/csvUtils';

interface DownloadSpendingViewCsvProps {
  selectedSpendingTransactions: ISpendingTransaction[];
  disabled: boolean;
}

const DownloadSpendingViewCsv: React.FC<DownloadSpendingViewCsvProps> = ({
  selectedSpendingTransactions,
  disabled
}) => {
  const handleDownloadCsv = () => {
    const csvContent = generateSpendingTransactionsCsvContent(selectedSpendingTransactions);
    try {
      downloadCsv(csvContent, 'spendingRecord.csv');
      Alert('success', 'Spending record has been downloaded successfully.');
    } catch (error: any) {
      Alert('error', error?.message);
    }
  };

  return (
    <div>
      {/* Button to trigger CSV download */}
      <button className="btn download-button body-regular" onClick={handleDownloadCsv} disabled={disabled}>
        <span>Export</span>
        <span className="csv-button--icon">
          <Icon icon="material-symbols:download" width={'20px'} height="20px" />
        </span>
      </button>
    </div>
  );
};

export default DownloadSpendingViewCsv;
