import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { primaryFuseMachines, smallLogo } from '@attendance-web-app/assets/logo';

interface ISidebarMainHeader {
  isSidebarOpen: boolean;
  sideBarClose: string;
  sidebarToggleHandler: () => void;
}
const SidebarMainHeader = ({ isSidebarOpen, sideBarClose, sidebarToggleHandler }: ISidebarMainHeader) => {
  return (
    <div className="sidebarMain__imageContainer">
      <img
        src={isSidebarOpen ? primaryFuseMachines : smallLogo}
        className={`sidebarMain__imageContainer--image ${sideBarClose}`}
      />
      <Icons.alignRight height={22} width={22} className="sidebarToggle" onClick={sidebarToggleHandler} />
    </div>
  );
};

export default SidebarMainHeader;
