import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import { Icons } from '@attendance-web-app/assets/Icons/Icons';

const modalStyle = {
  height: '92%',
  top: '8%',
  borderRadius: '5px'
};

function DesignationUploadError({ handleCloseModal, showModal, uploadErrorData }: any) {
  const csvErrorData = uploadErrorData.map((item: any) => ({
    department: item?.department,
    name: item?.name
  }));
  const ErrorTooltip = ({ errorKey, errorMessage }: any) => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{errorMessage}</Tooltip>}>
        <span className="d-flex gap-2">
          {errorKey} <Icons.Alert height={20} width={20} className="me-1" />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} fullscreen style={modalStyle}>
        <Modal.Header closeButton>
          <Modal.Title>{`Upload Failed (${uploadErrorData.length})`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table borderless hover>
            <thead className="body-regular designation-table-header">
              <tr>
                <th className="differentiate-th p-3">Name</th>
                <th className="differentiate-th p-3">Department</th>
                <th className="p-3">Exceptional Error</th>
              </tr>
            </thead>
            <tbody className="body-large-regular">
              {uploadErrorData?.map((item: any) => {
                return (
                  <tr>
                    <td className="employee-id p-3">
                      {item.error && item.error.name ? (
                        <span className="error">
                          <ErrorTooltip errorKey={item.name} errorMessage={item.error.name} />
                        </span>
                      ) : (
                        <span>{item.name}</span>
                      )}
                    </td>
                    <td className="employee-id p-3">
                      {item.error && item.error.department ? (
                        <span className="error">
                          <ErrorTooltip errorKey={item.department} errorMessage={item.error.department} />
                        </span>
                      ) : (
                        <span>{item.department}</span>
                      )}
                    </td>
                    <td className="employee-id p-3">
                      {item.error && item.error.exceptionalError && (
                        <span className="error">{item.error.exceptionalError}</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="d-flex gap-3">
          <CSVLink
            data={csvErrorData}
            className="btn-outline"
            onClick={handleCloseModal}
            filename={'designation_error_data.csv'}
          >
            Download CSV
          </CSVLink>

          <button className="btn designation-csv-donebtn" onClick={handleCloseModal}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DesignationUploadError;
