import { SyntheticEvent } from 'react';

import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useSidebarMainHook } from '../../hooks/useSidebarMainHook';
import { sidebarItems } from '../commonData';
import { useCloseAbsoluteDiv } from '../hooks/useCloseAbsoluteDiv';
import SidebarMainHeader from './SidebarMainHeader';
import SidebarMainItem from './SidebarMainItem';
import SidebarMainSubItem from './SidebarMainSubItem';

export default function MainSidebar() {
  const {
    sideBarClose,
    isSidebarOpen,
    onClose,
    sidebarToggleHandler,
    activeSidebar,
    onMouseEnterAndLeave,
    sidebarItemClickHandler,
    sidebarSubItemClickHandler,
    activeSidebarSubItem,
    onClickHandler,
    mobileSidebar
  } = useSidebarMainHook();
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);

  return (
    <>
      <div className="navbar__sidebarIcon" onClick={onClickHandler}>
        <Icons.alignRight height={22} width={22} className="sidebarToggle" />
      </div>

      <div className={`backdrop ${mobileSidebar ? 'isOpened' : ''}`} onClick={onClickHandler} />
      <div
        ref={dropdownRef}
        className={`sidebarMain body-large-semibold ${sideBarClose} ${mobileSidebar ? 'mobileView' : ''}`}
      >
        <SidebarMainHeader
          isSidebarOpen={isSidebarOpen}
          sideBarClose={sideBarClose}
          sidebarToggleHandler={sidebarToggleHandler}
        />

        {sidebarItems.map((item, index) => {
          let isActive = activeSidebar === item.title;
          let childLength = item.child.length;
          let isChildLengthGreaterThanZero = childLength > 0;
          return (
            <div
              key={index}
              className="sidebarMain__subContainer"
              onMouseEnter={(event: SyntheticEvent) => {
                onMouseEnterAndLeave(event, item);
              }}
              onMouseLeave={(event: SyntheticEvent) => {
                onMouseEnterAndLeave(event, item);
              }}
            >
              <SidebarMainItem
                item={item}
                sidebarItemClickHandler={sidebarItemClickHandler}
                sideBarClose={sideBarClose}
                isActive={isActive}
                isChildLengthGreaterThanZero={isChildLengthGreaterThanZero}
                childLength={childLength}
                activeSidebar={activeSidebar}
              />

              {/* SubItem component */}
              {isChildLengthGreaterThanZero && (
                <SidebarMainSubItem
                  childLength={childLength}
                  item={item}
                  sidebarItemClickHandler={sidebarItemClickHandler}
                  sidebarSubItemClickHandler={sidebarSubItemClickHandler}
                  activeSidebarSubItem={activeSidebarSubItem}
                  sideBarClose={sideBarClose}
                  isActive={isActive}
                  isChildLengthGreaterThanZero={isChildLengthGreaterThanZero}
                  activeSidebar={activeSidebar}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
