export const colors = {
  colorP01: '#004278',
  colorP02: '#0058a0',
  colorP03: '#2f78b4',
  colorP04: '#669dca',
  colorP05: '#c2def5',
  colorP06: '#f3f9fd',

  //Secondary Color
  colorS01: '#d0a600',
  colorS02: '#ffcd03',
  colorS03: '#fed637',
  colorS04: '#ffe065',
  colorS05: '#ffea94',
  colorS06: '#fff3c1',

  //GrayScales Colo"r
  colorG01: '#030900',
  colorG02: '#11190c',
  colorG03: '#7c807a',
  colorG04: '#b8bbb6',
  colorG05: '#edeeec',
  colorG06: '#ffffff',

  //error
  errorColor: '#F51414'
};
