import Alert from '@attendance-web-app/components/Common/Alert';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import departmentServices from '@attendance-web-app/services/department.services';
import employeeServices from '@attendance-web-app/services/employee.services';
import { IcreateDepartmentBody } from '@attendance-web-app/types/department/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';

interface IHodDropdown {
  label: string;
  id: number;
  image: string;
}
interface IAddDepartmentForms {
  departmentId: string;
  departmentName: string;
  hod: IHodDropdown;
}
const AddDepartment = ({ ...props }: any) => {
  const [optionsnew, setOptionsnew] = useState<any>();
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [typeSuperVisor, setTypedSuperVisor] = useState('');

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<IAddDepartmentForms>();
  useEffect(() => {
    getEmployees();
  }, [typeSuperVisor]);

  const getEmployees = async () => {
    try {
      const employeeListArgs = {
        name: typeSuperVisor,
        department: '',
        limit: 2,
        offset: 0
      };
      const hod = await employeeServices.employeeList(employeeListArgs);
      const hodData = hod.data.employee.map(employee => {
        return {
          label: employee.name,
          email: employee.email,
          image: employee.profilePhoto,
          id: employee.id,
          employeeIdNo: employee.employeeIdNo
        };
      });
      setOptionsnew(hodData);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const handleAddDepartment: SubmitHandler<IAddDepartmentForms> = async data => {
    try {
      const createDepartmentBody: IcreateDepartmentBody = {
        departmentIdNo: data.departmentId,
        name: data.departmentName,
        isActive: true
      };
      if (data.hod) {
        createDepartmentBody.hodId = data.hod.id;
      }
      await departmentServices.createDepartment(createDepartmentBody);
      Alert('success', 'New Department has been created successfully.');
      props.fetchAttendanceData();
      props.onHide();
    } catch (err: any) {
      err?.response?.data?.message?.map((message: any) => {
        if (message.includes('DepartmentIdNo')) {
          setError('departmentId', { message: message });
        }
        if (message.includes('Department name')) {
          setError('departmentName', { message: message });
        }
        if (message.includes('Hod')) {
          setError('hod', { message: message });
        }
      });
    }
  };

  return (
    <div className="row mt-4">
      <form className="container mt-4" id="add-department-form" onSubmit={handleSubmit(handleAddDepartment)}>
        <Form>
          <div className="row mb-1">
            <Form.Group className="col-6 form-group-name">
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-name ${errors.departmentId ? 'text-danger' : ''}`}
              >
                <span className="select-text invite-designation-hod">
                  {' '}
                  Department ID <span className="form-astrik">*</span>
                </span>
              </label>

              <Form.Control
                type="text"
                placeholder="Departments ID"
                {...register('departmentId', { required: true })}
                isInvalid={!!errors.departmentId}
                autoComplete="off"
                className="form-control "
              />

              {errors.departmentId && <ErrorLabel message={errors.departmentId.message || 'Required'} />}
            </Form.Group>
            <Form.Group className="col-6 form-group-name">
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-name   ${errors.departmentName ? 'text-danger' : ''}`}
              >
                <span className="select-text invite-designation-hod">
                  {' '}
                  Department Name <span className="form-astrik">*</span>
                </span>
              </label>
              <Form.Control
                type="name"
                placeholder="Department Name"
                {...register('departmentName', { required: true })}
                isInvalid={!!errors.departmentName}
                autoComplete="off"
              />

              {errors.departmentName && <ErrorLabel message={errors.departmentName.message || 'Required'} />}
            </Form.Group>
          </div>
        </Form>
        <div className="form-floating col">
          <Controller
            control={control}
            render={({ field: { onChange, name } }) => {
              const handleSelectChange = (selectedOption: any | null) => {
                if (selectedOption === null) {
                  onChange(selectedOption);
                  setValue(name, selectedOption);
                  setOptionsOpen(true);
                  setTypedSuperVisor('');
                }
                if (selectedOption) {
                  onChange(selectedOption);
                  setValue(name, selectedOption);
                  setOptionsOpen(false);
                  setTypedSuperVisor(selectedOption.label);
                }
              };

              const handleInputSupervisor = (valueinput: string) => {
                setTypedSuperVisor(valueinput);
              };

              const Option = (props: any) => {
                const { label, email, image, id, employeeIdNo } = props.data;
                return (
                  <>
                    <div
                      className="d-flex pb-2 pt-2 mb-2 ms-3 border-bottom align-items-center"
                      onClick={() => {
                        handleSelectChange({ label: email, id: id, image: image });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="flex-shrink-0">
                        {image ? (
                          <img src={image} alt={label} className=" round-image " />
                        ) : (
                          <AvatarName name={label ?? ''} />
                        )}
                      </div>
                      <div className="flex-shrink-1 ms-3 mt-1">
                        <div>{label}</div>
                        <div className="department-addHod-email">{email}</div>
                      </div>
                    </div>
                  </>
                );
              };

              return (
                <Select
                  className="select-input"
                  name={name}
                  options={optionsnew}
                  placeholder="- Select HOD -"
                  isSearchable
                  value={getValues(name)}
                  components={{ Option }}
                  onChange={handleSelectChange}
                  onInputChange={handleInputSupervisor}
                  inputValue={typeSuperVisor}
                  noOptionsMessage={() => 'No Hod Found'}
                  isClearable
                />
              );
            }}
            name="hod"
          />
          <label className="select-label" htmlFor="selectdepartment">
            <span className="select-text invite-designation-hod">Select Hod</span>
          </label>
          {errors.hod && <ErrorLabel message={errors.hod.message || 'This field is required'} />}{' '}
        </div>
      </form>
    </div>
  );
};

export default AddDepartment;
