import { OptionType } from '@attendance-web-app/global-state/reducers/logsReducers.d';
import { useEffect, useState } from 'react';
import { onOptionCheckboxChange } from '../_pages/_Dashboard/hooks/utils';
type Option = {
  value: string | number;
  label: string;
  departmentIdNo?: string;
  departmentName?: string;
};
interface IMultiSelectDropDownContainer {
  selectedOptions: Array<Option>;
  options?: Array<Option>;
  isVisible: boolean;
  onChangeSelectHandler: (type: string, options: Array<Option>) => void;
  onLogsTypeResetHandler?: () => void;
  onEmployeeTypeResetHandler?: () => void;
  selectOptionType?: string;
}
const MultiSelectDropDownContainer = ({
  options = [],
  selectedOptions,
  isVisible,
  onChangeSelectHandler,
  selectOptionType = 'logType'
}: IMultiSelectDropDownContainer) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(isVisible ?? false);
  const isOptionSelected = (option: OptionType) =>
    selectedOptions?.some(selectedOption => selectedOption?.value === option.value);

  const onOptionChangeHandler = (option: Option) => {
    const newSelectedOptions = onOptionCheckboxChange(selectedOptions, option);
    if (onChangeSelectHandler && option.value !== 'Select All') {
      onChangeSelectHandler(selectOptionType, newSelectedOptions);
    }
  };

  const onClearAllHandler = () => {
    onChangeSelectHandler(selectOptionType, []);
    setIsDropDownVisible(false);
  };

  const onSelectAllHandler = () => {
    onChangeSelectHandler(selectOptionType, options);
    setIsDropDownVisible(false);
  };
  useEffect(() => {
    setIsDropDownVisible(isVisible ?? false);
  }, [isVisible]);

  return (
    isDropDownVisible &&
    options?.length > 0 && (
      <div className="dropdown-optionsContainer w-100">
        <div className="dropdown-options w-100 py-1 px-1 flex ">
          {options?.map((option: any) => {
            const isSelected = isOptionSelected(option);
            return (
              <label key={option.value} className={`dropdown-option ${isSelected ? 'selected' : ''}`}>
                <input
                  type="checkbox"
                  className="hidden"
                  checked={isSelected}
                  onChange={() => onOptionChangeHandler(option)}
                />
                <span className={`${isSelected ? 'dropdown_selected' : ''}`}>{option.label}</span>
              </label>
            );
          })}
        </div>

        <div className="d-flex justify-content-between dropdown-options-actions">
          <span className="p-2 w-100 color-se1 dropdown-options-clear" onClick={onClearAllHandler}>
            Clear All
          </span>
          <span className="p-2 w-100 dropdown-options-select" onClick={onSelectAllHandler}>
            Select All
          </span>
        </div>
      </div>
    )
  );
};

export default MultiSelectDropDownContainer;
