import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { SetTimePeriod, setApplyFilter } from '@attendance-web-app/global-state/actions/filtersAction';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { downloadIndividualCsv } from '@attendance-web-app/services/download.service';
import { DropdownOption } from '@attendance-web-app/types/employee/type';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import IndividualCalender from './IndividualCalender';
import { IndividualDropDown } from './IndividualDropdown';

const IndividualHeader = () => {
  const { filtersDispatch, filtersState } = useFiltersContext();
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(filtersState.date);
  const { attendanceState, attendanceDispatch } = useAttendanceContext();

  const { timePeriod } = filtersState;

  const filterShow = () => setShow(true);

  const handleDownloadCsv = () => {
    const date = new Date(filtersState.date).toISOString().slice(0, 10);
    const department = filtersState?.Department;
    const name = filtersState?.search;
    const period = filtersState?.timePeriod;
    const id = employeeId || JSON.parse(localStorage.getItem('admin_user') as string).employeeIdNo;

    downloadIndividualCsv(date, department, name, period, id);
  };

  const applyFilters = async () => {
    filtersDispatch(setApplyFilter({ timePeriod: timePeriod, date: date }));
    setShow(false);
  };

  const modalClose = async () => {
    setDate('');
    SetTimePeriod('');
    setShow(false);
  };

  const INDIVIDUAL_PERIODICITY_OPTIONS: DropdownOption[] = [
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' }
  ];

  function onSelection() {
    // Perform action based on selected option here
  }

  return (
    <div>
      <div className="mobile-Individualheader ">
        <div
          className="back-individual-icon icon"
          onClick={() => {
            navigate('/dashboard');
            filtersDispatch(SetTimePeriod('Weekly'));
          }}
        >
          <Icons.BackLeft height={20} stroke="#2074B9" />
          <span className="">Back</span>
        </div>
        <div className="right icon">
          <div className="filter-icon" onClick={filterShow}>
            <Icons.FilterIcon height={24} />
          </div>
          <div className="download-icon">
            <button className="download-responsive-btn" onClick={handleDownloadCsv}>
              <Icons.DownloadingBlack height={24} />
            </button>
          </div>
        </div>
      </div>
      <div className="Modal ">
        <Modal
          show={show}
          onHide={modalClose}
          className="individual-header-modal"
          dialogClassName="custom-modal"
          backdropClassName="custom-backdrop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mobile-dropdown">
              <div className="col mobile-dropdown">
                <IndividualDropDown
                  options={INDIVIDUAL_PERIODICITY_OPTIONS}
                  onSelection={onSelection}
                  timePeriod={timePeriod}
                />
              </div>
              <div className="col mobile-dropdown">
                <IndividualCalender date={date} setDate={setDate} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="clear-button" onClick={modalClose}>
              Cancel
            </button>
            <button className="btn" onClick={applyFilters}>
              Apply filters
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default IndividualHeader;
