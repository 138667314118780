const SubDepartmentDetail = ({
  name: departmentName,
  departmentId,
  employeeCount,
  subDepartmentName,
  designationCount,
  title = 'Sub Department Summary'
}: {
  title?: string;
  name: string;
  departmentId: string;
  employeeCount: number;
  designationCount: number;
  subDepartmentName: string;
}) => {
  return (
    <div className="department-summary">
      <div className="body-large-regular department-summary-header">{title}</div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Department </div>
        <div className="department-summary-detail">{departmentName}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Department ID </div>
        <div className="department-summary-detail">{departmentId}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Sub Department Name </div>
        <div className="department-summary-detail">{subDepartmentName}</div>
      </div>
      {employeeCount !== 0 && (
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> Total number of employees </div>
          <div className="department-summary-detail">{employeeCount}</div>
        </div>
      )}
      {designationCount !== 0 && (
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> Total number of designation </div>
          <div className="department-summary-detail">{designationCount}</div>
        </div>
      )}
    </div>
  );
};
export default SubDepartmentDetail;
