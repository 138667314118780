import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export default function AssociateRoute({ children }: Props): JSX.Element {
  const location = useLocation();
  const userDetails = localStorage.getItem('admin_user') ?? '';

  if (!userDetails) {
    return <Navigate to="/error" state={{ from: location }} />;
  }
  return children as JSX.Element;
}
