import Alert from '@attendance-web-app/components/Common/Alert';
import axios from 'axios';

export const getYearlyTransactionSummary = async (params: { year: number }) => {
  try {
    return await axios.get('/transaction/transaction-summary-by-year', {
      params
    });
  } catch (error: any) {
    Alert('error', error?.response.data.message);
  }
};

export const getMonthlyTransactionSummary = async (params: { year: number }) => {
  try {
    return await axios.get('/transaction/monthly-summaries', {
      params
    });
  } catch (error: any) {
    Alert('error', error?.response.data.message);
  }
};
