import { spendingListReducersProps } from '../reducers/spendingListReducer.d';
import { SpendingListActionEnum, saveSpendingParamsAction, spendingListAction } from './spendingListAction.d';

export const spendingListSuccess = (payload: spendingListReducersProps): spendingListAction => ({
  type: SpendingListActionEnum.SAVE_SPENDING_LIST_DATA,
  payload
});
export const saveSpendingListParamsOnly = (payload: spendingListReducersProps): saveSpendingParamsAction => ({
  type: SpendingListActionEnum.SAVE_SPENDING_LIST_PARAMS_DATA,
  payload
});
