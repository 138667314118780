export enum selectedOptionType {
  PERIOD_OPTION = 'PERIOD_OPTION',
  DATE = 'DATE',
  LOG_TYPE = 'LOG_TYPE',
  DEPARTMENT_TYPE = 'DEPARTMENT_TYPE',
  DESIGNATION_TYPE = 'DESIGNATION_TYPE',
  HOD_TYPE = 'HOD_TYPE',
  SUPERVISOR_TYPE = 'SUPERVISOR_TYPE',
  STATUS_TYPE = 'STATUS_TYPE',
  ROLE_TYPE = 'ROLE_TYPE',
  BANK = ' BANK',
  VENDORS = 'VENDORS',
  SHIFT = 'SHIFT',
  DROP_OFF_SERVICE = 'DROP_OFF_SERVICE'
}
export interface IHandlePaginateClick {
  selected: number;
}
