import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';

const MobileIndividualProfile = ({ employeeRecords }: { employeeRecords: IEmployeeDetails }) => {
  const userRole = JSON.parse(localStorage.getItem('admin_user') as string)?.role;
  const token = localStorage.getItem('user_token');

  const userImg: string | undefined = userRole === 'individual' && token && JSON.parse(token).picture;

  return (
    <div className="individual-profile">
      <img src={userImg || employeeRecords.profilePhoto} alt="employee-pic" className="individual-image" />
      <div className="individual-mobile-detail">
        <div className="individual-text">
          {employeeRecords.name} <span>-{employeeRecords.id}</span>
        </div>
        <div className="profile-text">
          {employeeRecords.department},{employeeRecords.position}
        </div>
        <div className="profile-text">{employeeRecords.mobile}</div>
        <div className="profile-text email-link">{employeeRecords.email}</div>
      </div>
    </div>
  );
};
export default MobileIndividualProfile;
