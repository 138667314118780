import React from 'react';
import { AttendanceProvider } from './context/AttendanceContext';
import { EmployeeProvider } from './context/EmployeeContext';
import { FiltersProvider } from './context/FiltersContext';
import { InitProvider } from './context/InitContext';
import { SaveLogsProvider } from './context/LogsContext';
import { SpendingListProvider } from './context/SpendingListContext';
import { ViewListProvider } from './context/ViewContext';

const ContextStore = ({ children }: { children: React.ReactNode }) => {
  return (
    <SaveLogsProvider>
      <InitProvider>
        <FiltersProvider>
          <AttendanceProvider>
            <EmployeeProvider>
              <SpendingListProvider>
                <ViewListProvider>{children}</ViewListProvider>
              </SpendingListProvider>
            </EmployeeProvider>
          </AttendanceProvider>
        </FiltersProvider>
      </InitProvider>
    </SaveLogsProvider>
  );
};

export default ContextStore;
