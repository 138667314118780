import axios from 'axios';
interface IParams {
  timeStamp?: string;
  name?: string;
  limit?: number;
  offset?: number;
  actionTypes?: string;
}
const getDate = (date: string | Date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const getLogsListApi = async (params?: IParams) => {
  const { timeStamp, name, limit, offset, actionTypes } = params ?? {};
  try {
    const response = await axios.get(`/log`, {
      params: {
        timeStamp: timeStamp ? getDate(timeStamp) : '',
        name: name ?? '',
        limit: limit ?? 10,
        offset: offset ?? 0,
        actionType: actionTypes
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
