import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import ScreenHeader from '@attendance-web-app/components/Common/ScreenHeader';
import { IDepartmentHeaderProps } from '@attendance-web-app/types/department/type';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import CustomSearchBar from '../_header/LogSearchBar';
import DepartmentAddModal from './DepartmentAddModal';
import DepartmentUploadError from './DepartmentUploadErrorModal';

const DepartmentListHeader = ({ fetchDepartmentData, onChangeSearchHandler }: IDepartmentHeaderProps) => {
  const [modalShow, setModalShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [updatedMsg, setUpdatesMsg] = useState('');
  const [errorData, setErrorData] = useState<object[]>([]);
  const [updatedData, setUpdatedData] = useState<object[]>([]);

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ScreenHeader title="Department list">
        <div className="department-list-page">
          <div className="">
            <CustomSearchBar onChangeSearchHandler={onChangeSearchHandler} hasIcon={false} />
          </div>
          <div className="department-list-add" onClick={() => setModalShow(true)}>
            <button className="btn download-button  body-large-regular">
              <Icons.PlusIcon height={16} />

              <span className="body-regular invite-text">Add Department </span>
            </button>
          </div>

          <DepartmentAddModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            setSuccessMsg={setSuccessMsg}
            setUploadErrorMsg={setUploadErrorMsg}
            setErrorData={setErrorData}
            fetchAttendanceData={fetchDepartmentData}
            setUpdatesMsg={setUpdatesMsg}
            setUpdatedData={setUpdatedData}
          />
        </div>
      </ScreenHeader>

      {successMsg && (
        <Alert
          variant="success"
          dismissible
          onClose={() => setSuccessMsg('')}
          className="d-flex justify-content-between mx-3 body-large-regular department-alert"
        >
          <span>
            <Icons.Check height={24} /> &nbsp; {successMsg}
          </span>
          <span className="upload-error-msg">
            {uploadErrorMsg} &nbsp;
            {<Icon icon="material-symbols:circle" width="4px" height="4px" color="red" style={{ margin: '0 5px' }} />}
            &nbsp; {updatedMsg} &nbsp; &nbsp;
            <span className="btn-inline  alert-view-details" onClick={handleOpenModal}>
              View details
            </span>
            {showModal && (
              <DepartmentUploadError
                handleCloseModal={handleCloseModal}
                showModal={showModal}
                uploadErrorData={errorData}
                updatedData={updatedData}
              />
            )}
          </span>
        </Alert>
      )}
    </>
  );
};

export default DepartmentListHeader;
