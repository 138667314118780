import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { getSelectOptionTypeEnumFromTitle } from '@attendance-web-app/utils';
import React, { useMemo, useState } from 'react';

import SelectDropDownContainer from './SelectDropDownContainer';
import { useCloseAbsoluteDiv } from './hooks/useCloseAbsoluteDiv';
type Option = {
  value: string | number;
  label: string;
};
interface ICustomDropDownSelect {
  title?: string;
  selectedOptions?: Option;
  options?: Array<Option>;
  onChangeSelectHandler: (options: Option) => void;
  showDropDownList?: boolean;
  isEditable?: boolean;
  isAbsolute?: boolean;
}
const CustomDropDownSelect = ({
  title = '',
  selectedOptions = {
    label: '',
    value: ''
  },
  options = [],
  onChangeSelectHandler,
  showDropDownList = true,
  isEditable = true,
  isAbsolute = true
}: ICustomDropDownSelect) => {
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);

  useMemo(() => {
    setSearchOptions(options);
  }, [options.length]);
  const onClose = () => {
    setIsOpen(false);
  };
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);
  const onDropdownInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    if (value) {
      const filterData = options.filter(item => item.label.toLowerCase().includes(value?.toLowerCase()?.trim()));
      setSearchOptions(filterData);
    } else {
      setSearchOptions(options);
    }
  };
  const handleClearInput = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearch('');
    setSearchOptions(options);
  };

  return (
    <div className="multi-select-dropdown w-100" ref={dropdownRef}>
      <div
        className={`log-dropdown-header body-large-regular w-100 ${isEditable ? '' : 'disabledClass'}`}
        onClick={() => setIsOpen(isEditable == true ? !isOpen : isEditable)}
      >
        <div className="w-100">
          <label className={`log-select-label invite-employee-select `} id="log-selected-dropdown">
            <span className="select-text body-small-regular px-1 color-g02">{title}</span>
          </label>
          <div className="d-flex align-items-center">
            <input
              value={search}
              onChange={onDropdownInputChange}
              className="log-select-multiple"
              disabled={!isEditable}
              placeholder={selectedOptions.label ? selectedOptions.label : ' - Select -'}
            />
            {search && (
              <span className="px-2">
                <Icons.CrossIcon height={14} stroke="#595959" className="clear-icon" onClick={handleClearInput} />
              </span>
            )}
          </div>
        </div>
        <div className="multiselect-arrow">
          {isOpen ? (
            <Icons.ArrowUp height={20} width={20} stroke="#595959" />
          ) : (
            <Icons.ArrowDown height={20} width={20} stroke="#595959" />
          )}
        </div>
      </div>
      {showDropDownList && (
        <SelectDropDownContainer
          selectOptionType={getSelectOptionTypeEnumFromTitle(title)}
          isVisible={isOpen}
          onClose={onClose}
          options={searchOptions}
          selectedOptions={selectedOptions}
          onChangeSelectHandler={onChangeSelectHandler}
          isAbsolute={isAbsolute}
        />
      )}
    </div>
  );
};
export default CustomDropDownSelect;
