import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { Add } from '@attendance-web-app/global-state/actions/attendanceAction';
import { SetDepartment } from '@attendance-web-app/global-state/actions/filtersAction';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import attendanceServices from '@attendance-web-app/services/attendance.services';
import { downloadCsv } from '@attendance-web-app/services/download.service';
import { DropdownOption } from '@attendance-web-app/types/employee/type';
import { MouseEventHandler, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SearchBar from '../_header/Searchbar';
import MobileCalender from './MobileCalender';
import { MobileDropDown } from './MobileDropDown';
import MobileMultiselectDropdown from './MobileMultiselectDropdown';

export const DEPARTMENT_OPTIONS: DropdownOption[] = [
  { value: 'Design', label: 'Design' },
  { value: 'Technology & Services', label: 'Technology & Services' },
  { value: 'Product Engineering', label: 'Product Engineering' },
  { value: 'Accounting and Finance', label: 'Accounting and Finance' },
  { value: 'Service Engineering', label: 'Service Engineering' },
  { value: 'Tech-service', label: 'Tech-service' }
];

export const PERIODICITY_OPTIONS: DropdownOption[] = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' }
];

const MobileHeader = ({
  handleOpen
}: {
  open: boolean;
  handleOpen: MouseEventHandler<HTMLDivElement>;
  handleClose: MouseEventHandler<HTMLDivElement>;
}): JSX.Element => {
  const [isMobileHeaderVisible, setMobileHeaderVisibility] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { filtersState, filtersDispatch } = useFiltersContext();
  const { attendanceState, attendanceDispatch } = useAttendanceContext();
  const [date, setDate] = useState(filtersState.date);

  const { Department, timePeriod } = filtersState;

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
    setMobileHeaderVisibility(!isMobileHeaderVisible);
  };

  const [show, setShow] = useState(false);

  const modalClose = async () => {
    const attendanceParams = {
      date: new Date(date).toISOString().slice(0, 10),
      name: '',
      departmemt: Department,
      period: timePeriod
    };
    const response = (await attendanceServices.allAttendance(attendanceParams)) as any;
    attendanceDispatch(Add(response));

    setShow(false);
  };
  const filterShow = () => setShow(true);

  const onSelectedOptionsChange = (newSelectedOptions: DropdownOption[]) => {
    filtersDispatch(SetDepartment(String(newSelectedOptions.map(selected => selected.value))));
  };

  const getSelectedOptions = () =>
    Department.length > 0 ? Department.split(',').map(item => ({ label: item, value: item })) : [];

  const handleDownloadCsv = () => {
    const date = new Date(filtersState.date).toISOString().slice(0, 10);
    const department = Department;
    const name = filtersState?.search;
    const period = timePeriod;

    downloadCsv(date, department, name, period);
  };

  return (
    <div>
      <div className={isMobileHeaderVisible ? 'mobile-header' : 'mobile-header hidden'}>
        <div className="d-flex align-items-center justify-content-between">
          <div className=" menuIcon">
            <div onClick={handleOpen}>
              <Icons.MobileMenuIcon height={14} width={20} />
            </div>
          </div>

          <div className="right">
            <div className="search-icons">
              <Icons.SearchIcon height={24} fill="#595959" onClick={toggleSearchBar} />
            </div>
            <div className="filter-icon">
              <Icons.FilterIcon height={24} onClick={filterShow} />
            </div>
            <div className="download-icon" onClick={handleDownloadCsv}>
              <Icons.DownloadingBlack height={24} />
            </div>
          </div>
        </div>
      </div>
      <div className="Search-component">
        <div>{showSearchBar && <SearchBar />}</div>
        <div>
          <button className={!isMobileHeaderVisible ? 'clear-button' : 'clear-button hidden'} onClick={toggleSearchBar}>
            Clear
          </button>
        </div>
      </div>
      <div className="Modal">
        <Modal
          show={show}
          onHide={modalClose}
          className="mobile-header-modal"
          dialogClassName="custom-modal"
          backdropClassName="custom-backdrop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mobile-dropdown">
              <div className="col mobile-dropdown">
                <MobileMultiselectDropdown
                  options={DEPARTMENT_OPTIONS}
                  selectedOptions={getSelectedOptions()}
                  onSelectedOptionsChange={onSelectedOptionsChange}
                />
              </div>
              <div className="col mobile-dropdown">
                <MobileDropDown options={PERIODICITY_OPTIONS} timePeriod={timePeriod} />
              </div>
              <div className="col mobile-dropdown">
                <MobileCalender date={date} setDate={setDate} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="clear-button" onClick={modalClose}>
              Cancel
            </button>
            <button className="btn" onClick={modalClose}>
              Apply filters
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MobileHeader;
