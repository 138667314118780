import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { getDaysName, getFormattedFullDate, getFormattedTimeFromDate } from '@attendance-web-app/libs/date';
import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';
import { isAdminUser } from '@attendance-web-app/utils';
import React from 'react';
import { useParams } from 'react-router';
interface IAttendanceRecords {
  setSelectedAttendance?: React.Dispatch<React.SetStateAction<IEmployeeDetails[]>>;
  employeeRecords: IEmployeeDetails;
  isIndividual?: boolean;
}

const AttendanceRecord = ({
  setSelectedAttendance,
  employeeRecords,
  isIndividual = false
}: IAttendanceRecords): React.ReactElement => {
  const { employeeId } = useParams();
  const addEmployeeInfoInAttendance = (attendance: any) => {
    const employeeInfo = {
      name: employeeRecords?.name,
      department: employeeRecords?.department,
      designation: employeeRecords?.designation,
      email: employeeRecords?.email,
      employeeId: employeeRecords?.employeeIdNo,
      hod: employeeRecords?.HOD?.name,
      supervisor: employeeRecords?.supervisor?.name
    };
    return { ...attendance, employeeInfo };
  };
  const handleHeaderCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    const selectedAttendances: any = isChecked ? employeeRecords.attendances : [];
    const selectedAttendancesWithEmployeeInfo =
      selectedAttendances && selectedAttendances.map((attendance: any) => addEmployeeInfoInAttendance(attendance));
    setSelectedAttendance && setSelectedAttendance(selectedAttendancesWithEmployeeInfo);
    const allRowCheckboxes = document.querySelectorAll('tbody .row-checkbox');
    allRowCheckboxes.forEach(checkbox => {
      (checkbox as HTMLInputElement).checked = isChecked;
    });
  };

  const handleRowCheckboxChange = (event: any, attendance: any) => {
    const isChecked = event.target.checked;
    const attendanceWithEmployeeDetails = addEmployeeInfoInAttendance(attendance);
    if (isChecked) {
      setSelectedAttendance && setSelectedAttendance(prev => [...prev, attendanceWithEmployeeDetails]);
    } else {
      setSelectedAttendance &&
        setSelectedAttendance(prev => prev.filter(selectedLog => selectedLog.id !== attendanceWithEmployeeDetails.id));
    }
  };
  if (!employeeRecords) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {employeeRecords.attendances.length === 0 ? (
        <div className="empty-message body-large-regular">
          <div>
            {' '}
            <Icons.noDataIcon />
          </div>
          No Attendance List to display
        </div>
      ) : (
        <div className="col-12 list-wrapper">
          <div className="table-responsive" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
            <table className="table" style={{ overflowY: 'auto' }}>
              <thead className="thead body-small-semibold-uppercase">
                <tr>
                  {isAdminUser() && (
                    <th className="td py-6">
                      <input
                        type="checkbox"
                        disabled={isIndividual}
                        name=""
                        id=""
                        onChange={handleHeaderCheckboxChange}
                      />
                    </th>
                  )}
                  <th>Day</th>
                  <th>Date</th>
                  <th>Check-In Time</th>
                  <th>Check-Out Time</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {employeeRecords.attendances.map((attendanceRecord, index) => (
                  <tr className={`${attendanceRecord.isChecked ? 'highlight' : ''}`} key={index}>
                    {isAdminUser() && (
                      <td className="py-3">
                        <input
                          type="checkbox"
                          disabled={isIndividual}
                          className="row-checkbox"
                          checked={attendanceRecord.isChecked}
                          onChange={e => handleRowCheckboxChange(e, attendanceRecord)}
                        />
                      </td>
                    )}
                    <td className="body-regular align-middle">{getDaysName(attendanceRecord.date)}</td>
                    <td className="body-regular align-middle">{getFormattedFullDate(attendanceRecord.date)}</td>
                    <td className="body-regular align-middle">
                      {getFormattedTimeFromDate(attendanceRecord.checkInTime)}
                    </td>
                    <td className="body-regular align-middle">
                      {getFormattedTimeFromDate(attendanceRecord.checkOutTime)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendanceRecord;
