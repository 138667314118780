import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { colors } from '@attendance-web-app/theme/colors';
interface ISignOut {
  adminUser: {
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
  };
  userRole: string;
  signOut: () => void;
}
const SignOut = ({ adminUser, userRole, signOut }: ISignOut) => {
  return (
    <div className="signOut">
      <div className="square" />
      <div className="navbarMain__avatarContainer">
        <div className="navbarMain__avatarContainer--avatar">
          <img src={adminUser?.picture} alt="image" />
        </div>

        <div className="navbarMain__avatarContainer--user">
          <p className="userName body-regular">
            {adminUser?.given_name} {adminUser?.family_name}
          </p>
          <div className="userRole">
            <p className="body-small-regular colors-g03">{userRole}</p>
          </div>
          <div className="action">
            <Icons.Email height={12} width={12} stroke={colors.colorP02} fill={colors.colorP02} />
            <p className="body-small-regular colors-p02">{adminUser?.email} </p>
          </div>
        </div>
      </div>
      <div className="logout-button" onClick={signOut}>
        <p className="body-regular">Log out</p>
      </div>
    </div>
  );
};

export default SignOut;
