import { days, months } from '@fuse-apps/shared-constants';
import { format } from 'date-fns';

export function getDayName(day: number): string {
  return days[day];
}

export function getMonthName(month: number): string {
  return months[month];
}

export const getFormattedTimeFromDate = (timestamp: string | null | undefined): string => {
  if (!timestamp) {
    return '--:--'; // or return a default value such as '--:--'
  }

  const date = new Date(timestamp);
  const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const minutes = date.getMinutes().toString().length > 1 ? date.getMinutes() : '0' + date.getMinutes();
  const meridiem = date.getHours() > 12 ? 'PM' : 'AM';
  return `${hour}:${minutes} ${meridiem}`;
};

export const getDaysName = (timestamp: string): string => {
  const _date = new Date(timestamp);
  const day = getDayName(_date.getDay());
  return day;
};

export const getFormattedFullDate = (timestamp: string): string => {
  const _date = new Date(timestamp);
  const date = _date.getDate();
  const month = getMonthName(_date.getMonth());
  const year = _date.getFullYear();

  return `${date} ${month}, ${year}`;
};
export const getFormattedDateForParams = (timeStamp?: string): string => {
  const date = timeStamp ? format(new Date(timeStamp), 'yyyy-MM-dd') : '';
  return date;
};
export const changeNormalTimeToAmPm = (time: string) => {
  if (time) {
    const [hours, minutes] = time.split(':');

    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));

    const formattedTime = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return formattedTime;
  }

  return '';
};
export const convert12FormatTo24Format = (timeStr: string) => {
  if (timeStr) {
    const [time, modifier] = timeStr.split(' ');
    let hours: string | number = time.split(':')[0];
    const minutes = time.split(':')[1];
    if (hours === '12') {
      hours = '00';
    }

    if (parseInt(hours) < 10 && (modifier === 'am' || modifier === 'AM')) {
      hours = `0${hours}`;
    }
    if (modifier === 'PM' || modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  return '';
};
export const checkIfOpenFor24Hour = (openingHour: string, closingHour: string) => {
  if (openingHour && closingHour) {
    if (openingHour === '00:00 AM' && closingHour === '00:00 PM') {
      return true;
    }
  }
  return false;
};
