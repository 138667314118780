import { FiltersAction, FiltersActionType } from '../actions/filtersAction.d';
import { IFiltersReducerState } from './filtersReducer.d';

export const initialFiltersReducerState: IFiltersReducerState = {
  date: String(new Date()),
  Department: '',
  timePeriod: '',
  search: '',
  limit: 10,
  offset: 0
};

export const filtersReducer = (state: IFiltersReducerState, action: FiltersAction): IFiltersReducerState => {
  switch (action.type) {
    case FiltersActionType.setHeaderDate:
      return { ...state, date: action.payload };

    case FiltersActionType.setHeaderSearch:
      return { ...state, search: action.payload };

    case FiltersActionType.setHeaderDepartment: {
      return { ...state, Department: action.payload };
    }
    case FiltersActionType.setLimit:
      return { ...state, limit: action.payload };
    case FiltersActionType.setOffset:
      return { ...state, offset: action.payload };
    case FiltersActionType.setTimePeriod:
      if (action.payload === 'Weekly') {
        return { ...state, timePeriod: 'week' };
      }
      if (action.payload === 'Daily') {
        return { ...state, timePeriod: '' };
      }
      if (action.payload === 'Monthly') {
        return { ...state, timePeriod: 'month' };
      }
      return state;

    case FiltersActionType.setApplyFilter: {
      if (action.payload.timePeriod === 'Weekly' || action.payload.timePeriod === 'week') {
        return { ...state, timePeriod: 'week', date: action.payload.date };
      } else if (action.payload.timePeriod === 'Daily') {
        return { ...state, timePeriod: '', date: action.payload.date };
      } else if (action.payload.timePeriod === 'Monthly' || action.payload.timePeriod === 'month') {
        return { ...state, timePeriod: 'month', date: action.payload.date };
      } else {
        return { ...state, date: action.payload.date };
      }
    }

    default:
      return state;
  }
};
