import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import ScreenHeader from '@attendance-web-app/components/Common/ScreenHeader';
import CustomSearchBar from '@attendance-web-app/components/_pages/_header/LogSearchBar';
import getAllEmployeeServices from '@attendance-web-app/services/employee.services';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import InviteEmployeeTabForm from './InviteEmployeeTabForm';
import UploadCsvTab from './UploadCsvTab';
import UploadErrorModal from './UploadErrorModal';

interface InviteEmployee {
  show: boolean;
  onHide: () => void;
  setCreatedMsg: (msg: string) => void;
  setUploadErrorMsg: (errorMsg: string) => void;
  setUploadUpdatedMsg: (updatedMsg: string) => void;
  setUploadErrorData: React.Dispatch<React.SetStateAction<object[]>>;
  setUploadedData: React.Dispatch<React.SetStateAction<object[]>>;
}

interface IEmployeeHeader {
  toggleAdvancedFilter: () => void;
  onChangeSearchHandler: (name: string) => void;
}

function EmployeeInviteModal(props: InviteEmployee) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [designation, setDesignation] = useState({ value: '', label: '' });
  const [department, setDepartment] = useState({ value: 0, label: '' });
  const [supervisor, setSupervisor] = useState({ value: '', label: '' });
  const [role, setRole] = useState({ value: '', label: '' });
  const [employeeId, setEmployeeId] = useState('');
  const [employeeGrade, setEmployeeGrade] = useState('');
  const adminId = JSON.parse(localStorage.getItem('admin_user') as string).id;
  const [formErrors, setFormErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [activeTab, setActiveTab] = useState('upload');
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteEmployee = async () => {
    try {
      if (activeTab === 'upload') {
        if (selectedFile) {
          try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await getAllEmployeeServices.uploadEmployeeCsv(formData);
            props.setUploadErrorData(response.data?.errorMessages);
            props.setUploadedData(response.data?.updatedEmployees);
            props.setCreatedMsg(`Uploaded ${response.data?.totalEmployeeCreated} employees successfully`);
            props.setUploadErrorMsg(`${response.data?.uploadFailed} data upload failed`);
            props.setUploadUpdatedMsg(`${response.data?.totalEmployeeUpdated} data updated`);
          } catch (error) {
            console.error(error);
          } finally {
            setSelectedFile(null);
            setIsLoading(false);
          }
        } else {
          console.log('No file selected');
        }
      } else if (activeTab === 'addEmployee') {
        const createEmployeeBody = {
          email: email,
          mobile: Number(mobileNumber),
          designation: Number(designation.value),
          department: department.value,
          role: Number(role.value),
          employeeIdNo: employeeId,
          status: false,
          supervisorId: Number(supervisor.value),
          employeeId: adminId,
          grade: employeeGrade
        };
      }
      resetFormState();
      props.onHide();
    } catch (error: any) {
      if (error.inner) {
        const errors: any = {};
        error.inner.forEach((validationError: any) => {
          errors[validationError.path] = validationError.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setActiveTab('upload');
    } else if (index === 1) {
      setActiveTab('addEmployee');
    }
  };
  const resetFormState = () => {
    setEmail('');
    setName('');
    setMobileNumber('');
    setDesignation({ value: '', label: '' });
    setDepartment({ value: 0, label: '' });
    setSupervisor({ value: ' ', label: '' });
    setEmployeeId('');
    setFormErrors([]);
  };

  const handleHideModal = () => {
    resetFormState();
    setSelectedFile(null);
    props.onHide();
  };

  return (
    <Modal
      className="invite-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen={true}
      onHide={handleHideModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          Invite Employees
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs onSelect={handleTabChange}>
          <TabList className="modal-tablist">
            <Tab className="body-regular-semibold no-bullets">Upload .csv </Tab>
            <Tab className="body-regular-semibold no-bullets">Add Employee</Tab>
          </TabList>
          <TabPanel>
            <div style={{ height: '318px' }}>
              <UploadCsvTab selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
            </div>
          </TabPanel>
          <TabPanel>
            <InviteEmployeeTabForm onHide={props.onHide} />
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-tertiary pe-3" onClick={handleHideModal}>
          Cancel
        </button>
        <LoadingScreen isLoading={isLoading}>
          {selectedFile ? (
            <button className="btn" onClick={handleInviteEmployee}>
              Invite Employee
            </button>
          ) : (
            <button className="btn" form="invite-form">
              Invite Employee
            </button>
          )}
        </LoadingScreen>
      </Modal.Footer>
    </Modal>
  );
}

const EmployeeListHeader = ({ toggleAdvancedFilter, onChangeSearchHandler }: IEmployeeHeader) => {
  const [modalShow, setModalShow] = useState(false);
  const [createdMsg, setCreatedMsg] = useState('');
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [uploadErrorData, setUploadErrorData] = useState<object[]>([]);
  const [uploadedData, setUploadedData] = useState<object[]>([]);
  const [uploadUpdatedMsg, setUploadUpdatedMsg] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ScreenHeader title="Employee List">
        <div className="employee-list-page">
          <div className="employee-list-search">
            <CustomSearchBar
              toggleAdvancedFilter={toggleAdvancedFilter}
              onChangeSearchHandler={onChangeSearchHandler}
            />
          </div>
          <div className="employee-list-invite">
            <button className="btn download-button  body-regular" onClick={() => setModalShow(true)}>
              <Icons.PlusIcon height={16} />
              <span> Invite employee </span>
            </button>
          </div>
        </div>
      </ScreenHeader>
      <EmployeeInviteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setCreatedMsg={setCreatedMsg}
        setUploadErrorMsg={setUploadErrorMsg}
        setUploadUpdatedMsg={setUploadUpdatedMsg}
        setUploadErrorData={setUploadErrorData}
        setUploadedData={setUploadedData}
      />
      {createdMsg && (
        <Alert
          variant="success"
          dismissible
          onClose={() => setCreatedMsg('')}
          className="d-flex justify-content-between mx-3 body-large-regular"
          style={{ width: '97%' }}
        >
          <span>
            <Icons.Check height={24} /> &nbsp; {createdMsg}
          </span>
          {!uploadErrorMsg.includes('0 data upload failed') && (
            <span className="upload-error-msg">
              {uploadErrorMsg} &nbsp;
              {<Icon icon="material-symbols:circle" width="4px" height="4px" color="red" style={{ margin: '0 5px' }} />}
              &nbsp; {uploadUpdatedMsg} &nbsp; &nbsp;
              <span className="btn-inline alert-view-details" onClick={handleOpenModal}>
                View details
              </span>
              {showModal && (
                <UploadErrorModal
                  handleCloseModal={handleCloseModal}
                  showModal={showModal}
                  uploadErrorData={uploadErrorData}
                  uploadedData={uploadedData}
                />
              )}
            </span>
          )}
        </Alert>
      )}
    </>
  );
};

export default EmployeeListHeader;
