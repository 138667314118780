import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import IndividualHeader from '@attendance-web-app/components/_pages/_MobileView/IndividualHeader';
import MobileIndividualProfile from '@attendance-web-app/components/_pages/_MobileView/MobileIndividualProfile';
import DetailSidebar from '@attendance-web-app/components/_pages/_Records/DetailSidebar';
import EmployeeRecord from '@attendance-web-app/components/_pages/_Records/EmployeeRecord';
import RecordHeader from '@attendance-web-app/components/_pages/_Records/RecordHeader';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import employeeDetailsServices from '@attendance-web-app/services/employeeDetails.services';
import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const initialEmployeeDetailsState: IEmployeeDetails = {
  id: 0,
  name: '',
  email: '',
  mobile: '',
  department: '',
  employeeIdNo: '',
  position: '',
  designation: {
    id: 0,
    name: ''
  },
  profilePhoto: '',
  supervisor: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  HOD: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  attendances: []
};

export function Records() {
  const { employeeId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const { filtersState } = useFiltersContext();
  const [open, setOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState<IEmployeeDetails[]>([]);
  const [employeeRecords, setEmployeeRecords] = useState<IEmployeeDetails>(initialEmployeeDetailsState);
  useEffect(() => {
    fetchEmployeeRecords();
    setSelectedAttendance([]);
  }, [employeeId, filtersState.timePeriod, filtersState.date]);

  const fetchEmployeeRecords = async () => {
    try {
      setLoading(true);
      const records = await employeeDetailsServices.getEmployeeDetails(
        String(employeeId),
        new Date(filtersState.date).toISOString().slice(0, 10),
        filtersState.timePeriod
      );
      setEmployeeRecords(prev => ({ ...prev, ...records.data }));
      setLoading(false);
    } catch (err) {
      handleErrorMessage(err);
    }
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="row mt-3">
          <IndividualHeader />
          <RecordHeader selectedAttendance={selectedAttendance} employeeRecords={employeeRecords} />
        </div>
        <div className="d-flex h-100" style={{ gap: '24px' }}>
          <DetailSidebar employeeRecords={employeeRecords} />
          <div className="content">
            <div className="container-fluid">
              {isLoading ? (
                <div className="flex-1 d-flex justify-content-center align-items-center loading__container">
                  <Icons.Loading height={50} width={50} />
                </div>
              ) : (
                <>
                  <div className="individual-profile row mb-3">
                    <MobileIndividualProfile employeeRecords={employeeRecords} />
                  </div>
                  <div className="row employeedetail-table">
                    <EmployeeRecord employeeRecords={employeeRecords} setSelectedAttendance={setSelectedAttendance} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Records;
