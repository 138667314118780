import Alert from '@attendance-web-app/components/Common/Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const patchDesignationToServer = async (payload: {
  department: number;
  subDepartment: number;
  name: string;
  designationId: number;
}): Promise<any> => {
  const { designationId, ...restValue } = payload;
  try {
    const response = await axios.patch(`/designation/${designationId}`, restValue);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const useUpdateDesignation = (departmentIdNo?: string, onSuccessHandler?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchDesignationToServer,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['designation_list', departmentIdNo]
          },
          (oldQueryData: any) => {
            const changedDataIndex = oldQueryData?.findIndex((designation: any) => designation.id === data.id);

            if (changedDataIndex > -1) {
              oldQueryData[changedDataIndex] = data;
              return oldQueryData;
            }
          }
        );
        Alert('success', 'Designation updated successfully.');
        onSuccessHandler && onSuccessHandler();
      }
    }
  });
};
