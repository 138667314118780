import getAdmin from '@attendance-web-app/services/login.service';
import setAuthToken from '@attendance-web-app/services/setAuthToken';
import { lock } from './Login';

interface IdTokenPayload {
  [key: string]: any;
}

interface AuthResult {
  accessToken: string;
  idToken: string;
  idTokenPayload: IdTokenPayload;
}
declare global {
  interface Window {
    AUTHENTICATED: boolean;
  }
}

const silentAuth = async () => {
  return new Promise<string | void>((resolve, reject) => {
    lock.checkSession({}, (err, authResult) => {
      if (err) {
        console.log({ errorFromSilentAuth: err });
        reject(err);
      } else {
        console.log({ authResultFromSilentAuth: authResult });
        sessionStorage.setItem('access_token', authResult?.accessToken as string);
        resolve((authResult as AuthResult).accessToken);
      }
    });
  });
};

export async function authenticate(authResult: AuthResult) {
  try {
    sessionStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('user_token', JSON.stringify(authResult.idTokenPayload));
    localStorage.setItem('id_token', authResult.idToken);
    const loggeduser = await getAdmin(authResult.accessToken);
    let alreadyNavigated = false;
    if (loggeduser.statusCode === 401 || loggeduser.statusCode === 400) {
      sessionStorage.removeItem('access_token');
      localStorage.removeItem('user_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('admin_user');

      throw new Error('401');
    }
    if (loggeduser.firstLogin === 'true') {
      try {
        alreadyNavigated = true; // Set the flag to true
        const silentAuthResult = await silentAuth();
        setAuthToken(silentAuthResult as string);
        if (silentAuthResult) {
          return {
            ok: true,
            data: 'success'
          };
        } else {
          throw new Error('Silent auth failed.');
        }
      } catch (error) {
        throw new Error('Something went wrong');
      }
    } else {
      if (loggeduser?.data) {
        localStorage.setItem('admin_user', JSON.stringify(loggeduser?.data ?? null));
        return {
          ok: true,
          data: loggeduser?.data
        };
      }
      return {
        ok: false,
        data: null
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err
    };
  }
}
