import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useCloseAbsoluteDiv } from './hooks/useCloseAbsoluteDiv';

interface ICustomFilterChipTrigger {
  title: string;
  value?: any;
  length?: number;
  onCrossClick: () => void;
  onChipClick?: () => void;
}
interface CustomFilterChip extends ICustomFilterChipTrigger {
  isMultipleDropdown?: boolean;
  isDropDown?: boolean;
  isSimpleText?: boolean;
  isDate?: boolean;
  children?: React.ReactNode;
}
export const CustomFilterChipTrigger = ({
  title,
  value,
  length = 0,
  onCrossClick = () => {},
  onChipClick = () => {}
}: ICustomFilterChipTrigger) => {
  const onCrossClicked = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (title) {
      onCrossClick();
    }
  };
  return (
    <div className="customFilterChip" onClick={onChipClick}>
      <div className="customFilterChip__right">
        <p className="customFilterChip__title">
          {title}: {Array.isArray(value) ? value?.[0]?.label : value}
        </p>
        {length > 1 && (
          <div className="length">
            <span>+{length - 1}</span>
          </div>
        )}
      </div>
      <span className="icon_container" onClick={onCrossClicked}>
        <Icon icon="maki:cross" height={'16'} width={'16'} />
      </span>
    </div>
  );
};

const CustomFilterChip = ({
  title,
  value,
  length = 0,
  isDate = false,
  children,
  onCrossClick = () => {}
}: CustomFilterChip) => {
  const [isChildrenOpen, setIsChildrenOpen] = useState(isDate);
  const onChipClick = () => {
    setIsChildrenOpen(prev => !prev);
  };

  const onClose = () => {
    if (!isDate) {
      setIsChildrenOpen(false);
    }
  };
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);

  return (
    value.length > 0 && (
      <div className="multi-select-dropdown" ref={dropdownRef}>
        {!isDate && (
          <CustomFilterChipTrigger
            onChipClick={onChipClick}
            title={title}
            value={value}
            length={length}
            onCrossClick={onCrossClick}
          />
        )}
        {isChildrenOpen && (
          <div className={`customFilterChip__children ${isDate ? 'isNotAbsolute' : ''}`}>{children}</div>
        )}
      </div>
    )
  );
};

export default CustomFilterChip;
