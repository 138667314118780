import Alert from '@attendance-web-app/components/Common/Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const postDesignationToServer = async (payload: {
  department: number;
  subDepartment: number;
  name: string;
}): Promise<any> => {
  try {
    const response = await axios.post(`/designation`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const useAddDesignation = (departmentIdNo?: string, onSuccessHandler?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postDesignationToServer,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['designation_list', departmentIdNo]
          },
          (oldQueryData: any) => {
            console.log({ oldQueryData, data });
            const newQueryData = [...oldQueryData, data];
            return newQueryData;
          }
        );
        Alert('success', 'Designation added successfully.');
        onSuccessHandler && onSuccessHandler();
      }
    }
  });
};
