import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { range } from '@attendance-web-app/utils/range';
import { months } from '@fuse-apps/shared-constants';
import { addDays, format, getYear, startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

const currentYear = new Date().getFullYear();

const IndividualCalender = ({ setDate }: { date: string; setDate: React.Dispatch<React.SetStateAction<string>> }) => {
  const { filtersState, filtersDispatch } = useFiltersContext();
  const [startDate, setStartDate] = useState(new Date(filtersState.date));
  const years = range(2013, currentYear);
  const [year, setYear] = useState(getYear(startDate));
  const [month, setMonth] = useState(months[startDate.getMonth()]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());
  const [selectedWeekEnd, setSelectedWeekEnd] = useState(new Date());

  const handleCalendarOpen = () => {
    const display = isOpen ? 'none' : 'block';
    setIsOpen(isOpen => !isOpen);
    document.querySelector('.react-datepicker__tab-loop')?.setAttribute('style', `display:${display}`);
  };

  useEffect(() => {
    setMonth(months[startDate.getMonth()]);
    setYear(getYear(startDate));
    setIsOpen(!isOpen);
    setDate(String(startDate));
    selectWeek(startDate);
  }, [startDate]);

  const selectWeek = (date: Date) => {
    const startOfWeekDate = startOfWeek(date);
    const endOfWeekDate = addDays(startOfWeekDate, 6);
    setSelectedWeekStart(startOfWeekDate);
    setSelectedWeekEnd(endOfWeekDate);
  };

  const renderDayContents = (dayOfMonth: number, date: Date): JSX.Element => {
    let customClassName;
    if (filtersState.timePeriod === 'week' && date >= selectedWeekStart && date <= selectedWeekEnd)
      customClassName = 'custom-classname';

    return (
      <div className={customClassName}>
        <span>{dayOfMonth}</span>
      </div>
    );
  };

  return (
    <div className="calendar-right body-large-regular">
      <DatePicker
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <div className="header">
            <select
              className="body-regular"
              value={year}
              onChange={({ target: { value } }) => {
                setYear(Number(value));
                changeYear(Number(value));
              }}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="body-regular"
              value={month}
              onChange={({ target: { value } }) => {
                setMonth(value);
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={startDate}
        onChange={date => {
          setStartDate(date ?? new Date());
        }}
        useWeekdaysShort
        customInput={
          <button className="calendar-input body-large-regular" onClickCapture={handleCalendarOpen}>
            {filtersState.timePeriod === '' ? format(startDate, 'MMMM d, yyyy') : format(startDate, 'MMMM, yyyy')}
            {isOpen ? (
              <Icons.ArrowUp height={20} width={20} stroke="#595959" />
            ) : (
              <Icons.ArrowDown height={20} width={20} stroke="#595959" />
            )}
          </button>
        }
        onCalendarClose={() => setIsOpen(false)}
        renderDayContents={renderDayContents}
      />
    </div>
  );
};

export default IndividualCalender;
