import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContextStore from './global-state/Store';
import Router from './routes';
import './styles/style.scss';

const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.VITE_BASEURL_FALC;

if (sessionStorage.getItem('access_token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <ContextStore>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Router />
        </BrowserRouter>
      </QueryClientProvider>
    </ContextStore>
  </>
);
