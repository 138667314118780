import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import React from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { useCustomCompCsv } from './hooks/useCustomCompCSV';

interface UploadCsvProps {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  googleDriveLink: string;
}

const CustomCompCSV = ({ selectedFile, setSelectedFile, googleDriveLink }: UploadCsvProps) => {
  const { handleResetCsv, handleFileInput, fileSize, uploadProgress } = useCustomCompCsv({ setSelectedFile });

  return (
    <div className="mt-4">
      <Form>
        <label htmlFor="csv-file-input" className={`upload-csv ${selectedFile ? 'disabled' : ''}`}>
          {selectedFile ? selectedFile.name : 'Upload .CSV file here'}
          <Form.Control
            type="file"
            id="csv-file-input"
            onChange={handleFileInput}
            accept="text/csv"
            disabled={selectedFile !== null}
          />
        </label>
        {!selectedFile && (
          <p className="body-large-regular" style={{ marginBottom: '4rem' }}>
            No file uploaded. &nbsp;
            <button className="btn-inline" onClick={() => window.open(`${googleDriveLink},'_blank`)}>
              <Icons.GoogleLinkIcon height={16} width={17} stroke="#2074B9" /> Google sheet
            </button>
          </p>
        )}
      </Form>
      {selectedFile && (
        <div className="upload-container-designation">
          <div className="upload-text">
            <div>
              <p className="body-regular-semibold mb-2">{selectedFile.name}</p>
              <p className="body-regular-semibold mb-1" style={{ color: '#868686' }}>
                <span>{fileSize}</span>
              </p>
            </div>

            <div>
              <span className="mb-1" onClick={handleResetCsv}>
                <Icons.CrossIcon height={20} width={20} fill="#595959" />
              </span>
              <p className="mb-1">{uploadProgress}%</p>
            </div>
          </div>
          <ProgressBar now={uploadProgress} />
        </div>
      )}
    </div>
  );
};

export default CustomCompCSV;
