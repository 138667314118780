import Alert from '@attendance-web-app/components/Common/Alert';
import {
  getMonthlyTransactionSummary,
  getYearlyTransactionSummary
} from '@attendance-web-app/services/transactionSummary.service';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import format from 'date-fns/format';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { useState } from 'react';

export const useBalanceSheet = () => {
  //get transaction summary of current year by default
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const {
    data: yearlyTransaction,
    refetchQuery: refetchYearlyTransactionSummary,
    isLoading
  } = useCustomListApi({
    queryKey: ['yearlyTransactionSummaryList'],
    getApiFn: getYearlyTransactionSummary,
    enabled: true,
    params: { year: currentYear },
    options: {
      refetchOnWindowFocus: false
    }
  });

  const { data: monthlyTransaction, refetchQuery: refetchMonthlyTransactionSummary }: any = useCustomListApi({
    queryKey: ['monthlyTransactionSummaryList'],
    getApiFn: getMonthlyTransactionSummary,
    enabled: true,
    params: { year: currentYear },
    options: {
      refetchOnWindowFocus: false
    }
  });

  const onYearChange = (year: number) => {
    //fetching transaction and balance summary of selected year
    refetchYearlyTransactionSummary({ year });
    refetchMonthlyTransactionSummary({ year });
    setYear(year);
  };

  const prepareDownloadToData = () => {
    const transactionToDownload = [] as any;
    const monthlyTransactionData = monthlyTransaction.data;
    monthlyTransactionData.map((transaction: any) => {
      if (transaction.payments.length <= 1) {
        //include totalTransactions, totalPaid,remainingBalance,vendorName in the first row of the data with no or only one payment
        const { month, totalTransactions, remainingBalance, totalPaid } = transaction;
        const { chequeNo, amount, bank, transactionDate, vendor } = transaction?.payments[0] ?? {};
        const bankName = bank?.fullName;
        const firstRowData = {
          month,
          transaction: totalTransactions,
          remainingBalance,
          vendorName: vendor?.vendorName,
          totalPaid,
          chequeNo,
          amount,
          bank: bankName,
          transactionDate: transactionDate ? format(new Date(transactionDate), 'MMM d, yyyy') : ''
        };
        transactionToDownload.push(firstRowData);
      } else {
        //include totalTransactions, totalPaid,remainingBalance,vendorName in the last row of the data
        const numberofPayments = transaction.payments.length;

        for (let i = 0; i < numberofPayments - 1; i++) {
          const { vendor, transactionDate } = transaction?.payments[i] ?? {};
          const transactionDetails = {
            ...transaction.payments[i],
            bank: transaction.payments[i].bank.fullName,
            vendorName: vendor.vendorName,
            transactionDate: transactionDate ? format(new Date(transactionDate), 'MMM d, yyyy') : ''
          };

          transactionToDownload.push(transactionDetails);
        }
        const lastIndexData = {
          ...transaction.payments[numberofPayments - 1],
          bank: transaction.payments[numberofPayments - 1].bank.fullName,
          vendorName: transaction?.payments[numberofPayments - 1]?.vendor.vendorName,
          transactionDate: format(
            new Date(transaction?.payments[numberofPayments - 1]?.transactionDate),
            'MMM d, yyyy'
          ),
          month: transaction.month,
          transaction: transaction.totalTransactions,
          remainingBalance: transaction.remainingBalance,
          totalPaid: transaction.totalPaid
        };
        transactionToDownload.push(lastIndexData);
      }
    });

    return transactionToDownload;
  };

  const handleExport = (year: number) => {
    refetchMonthlyTransactionSummary({ year });
    const datas = monthlyTransaction.data;
    const transactionToDownload = prepareDownloadToData();

    //creating excel sheet
    var ExcelJSWorkbook = new ExcelJS.Workbook();
    var worksheet = ExcelJSWorkbook.addWorksheet('ExcelJS sheet');

    //preparing headers for excel sheet
    worksheet.columns = [
      { header: 'Month', key: 'month', width: 15 },
      { header: 'Vendor', key: 'vendorName', width: 15 },
      { header: 'Bank', key: 'bank', width: 15 },
      { header: 'Cheque No', key: 'chequeNo', width: 15 },
      { header: ' Paid Amount', key: 'amount', width: 15 },
      { header: 'Paid on', key: 'transactionDate', width: 18 },
      { header: 'Total Transactions', key: 'transaction', width: 15 },
      { header: 'Total Paid', key: 'totalPaid', width: 15 },
      {
        header: 'Balance',
        key: 'remainingBalance',
        width: 15
      }
    ];

    //add row datas
    for (let i = 0; i < transactionToDownload.length; i++) {
      worksheet.addRow(transactionToDownload[i]);
    }

    let rowCount = 2;
    const rowIndexOfEachMonth = [2]; //in excel sheet data row starts from row 2
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].payments.length === 0) {
        //updating rowCount for monthly data with no payments
        rowCount = rowCount + 1;
        rowIndexOfEachMonth.push(rowCount);
      } else {
        //updating rowCount for monthly data with payments
        rowCount = datas[i].payments.length + rowCount;
        rowIndexOfEachMonth.push(rowCount);
      }
    }

    for (let i = 0; i < rowIndexOfEachMonth.length; i++) {
      if (rowIndexOfEachMonth[i + 1] - rowIndexOfEachMonth[i] > 1) {
        //merge month cells for payments of same month
        worksheet.mergeCells(`A${rowIndexOfEachMonth[i]}:A${rowIndexOfEachMonth[i + 1] - 1}`);
        worksheet.getCell(`A${rowIndexOfEachMonth[i]}:A${rowIndexOfEachMonth[i + 1] - 1}`).value =
          transactionToDownload[rowIndexOfEachMonth[i + 1] - 3].month;

        // //merge total transaction cell  of same month
        worksheet.mergeCells(`G${rowIndexOfEachMonth[i]}:G${rowIndexOfEachMonth[i + 1] - 1}`);
        worksheet.getCell(`G${rowIndexOfEachMonth[i]}:G${rowIndexOfEachMonth[i + 1] - 1}`).value =
          transactionToDownload[rowIndexOfEachMonth[i + 1] - 3].transaction;

        // //merge total paid cell  of same month
        worksheet.mergeCells(`H${rowIndexOfEachMonth[i]}:H${rowIndexOfEachMonth[i + 1] - 1}`);
        worksheet.getCell(`H${rowIndexOfEachMonth[i]}:H${rowIndexOfEachMonth[i + 1] - 1}`).value =
          transactionToDownload[rowIndexOfEachMonth[i + 1] - 3].totalPaid;

        // //merge balance cell  of same month
        worksheet.mergeCells(`I${rowIndexOfEachMonth[i]}:I${rowIndexOfEachMonth[i + 1] - 1}`);
        worksheet.getCell(`I${rowIndexOfEachMonth[i]}:I${rowIndexOfEachMonth[i + 1] - 1}`).value =
          transactionToDownload[rowIndexOfEachMonth[i + 1] - 3].remainingBalance;
      }
      // }
    }
    //Download the data
    ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer: any) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `YearlyTransactionSummary.xlsx`);
    });
    Alert('success', 'Balance sheet has been downloaded successfully.');
  };
  return { yearlyTransaction, monthlyTransaction, onYearChange, isLoading, handleExport, year };
};
