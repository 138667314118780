import { getdepartmentList as getDepartmentList } from '@attendance-web-app/services/department.services';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { useState } from 'react';

const PAGE_LIMIT = 50;
export const useDepartmentHook = () => {
  const [pageLimit, setPagelimit] = useState(PAGE_LIMIT);
  const [pageOffset, setPageOffset] = useState(0);

  //fetching department array data
  const {
    data: departmentList,
    refetchQuery: refetchDepartmentList,
    isLoading
  } = useCustomListApi({
    queryKey: ['departmentsLists'],
    getApiFn: getDepartmentList,
    enabled: false,
    params: { limit: pageLimit, offset: pageOffset }
  }) as any;
  const departmentListData = departmentList?.data;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    document.body.style.overflowY = 'hidden';
  };

  const handleClose = () => {
    setOpen(false);
    document.body.style.overflowY = 'auto';
  };

  const handlePaginateClick = (event: any) => {
    const offset = event.selected * pageLimit;
    //saving in state
    setPageOffset(offset);
    setPagelimit(pageLimit);
    //refetching department data using new offset
    refetchDepartmentList({ offset: offset, limit: pageLimit });
  };

  const handleLimitClick = (limit: number) => {
    //saving in state
    setPageOffset(0);
    setPagelimit(limit);
    //refetching department data using new offset and limit
    refetchDepartmentList({ offset: 0, limit });
  };

  const onChangeSearchHandler = (search: string) => {
    //refetching department data using new search string
    refetchDepartmentList({ name: search });
    setPageOffset(0);
    setPagelimit(PAGE_LIMIT);
  };

  return {
    open,
    setOpen,
    refetchDepartmentList,
    isLoading,
    departmentListData,
    handleClose,
    handleOpen,
    handlePaginateClick,
    handleLimitClick,
    pageLimit,
    pageOffset,
    onChangeSearchHandler
  };
};
