const DropdownMenuStatic = () => {
  return (
    <div>
      <div className="dropdown ">
        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Department:All
          <img src="../../assets/images/dropdown-arrow.svg" alt="dropdown-arrow" />
        </button>
      </div>

      <div id="dropdown_outline">
        <label htmlFor="All">
          <input type="checkbox" id="All" name="checkbox" />
          <span className="spacebetween">All</span>
        </label>
        <label htmlFor="Design">
          <input type="checkbox" id="Design" name="checkbox" />
          <span className="spacebetween">Design</span>
        </label>
        <label htmlFor="Technology & Services">
          <input type="checkbox" id="Technology & Services" name="checkbox" />
          <span className="spacebetween">Technology & Services</span>
        </label>
        <label htmlFor="Product Engineering">
          <input type="checkbox" id="Product Engineering" name="checkbox" />
          <span className="spacebetween">Product Engineering</span>
        </label>
        <label htmlFor="Accounting and Finance">
          <input type="checkbox" id="Accounting and Finance" name="checkbox" />
          <span className="spacebetween">Accounting and Finance</span>
        </label>
        <label htmlFor="Service Engineering">
          <input type="checkbox" id="Service Engineering" name="checkbox" />
          <span className="spacebetween">Service</span>
          Engineering
        </label>
        <label htmlFor="Tech Services">
          <input type="checkbox" id="Tech Services" name="checkbox" />
          <span className="spacebetween">Tech Services</span>
        </label>
      </div>

      {/* Daily Weekly */}

      <div className="dropdown ">
        <button
          className=" dropdown-toggle month_dropdown"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span>
            Weekly
            <img src="../../assets/images/dropdown-arrow.svg" alt="dropdown-arrow" />
          </span>
        </button>{' '}
        <div id="dropdown_outline_dw">
          <label htmlFor="Daily">
            <span className="spacebetween">Dialy</span>
          </label>
          <label htmlFor="Weekly">
            <span className="spacebetween">Weekly</span>
          </label>
          <label htmlFor="Daily">
            <span className="spacebetween unused">Monthly</span>
          </label>
          <label htmlFor="Weekly">
            <span className="spacebetween unused">Date Range</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenuStatic;
