import { IDepartmentSummaryProps } from '@attendance-web-app/types/department/type';

const DepartmentSummary = ({ departmentDetails }: IDepartmentSummaryProps) => {
  const departmentName = departmentDetails.departments[0].name,
    departmentId = departmentDetails.departments[0].departmentIdNo,
    employeeCount = departmentDetails.departments[0].employeeCount,
    hodName = departmentDetails.departments[0]?.hod?.employee?.name,
    hodDesignation = departmentDetails.departments[0]?.hod?.employee?.designation?.name,
    hodEmail = departmentDetails.departments[0]?.hod?.employee?.email;

  return (
    <>
      <div className="department-summary mt-4">
        <div className="body-large-regular department-summary-header"> Department Summary</div>
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> Department </div>
          <div className="department-summary-detail">{departmentName}</div>
        </div>
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> Total number of employees </div>
          <div className="department-summary-detail">{employeeCount}</div>
        </div>
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> Department ID </div>
          <div className="department-summary-detail">{departmentId}</div>
        </div>
        <div className="department-summary-description body-small-regular">
          <div className="department-summary-title"> HOD </div>
          <div className="department-summary-detail">{hodName ? hodName : <span>Unassigned</span>}</div>
          <div className="department-summary-detail">{hodDesignation}</div>
          <div className="department-hod-email">{hodEmail}</div>
        </div>
      </div>
    </>
  );
};

export default DepartmentSummary;
