import { Icon } from '@iconify/react';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useCustomYearlyCalendar } from './hooks/useCustomYearlyCalendar';

type selectedDate = Date;
interface ICustomYearCalendar {
  onYearChange: (year: number) => void;
}

const CustomYearlyCalendar = ({ onYearChange }: ICustomYearCalendar) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const { onMenuClick, dropdownRef, yearRange, isOpen ,onClose} = useCustomYearlyCalendar();

  const onYearClick = (year: number) => {
    setSelectedYear(year);
    //fetching summary of selected year
    onYearChange(year);
    onClose();
  };

  return (
    <Dropdown ref={dropdownRef}>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <div className="d-flex justify-content-between dropdownMenuToggleButton" onClick={onMenuClick}>
          {selectedYear}
          <span className="dropdown-menu__arrow ">
            {isOpen ? (
              <Icon icon="icon-park:up" height={16} width={16} color="#595959" />
            ) : (
              <Icon icon="icon-park:down" height={16} width={16} color="#595959" />
            )}
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className={isOpen ? 'dropdwn-options show' : 'dropdwn-options'}>
        {yearRange.map((year: number) => (
          <div
            key={year}
            className="dropdown-item  dropdwn-option regular-body yearly-dropdown "
            onClick={() => onYearClick(year)}
            hidden={!isOpen}
          >
            <span className="yearly-dropdown-items"> {year} </span>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default CustomYearlyCalendar;
