import { spendingListReducersProps } from '../types';
export enum SpendingListActionEnum {
  SAVE_SPENDING_LIST_DATA = 'SAVE_SPENDING_LIST_DATA',
  SAVE_SPENDING_LIST_PARAMS_DATA = 'SAVE_SPENDING_LIST_PARAMS_DATA'
}

export type spendingListAction = {
  type: SpendingListActionEnum.SAVE_SPENDING_LIST_DATA;
  payload: spendingListReducersProps;
};
export type saveSpendingParamsAction = {
  type: SpendingListActionEnum.SAVE_SPENDING_LIST_PARAMS_DATA;
  payload: spendingListReducersProps;
};
export type SpendingListAction = spendingListAction | saveSpendingParamsAction;
