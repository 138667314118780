import Alert from '@attendance-web-app/components/Common/Alert';
import CustomCompCSV from '@attendance-web-app/components/Common/CustomCompCsv';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import {
  default as designationService,
  default as getAllDesignationServices
} from '@attendance-web-app/services/designation.service';
import { IAddDesignationModalProps } from '@attendance-web-app/types/designation/type';
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import DesignationForm from './DesignationForm';

const DesignationAddModal = (props: IAddDesignationModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('addDesignation');

  const handleAddDesignation = async (name?: string, file?: File) => {
    try {
      if (activeTab === 'upload' && file) {
        if (selectedFile) {
          try {
            setIsLoading(true);
            const response = await designationService.uploadDesignationCsv(selectedFile);
            props.setErrorData(response.data?.errorMessages);
            props.setSuccessMsg(`Uploaded ${response.data?.totalDesignationCreated} designation successfully`);
            props.setUploadErrorMsg(`${response.data?.uploadFailed} data upload failed`);
            props.fetchDesignationData();
            props.onHide();
            setActiveTab('addDesignation');
          } catch (error: any) {
            Alert('error', `${error.response.data.message ?? 'Error While Adding Designation'}`);
            setIsLoading(false);
          } finally {
            setIsLoading(false);
            setSelectedFile(null);
          }
        }
      } else if (activeTab === 'addDesignation' && name) {
        try {
          await getAllDesignationServices.createDesignation({
            department: props.departmentId,
            name: name
          });
          Alert('success', `New Designation has been added successfully.`);
        } catch (error: any) {
          Alert('error', `${error.response.data.message ?? 'Error While Adding Designation'}`);
        }
        props.fetchDesignationData();
        props.onHide();
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setActiveTab('addDesignation');
    } else if (index === 1) {
      setActiveTab('upload');
    }
  };

  const handleHideModal = () => {
    setSelectedFile(null);
    props.onHide();
  };
  return (
    <CustomModal
      showModal={props.show}
      handleHideModal={props.onHide}
      modalTitle=" Add Designation"
      footerComponent={
        <>
          <button className="btn-tertiary pe-3" onClick={handleHideModal}>
            Cancel
          </button>
          <LoadingScreen isLoading={isLoading}>
            {selectedFile ? (
              <button className="btn" onClick={() => handleAddDesignation('', selectedFile)}>
                Add Designation
              </button>
            ) : (
              <button className="btn" form="invite-designation-form">
                Add Designation
              </button>
            )}
          </LoadingScreen>
        </>
      }
    >
      <Tabs onSelect={handleTabChange}>
        <TabList className="modal-tablist">
          <Tab className="body-regular-semibold no-bullets">Add Designation</Tab>
          <Tab className="body-regular-semibold no-bullets">Upload .csv </Tab>
        </TabList>
        <TabPanel>
          <div className="row mb-4 mt-5">
            <DesignationForm handleAddDesignation={handleAddDesignation} />
          </div>
        </TabPanel>
        <TabPanel>
          <CustomCompCSV
            googleDriveLink={
              'https://docs.google.com/spreadsheets/d/1HoETpr_Lv0sVEhCum597vIPFBNnIFieB0kzwPHMBdMI/edit#gid=436275963'
            }
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default DesignationAddModal;
