import { Dispatch, createContext, useContext, useReducer } from 'react';
import { FiltersAction } from '../actions/filtersAction.d';
import { filtersReducer, initialFiltersReducerState } from '../reducers/filtersReducer';
import { IFiltersReducerState } from '../reducers/filtersReducer.d';

interface FiltersContextValue {
  filtersState: IFiltersReducerState;
  filtersDispatch: Dispatch<FiltersAction>;
}

export const FiltersContext = createContext<FiltersContextValue>({
  filtersState: initialFiltersReducerState,
  filtersDispatch: () => {
    return;
  }
});

export const FiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(filtersReducer, initialFiltersReducerState);
  return (
    <FiltersContext.Provider value={{ filtersState: state, filtersDispatch: dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
