import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { IDesignationItemsProps } from '@attendance-web-app/utils/type';
import { getDesignationList } from '../api/getDesignationList';

interface useManageDesignationList {
  designationListData: IDesignationItemsProps[];
  refetchDesignationList: (refetchParams: {
    designation?: string;
    department?: string;
    subDepartment: string;
  }) => Promise<any>;
  designationLoading: boolean;
}
export const useManageDesignationList = (departmentIdNo: string): useManageDesignationList => {
  const {
    data: designationListData,
    isLoading: designationLoading,
    refetchQuery: refetchDesignationList
  } = useCustomListApi({
    queryKey: ['designation_list', departmentIdNo],
    getApiFn: getDesignationList,
    params: {
      departmentIdNo
    },
    enabled: false
  }) as any;

  return {
    designationListData,
    refetchDesignationList,
    designationLoading
  };
};
