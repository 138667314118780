import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import logsServices from '@attendance-web-app/services/logs.services';
import { ILogs } from '@attendance-web-app/types/logs/type';
import { combineFirstNameAndLastName } from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Accordion, Badge, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../_PageWrapper';
interface DynamicUpdatedOfComponentProps {
  name: string;
  dynamicContent: string;
  email: string;
}
const DynamicUpdatedOfComponent = ({ name = '', dynamicContent = '', email = '' }: DynamicUpdatedOfComponentProps) => {
  return (
    <>
      <div className="h5-semibold">{name}</div>
      <div className="h5-regular">{dynamicContent}</div>
      <div className="logs-email h5-regular">{email}</div>
    </>
  );
};
const LogsDetail = () => {
  const navigate = useNavigate();
  const { logsId } = useParams();
  const [logs, setLogs] = useState<ILogs>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSpecificLog();
  }, []);

  const countUpdatedFields = () => {
    if (logs && logs?.previousData && logs?.updatedData) {
      const prevData = JSON.parse(logs.previousData);
      const updatedData = JSON.parse(logs.updatedData);
      let count = 0;
      for (const key in updatedData) {
        if (prevData[key] !== updatedData[key]) {
          count++;
        }
      }
      return count;
    }
    return 0;
  };

  const fetchSpecificLog = async () => {
    try {
      if (logsId) {
        const response = await logsServices.getSpecificLogs(logsId);

        setLogs(response.data);

        setLoading(false);
      }
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const capitalizeFirstWord = (str: string) => {
    const words = str.toLowerCase().split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  };

  if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <PageWrapper hasSideBar={false} navBarTitle="Logs">
        <div>
          <span className="body-large-semibold logs-main-title">Log Detail</span>
          <div className="logs-container">
            {logs?.actionType?.includes('Update') ? (
              <Card className="logs-card">
                <div className="body-large-regular">Log Summary</div>
                <div className="logs-summary body-large-semibold">
                  <div className="">
                    <div className="logs-title h5-regular"> Occurred at</div>
                    <div className="h5-regular"> {format(new Date(logs?.timeStamp), 'd MMMM, yyyy')}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Type</div>
                    <div className="h5-regular">{logs?.actionType}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Updated by</div>
                    <div className="h5-semibold"> {logs?.employee?.name}</div>
                    <div className="h5-regular">{logs?.employee?.role?.role}</div>
                    <div className="logs-email h5-regular">{logs?.employee?.email}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Updated of</div>
                    {logs.actionType === 'Update Employee' && (
                      <DynamicUpdatedOfComponent
                        name={logs?.updatedOfDetails?.name}
                        dynamicContent={logs?.updatedOfDetails?.role?.role}
                        email={logs?.updatedOfDetails?.email}
                      />
                    )}
                    {logs.actionType === 'Update Vendor' && (
                      <DynamicUpdatedOfComponent
                        name={combineFirstNameAndLastName(
                          logs?.updatedOfDetails.firstName ?? '',
                          logs?.updatedOfDetails?.lastName ?? ''
                        )}
                        dynamicContent={logs?.updatedOfDetails?.vendorName ?? ''}
                        email={logs?.updatedOfDetails?.emailAddress ?? ''}
                      />
                    )}
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Description</div>
                    <div className="logs-detail-dark h5-regular">{logs?.remarks}</div>
                  </div>
                </div>
              </Card>
            ) : (
              <Card className="logs-card">
                <div className="h4-semibold">Summary</div>
                <div className="logs-summary body-large-semibold">
                  <div className="">
                    <div className="logs-title h5-regular"> Occured at</div>
                    <div className="h5-regular"> {format(new Date(logs?.timeStamp ?? ''), 'd MMMM, yyyy')}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Type</div>
                    <div className="h5-regular">{logs?.actionType}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Invited by</div>
                    <div className="h5-semibold"> {logs?.employee?.name}</div>
                    <div className="h5-regular py-1">{logs?.employee?.role?.role}</div>
                    <div className="logs-email h5-regular">{logs?.employee?.email}</div>
                  </div>
                  <div>
                    <div className="logs-title h5-regular"> Description</div>
                    <div className="h5-regular">{logs?.remarks}</div>
                  </div>
                </div>
              </Card>
            )}
            {logs?.actionType.includes('Update') ? (
              <div className="logs-accordion-container" style={{ marginBottom: '20px' }}>
                <div className="logs-detail">
                  <span className="logs-detail-light">Showing &nbsp;</span>
                  <span className="h5-semibold">{countUpdatedFields()} updates </span>
                </div>
                <Accordion className="logs-accordion">
                  {logs &&
                    Object.entries(JSON.parse(logs.previousData)).map(([key, value]) => (
                      <Accordion.Item
                        key={key}
                        eventKey={key.toString()}
                        className="timeline-item mb-3 logs-accordion-item"
                      >
                        <Accordion.Header className="logs-accordion-header">
                          <div className="timeline-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="left-content">
                              <div className="h5-regular">
                                <span className="h5-semibold pe-1">{capitalizeFirstWord(key)}</span>
                                <span className="muted-text">changed of &nbsp;</span>
                                <span className="h5-semibold pe-1">{logs?.updatedOfDetails?.name}</span>
                              </div>
                            </div>
                            <div className="right-content">
                              <div className="timeline-time">
                                <span className="h5-regular logs-detail-light">
                                  Changed at &nbsp;
                                  {new Date(logs.timeStamp).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="logs-accordion-table">
                          <table className="ms-3">
                            <tbody>
                              <tr className="logs-accordion-row">
                                <td>
                                  <Badge className="h5-regular badge-initial">Initial</Badge>
                                </td>
                                <td className="body-regular logs-detail-light ps-2">{String(value)}</td>
                              </tr>
                              <tr className="logs-accordion-row">
                                <td className="">
                                  <Badge className="h5-regular badge-updated">Updated</Badge>
                                </td>
                                <td className="body-regular logs-detail-light ps-2">
                                  {JSON.parse(logs.updatedData)[key]?.toString()}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default LogsDetail;
