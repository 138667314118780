import { EmployeeActionType, SaveEmployeeAction } from '../actions/employeeAction.d';
import { ChangeEmployeeStatusType, employeeProps } from './employeeReducer.d';

export const employeeInitialState: employeeProps = {
  selectedEmployeeList: [],
  data: [],
  totalCount: 0,
  totalPages: null,
  departmentSelectedOption: {
    selected: '',
    value: []
  },
  designationSelectedOption: {
    selected: '',
    value: []
  },
  hodSelectedOption: {
    selected: '',
    value: []
  },
  superVisorSelectedOption: {
    selected: '',
    value: []
  },
  departmentTypes: [],
  shiftSelectedOption: {
    value: '',
    label: ''
  },
  paramsValue: {
    name: '',
    limit: 10,
    offset: 0,
    department: '',
    designation: '',
    hod: '',
    supervisor: '',
    status: '',
    role: '',
    shift: ''
  }
};

export const employeeReducer = (state: employeeProps, action: SaveEmployeeAction): employeeProps => {
  switch (action.type) {
    case EmployeeActionType.SAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case EmployeeActionType.SAVE_PARAMS:
      return {
        ...state,
        paramsValue: {
          ...state.paramsValue,
          ...action.payload
        }
      };
    case EmployeeActionType.CHANGE_EMPLOYEE_STATUS_ACTION:
      const { employeeIdNo, status } = action.payload as ChangeEmployeeStatusType;
      const employeeIndex = state.data?.findIndex(employee => employee.employeeIdNo === employeeIdNo) as any;
      if (state.data?.[employeeIndex]) {
        state.data[employeeIndex].status = status;
      }
      return {
        ...state
      };
    default:
      return state;
  }
};
