import Alert from '@attendance-web-app/components/Common/Alert';
import getAllDepartmentServices from '@attendance-web-app/services/department.services';
import { IDepartmentArchiveModalProps } from '@attendance-web-app/types/department/type';
import { Alert as ArchiveAlert, Button, Modal } from 'react-bootstrap';

const ArchivedModal = ({
  customSize,
  show,
  onHide,
  setIsMenuOpen,
  department,
  archiveError,
  setArchiveError
}: IDepartmentArchiveModalProps) => {
  const size = customSize === 'md' ? undefined : customSize;

  const handleArchive = async () => {
    const loggedUser = JSON.parse(localStorage.getItem('admin_user') as string);
    const { departmentIdNo } = loggedUser.department;

    try {
      if (departmentIdNo === department.departmentIdNo) {
        setArchiveError('Invalid archive operation by logged in user');
        return console.error('Invalid archive operation by logged in user');
      } else {
        department.isActive = !department.isActive;
        await getAllDepartmentServices.archiveDepartment(department.departmentIdNo, department.isActive);

        Alert(
          'success',
          ` ${
            department.isActive
              ? 'Successfully department has been removed from archived'
              : 'Successfully department has been archived.'
          }`
        );
        setIsMenuOpen(false);
      }
    } catch (error: any) {
      Alert('error', error.response.message ?? 'Something went wrong !');
    }
  };

  return (
    <Modal show={show} size={size} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          {department.isActive === true ? 'Archive Department' : 'Unarchive Department'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="body-regular ">
          Are you sure you want to {department.isActive === true ? 'archive' : 'unarchive'} department &nbsp;
          <span className="bold">{department.name}</span>
        </span>
        {archiveError ? (
          <div className="archiveAlert">
            <ArchiveAlert
              variant={'warning'}
              dismissible={true}
              onClose={() => {
                setArchiveError('');
              }}
              className="custom-alert"
            >
              {archiveError}
            </ArchiveAlert>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-tertiary"
          onClick={() => {
            onHide();
          }}
        >
          Cancel
        </button>
        <Button
          onClick={() => {
            if (archiveError?.length === 0) {
              onHide();
              handleArchive();
            }
          }}
        >
          {department.isActive === true ? 'Archive Department' : 'Unarchive Department'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchivedModal;
