import { formatAmountToStandardFormat } from '@attendance-web-app/utils';
import format from 'date-fns/format';
import { Accordion } from 'react-bootstrap';

const BalanceSheetTable = ({ monthlyTransactionData }: any) => {
  return (
    <>
      {monthlyTransactionData?.map((transaction: any, index: string) => (
        <div className="balance-sheet-data-row mt-2" key={index}>
          <Accordion aria-expanded={false}>
            <Accordion.Item className="transaction-accordation" eventKey={index}>
              {/* Monthly Transaction and Balance Detail */}
              <Accordion.Header>
                <div className="balance-sheet-data">
                  <span className="body-regular-semibold monthly-data">{transaction.month}</span>
                  <div className="flex-container">
                    <span className="body-small-regular balance-sheet-card-title">Total Transaction</span>
                    <span className="body-regular">
                      Rs. {formatAmountToStandardFormat(transaction.totalTransactions)}
                    </span>
                  </div>
                  <div className="flex-container">
                    <span className="body-small-regular balance-sheet-card-title">Total Paid</span>
                    <span className="body-regular">Rs {formatAmountToStandardFormat(transaction.totalPaid)}</span>
                  </div>
                  <div className="flex-container">
                    <span className="body-small-regular balance-sheet-card-title">Balance</span>
                    <span className="body-regular">
                      Rs. {formatAmountToStandardFormat(transaction.remainingBalance)}{' '}
                    </span>
                  </div>
                </div>
              </Accordion.Header>

              {/* Monthly Payment Table */}
              {transaction.payments.length !== 0 && (
                <Accordion.Body>
                  <div className="table-responsive attendance-table">
                    <table className="table">
                      <thead className="thead body-regular-semibold attendance-thead">
                        <tr className="table-header">
                          <th className="accordation-thead"></th>
                          <th>Vendor</th>
                          <th>Bank</th>
                          <th>Cheque No.</th>
                          <th>Paid Amount</th>
                          <th style={{ paddingRight: '80px' }}>Paid On</th>
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        {transaction.payments.map((payment: any, paymentIndex: number) => {
                          return (
                            <tr className="body-regular align-middle" key={payment?.chequeNo ?? paymentIndex}>
                              <td className="accordation-thead "></td>
                              <td>{payment?.vendor?.vendorName}</td>
                              <td>{payment?.bank?.fullName}</td>
                              <td>{payment?.chequeNo}</td>
                              <td>Rs. {formatAmountToStandardFormat(payment?.amount)}</td>
                              <td style={{ paddingRight: '80px' }}>
                                {payment?.transactionDate && format(new Date(payment?.transactionDate), 'MMM d')}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion>
        </div>
      ))}
    </>
  );
};

export default BalanceSheetTable;
