import { employeeProps } from '../reducers/employeeReducer.d';
export enum EmployeeActionType {
  SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS',
  SAVE_PARAMS = 'SAVE_PARAMS',
  CHANGE_EMPLOYEE_STATUS_ACTION = 'CHANGE_EMPLOYEE_STATUS_ACTION',
  RESET_CONTEXT = 'RESET_CONTEXT'
}

export type saveEmployeeAction = {
  type: EmployeeActionType.SAVE_EMPLOYEE_SUCCESS;
  payload: employeeProps;
};

export type SAVE_PARAMS_ACTION = {
  type: any;
  payload: employeeProps;
};

export type CHANGE_EMPLOYEE_STATUS_ACTION = {
  type: any;
  payload: {
    employeeIdNo: string;
    status: boolean;
  };
};
export type RESET_CONTEXT = {
  type: EmployeeActionType.RESET_CONTEXT;
  payload?: any;
};
export type SaveEmployeeAction =
  | saveEmployeeAction
  | SAVE_PARAMS_ACTION
  | CHANGE_EMPLOYEE_STATUS_ACTION
  | RESET_CONTEXT;
