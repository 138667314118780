import DownloadLogsCsv from '@attendance-web-app/components/_pages/_Dashboard/DownloadLogsCsv';
import LogsTable from '@attendance-web-app/components/_pages/_Dashboard/LogsTable';
import AdvanceLogsFilterSidebar from '@attendance-web-app/components/_pages/_header/AdvanceFilterSidebar';
import LogSearchBar from '@attendance-web-app/components/_pages/_header/LogSearchBar';

import CustomCalendar from '@attendance-web-app/components/Common/CustomCalender';
import CustomFilterChip, { CustomFilterChipTrigger } from '@attendance-web-app/components/Common/CustomFilterChip';
import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import MultiSelectDropDownContainer from '@attendance-web-app/components/Common/MultiSelectDropDownContainer';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import { EmployeeRole } from '@attendance-web-app/types/employee/type';
import IndividualRecords from '../individualDashboard';
import { useLogsScreenHook } from './hooks/useLogsScreenHook';

export default function Logs() {
  const {
    userRole,
    logsActionsTypes,
    logsState,
    showAdvancedFilter,
    openAdvanceFilter,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    selectedLogs,
    setSelectedLogs,
    onApplyHandler,
    onChangeSelectHandler,
    onChangeSearchHandler,
    onDateChangeHandler,
    onDateResetHandler,
    onLogsTypeResetHandler,
    selectedActionTypes,
    onLogsTypeChangeHandlerFromChip,
    handlePaginateClick,
    handleLimitChange,
    onClearAllFilter,
    isRefetching
  } = useLogsScreenHook();
  if (userRole === EmployeeRole.INDIVIDUAL) {
    return <IndividualRecords />;
  } else {
    return (
      <PageWrapper
        hasFooter={logsState.logsDetails.totalCount > 5}
        footerComponent={
          <CustomPagination
            totalCount={logsState?.logsDetails?.totalCount}
            totalPages={logsState?.logsDetails?.totalPages}
            handleLimitChange={handleLimitChange}
            handlePaginateClick={handlePaginateClick}
            offset={logsState.offset}
            limit={logsState.limit}
          />
        }
      >
        <div className="container-fluid ">
          <div className="row">
            {/* <MobileHeader
              open={openMobileHeader}
              handleOpen={handleMobileHeaderOpen}
              handleClose={handleMobileHeaderClose}
            /> */}
            <div className="log-list-header">
              <div className="h3-semibold">Logs</div>
              <div className="employee-list-page">
                <div>
                  {showAdvancedFilter && (
                    <AdvanceLogsFilterSidebar
                      onApplyHandler={onApplyHandler}
                      onChangeSelectHandler={onChangeSelectHandler}
                      selectedActionTypes={selectedActionTypes}
                      logsActionTypeOptions={logsActionsTypes}
                      cancelAdvancedFilter={cancelAdvancedFilter}
                      openAdvanceFilter={openAdvanceFilter}
                      showAdvanceFilter={showAdvancedFilter}
                    />
                  )}
                  <LogSearchBar
                    toggleAdvancedFilter={toggleAdvancedFilter}
                    onChangeSearchHandler={onChangeSearchHandler}
                  />
                </div>
                <DownloadLogsCsv selectedLogs={selectedLogs} disabled={selectedLogs.length === 0} />
              </div>
            </div>

            <div className="customFilterChipContainer">
              {logsState.selectedDate && (
                <CustomFilterChip
                  title="Selected Date"
                  value={logsState.selectedDate}
                  isDate
                  onCrossClick={onDateResetHandler}
                >
                  <CustomCalendar
                    onSelectedDate={onDateChangeHandler}
                    customDatePickerTrigger={
                      <CustomFilterChipTrigger
                        title={'Selected Date'}
                        value={logsState.selectedDate}
                        onCrossClick={onDateResetHandler}
                      />
                    }
                  />
                </CustomFilterChip>
              )}
              {logsState.logsSelectedOption?.selected === 'Type' && (
                <CustomFilterChip
                  title="Type"
                  value={logsState.logsSelectedOption?.value}
                  onCrossClick={onLogsTypeResetHandler}
                  length={selectedActionTypes.length}
                >
                  <MultiSelectDropDownContainer
                    isVisible={true}
                    onLogsTypeResetHandler={onLogsTypeResetHandler}
                    options={logsActionsTypes}
                    selectedOptions={selectedActionTypes}
                    onChangeSelectHandler={onLogsTypeChangeHandlerFromChip}
                  />
                </CustomFilterChip>
              )}

              {(logsState.selectedDate || (logsState.logsSelectedOption?.value?.length ?? 0) > 0) && (
                <div className="customFilterChipContainer__clearAllButton">
                  <span className="body-regular-semibold colors-p02" onClick={onClearAllFilter}>
                    Clear all
                  </span>
                </div>
              )}
            </div>
          </div>
          <LoadingScreen isLoading={isRefetching}>
            <div className={`row`} style={logsState?.logsDetails?.totalCount >= 10 ? { marginBottom: '5rem' } : {}}>
              <LogsTable toggleAdvancedFilter={toggleAdvancedFilter} setSelectedLogs={setSelectedLogs} />
            </div>
          </LoadingScreen>
        </div>
      </PageWrapper>
    );
  }
}
