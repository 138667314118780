import CustomCalendar from '@attendance-web-app/components/Common/CustomCalender';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import React from 'react';

interface AdvanceLogsFilterSidebarProps {
  onApplyHandler: () => void;
  onChangeSelectHandler: (type: string, options: any) => void;
  selectedActionTypes: any[];
  logsActionTypeOptions: any[];
  cancelAdvancedFilter: () => void;
  showAdvanceFilter?: boolean;
  openAdvanceFilter: () => void;
}

const AdvanceLogsFilterSidebar: React.FC<AdvanceLogsFilterSidebarProps> = ({
  onApplyHandler,
  onChangeSelectHandler,
  selectedActionTypes,
  logsActionTypeOptions,
  cancelAdvancedFilter,
  openAdvanceFilter,
  showAdvanceFilter
}) => {
  const hideSidebar = () => {
    cancelAdvancedFilter();
  };
  const onApplyMainHandler = () => {
    onApplyHandler();
    cancelAdvancedFilter();
  };

  return (
    <CustomModal
      showModal={showAdvanceFilter}
      handleMenuOpen={openAdvanceFilter}
      handleHideModal={hideSidebar}
      modalTitle="Advance Filter"
      footerComponent={
        <>
          <button className="btn-tertiary" onClick={hideSidebar}>
            Cancel
          </button>
          <button className="btn " onClick={onApplyMainHandler}>
            Apply Filter
          </button>
        </>
      }
    >
      <div className="advance-filter-content">
        <div className="search-content w-100">
          <CustomMultiDropDownSelect
            title="Select Type"
            options={logsActionTypeOptions}
            selectedOptions={selectedActionTypes}
            onChangeSelectHandler={onChangeSelectHandler}
          />
          <div className="w-100">
            <CustomCalendar isAdvancedFilter={true} onSelectedDate={onChangeSelectHandler} hasCalenderIcon={true} />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AdvanceLogsFilterSidebar;
