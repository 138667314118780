import { IEmployeeDetails, IEmployeeDetailsParams } from '@attendance-web-app/types/employee/type';
import axios, { AxiosResponse } from 'axios';

const getEmployeeDetails = async (
  id: string,
  date?: string,
  period?: string
): Promise<AxiosResponse<IEmployeeDetails, Error>> => {
  const params: IEmployeeDetailsParams = { date: date ?? String(new Date()), period: period };
  if (period === 'month') {
    params.limit = 32;
  }
  return axios.get(`/attendance/${id}`, {
    params: params
  });
};

export default {
  getEmployeeDetails
};
