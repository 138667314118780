export function convertDateFormat(originalDate: any) {
  const originalDateObj = originalDate ? new Date(originalDate) : new Date();
  const day = originalDateObj.getUTCDate();
  const month = originalDateObj.toLocaleString('en-US', { month: 'short' });
  const year = originalDateObj.getUTCFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}
