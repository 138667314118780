import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { colors } from '@attendance-web-app/theme/colors';

export interface SidebarItem {
  icon: (stroke: string) => React.ReactNode;
  title: string;
  route: string | null;
  child: Array<{ route: string; title: string }>;
}
export type SidebarItems = Array<SidebarItem>;
export const sidebarItems = [
  {
    icon: (stroke = colors.colorG02) => <Icons.orgMgmt height={20} width={20} stroke={stroke} />,
    title: 'Org. Management',
    route: null,
    child: [
      {
        route: '/employee-list',
        title: 'Employees'
      },
      {
        route: '/department-list',
        title: 'Departments'
      }
    ]
  },
  {
    icon: (stroke = colors.colorG02) => <Icons.expenseOverview height={20} width={20} stroke={stroke} />,
    title: 'Expense Overview',
    route: null,
    child: [
      {
        route: '/spending-list',
        title: 'Spending'
      },
      {
        route: '/balance-sheet',
        title: 'Balance Sheet'
      },
      {
        route: '/vendor-list',
        title: 'Vendor-wise View'
      }
    ]
  },
  {
    icon: (stroke = colors.colorG02) => <Icons.attendanceList height={20} width={20} stroke={stroke} />,
    route: '/',
    title: 'Attendances',
    child: []
  },
  {
    route: '/logs',
    icon: (stroke = colors.colorG02) => <Icons.logs height={20} width={20} stroke={stroke} />,
    title: 'Logs',
    child: []
  }
];
export const returnSidebarItemTitleFromRoute = (route: string) => {
  switch (route) {
    case '/department-list':
      return 'Org. Management';
    case '/employee-list':
      return 'Org. Management';
    case '/spending-list':
      return 'Expense Overview';
    case '/balance-sheet':
      return 'Expense Overview';
    case '/vendor-list':
      return 'Expense Overview';
    case '/dashboard':
    case '/':
      return 'Attendances';
    case '/logs':
      return 'Logs';
    default:
      return '';
  }
};
export const returnSidebarSubItemTitleFromRoute = (route: string) => {
  switch (route) {
    case '/department-list':
      return 'Departments';
    case '/employee-list':
      return 'Employees';
    case '/spending-list':
      return 'Spending';
    case '/balance-sheet':
      return 'Balance Sheet';
    case '/vendor-list':
      return 'Vendor-wise View';
    default:
      return '';
  }
};
