const RedirectSchedulerModal = () => {
  return (
    <div className="signOut redirectScheduler">
      <div className="square" />
      <div className="navbarMain__avatarContainer">
        <div className="navbarMain__avatarContainer--user">
          <p
            className="userName body-regular action "
            style={{
              width: '10rem'
            }}
          >
            Are you sure you want to be redirected to the scheduler web app ?
          </p>
        </div>
      </div>
      <a className="logout-button text-decoration-none" href={'http://10.0.2.16:4200/login'} target="_blank">
        <p className="body-regular">Go To Scheduler</p>
      </a>
    </div>
  );
};

export default RedirectSchedulerModal;
