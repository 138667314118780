import { SaveLogsActionEnum, saveLogsAction } from '../actions/logsAction.d';
import { saveLogsReducersProps } from './logsReducers.d';
export const saveLogsInitialState: saveLogsReducersProps = {
  data: [],
  logsDetails: {
    totalCount: 0
  },
  logsSelectedOption: {
    selected: '',
    value: []
  },
  selectedDate: '',
  logsSearch: '',
  limit: 10,
  offset: 0
};

export const saveLogsReducer = (state: saveLogsReducersProps, action: saveLogsAction): saveLogsReducersProps => {
  switch (action.type) {
    case SaveLogsActionEnum.SAVE_LOGS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
