import React, { Dispatch, createContext, useContext, useReducer } from 'react';
import { SaveEmployeeAction } from '../actions/employeeAction.d';
import { employeeInitialState, employeeReducer } from '../reducers/employeeReducer';
import { employeeProps } from '../reducers/employeeReducer.d';

export interface EmployeeContextValue {
  employeeState: employeeProps;
  employeeDispatch: Dispatch<SaveEmployeeAction>;
}

export const EmployeeContext = createContext<EmployeeContextValue>({
  employeeState: employeeInitialState,
  employeeDispatch: () => {
    return;
  }
});

export function EmployeeProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(employeeReducer, employeeInitialState);

  return (
    <EmployeeContext.Provider
      value={{
        employeeState: state,
        employeeDispatch: dispatch
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
}
export const useEmployeeContext = () => {
  const context = useContext(EmployeeContext);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
