import { Alert, Button, Modal } from 'react-bootstrap';
import { ISubDepartmentArchiveProps } from './types';

const SubDepartmentArchiveModal = ({
  customSize,
  show,
  onHide,
  subDepartment,
  handleArchive,
  archiveError,
  setArchiveError
}: ISubDepartmentArchiveProps) => {
  const size = customSize === 'md' ? undefined : customSize;

  const handleClose = () => {
    onHide();
    setArchiveError('');
  };

  return (
    <Modal show={show} size={size} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          Archive Sub Department
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="body-regular ">
          Are you sure you want to <span>{!subDepartment?.isActive ? 'unarchive' : 'archive'}</span>{' '}
          <span className="bold">{subDepartment?.name}</span> ?
        </span>
        {archiveError ? (
          <div className="archiveAlert">
            <Alert
              variant={'warning'}
              dismissible={true}
              onClose={() => {
                setArchiveError('');
              }}
              className="custom-alert"
            >
              {archiveError}
            </Alert>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-tertiary"
          onClick={() => {
            onHide();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (archiveError?.length !== 0) onHide();
            if (subDepartment) {
              handleArchive(subDepartment);
            }
          }}
        >
          {!subDepartment?.isActive ? 'Unarchive Sub Department' : 'Archive Sub Department'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubDepartmentArchiveModal;
