import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import DetailSidebar from '@attendance-web-app/components/_pages/_Records/DetailSidebar';
import EmployeeRecord from '@attendance-web-app/components/_pages/_Records/EmployeeRecord';
import RecordHeader from '@attendance-web-app/components/_pages/_Records/RecordHeader';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import employeeDetailsServices from '@attendance-web-app/services/employeeDetails.services';
import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useEffect, useState } from 'react';

export const initialEmployeeDetailsState: IEmployeeDetails = {
  id: 0,
  name: '',
  email: '',
  mobile: '',
  department: '',
  employeeIdNo: '',
  position: '',
  designation: {
    id: 0,
    name: ''
  },
  profilePhoto: '',
  supervisor: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  HOD: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  attendances: []
};

export function IndividualRecords() {
  const [isLoading, setLoading] = useState(true);
  const { filtersState, filtersDispatch } = useFiltersContext();
  const [employeeRecords, setEmployeeRecords] = useState<IEmployeeDetails>(initialEmployeeDetailsState);
  const userid = JSON.parse(window.localStorage.getItem('admin_user') as string)?.employeeIdNo;
  useEffect(() => {
    setLoading(true);
    fetchEmployeeRecords();
  }, [userid, filtersState.timePeriod, filtersState.date]);

  const fetchEmployeeRecords = async () => {
    try {
      const records = await employeeDetailsServices.getEmployeeDetails(
        userid,
        new Date(filtersState.date).toISOString().slice(0, 10),
        filtersState.timePeriod
      );
      setEmployeeRecords(prev => ({ ...prev, ...records.data }));
      setLoading(false);
    } catch (err) {
      handleErrorMessage(err);
    }
  };
  return (
    <PageWrapper hasSideBar={false}>
      <div className="main-wrapper mt-4">
        <div className="d-flex flex-wrap" style={{ gap: '24px' }}>
          <DetailSidebar employeeRecords={employeeRecords} />
          <div className="flex-grow-1">
            <div className="container-fluid">
              <div className="row ">
                {/* This only shows on mobile  <IndividualHeader /> */}

                {/* This shows on Desktop */}
                <RecordHeader employeeRecords={employeeRecords} />
              </div>
              <div className="row employeedetail-table">
                <LoadingScreen isLoading={isLoading}>
                  <EmployeeRecord employeeRecords={employeeRecords} />
                </LoadingScreen>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default IndividualRecords;
