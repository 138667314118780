import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ICustomListApiHookProps, IParams, initialParams } from '../type';

const useCustomListApi = ({
  queryKey = ['customListKey'],
  getApiFn,
  enabled = false,
  params = {},
  options = {}
}: ICustomListApiHookProps) => {
  const queryClient = useQueryClient();

  const { data, isSuccess, isLoading, isRefetching, ...query } = useQuery({
    queryKey,
    queryFn: () => getApiFn(params ?? initialParams),
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });

  // hero function for refetching data with different parameters
  const refetchQuery = async (refetchParams: IParams) => {
    return await queryClient.fetchQuery({
      queryKey,
      queryFn: () => getApiFn(refetchParams)
    });
  };

  return {
    ...query,
    isLoading,
    isRefetching,
    data,
    isSuccess,
    refetchQuery
  };
};
export default useCustomListApi;
