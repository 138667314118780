import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { colors } from '@attendance-web-app/theme/colors';
import { ISidebarMainItem } from './SidebarMainItem';

interface ISidebarMainSubItem extends ISidebarMainItem {
  sidebarSubItemClickHandler: (title: string, route: string) => any;
  activeSidebarSubItem: string;
}
const SidebarMainSubItem = ({
  isActive = false,
  sidebarItemClickHandler,
  item,
  sidebarSubItemClickHandler,
  activeSidebarSubItem,
  isChildLengthGreaterThanZero,
  sideBarClose,
  activeSidebar
}: ISidebarMainSubItem) => {
  return (
    <div className={`sidebarMain__items--child ${isActive ? 'clicked' : ''} ${sideBarClose}`}>
      {isActive && sideBarClose === 'closed' && (
        <div
          className="sidebarSubItemHeadingContainer"
          onClick={sidebarItemClickHandler(item.title, isChildLengthGreaterThanZero, item.route)}
        >
          <p className={`sidebarSubItemHeading sidebarMain__items--title body-regular-semibold `}>{item.title}</p>

          <div className={`sideBarSubItemHeadingIcon ${sideBarClose} ${item.title === activeSidebar ? 'rotate' : ''}`}>
            {isChildLengthGreaterThanZero && (
              <Icons.ArrowRightActive
                height={16}
                width={16}
                stroke={isActive ? colors.colorP02 : colors.colorG02}
                fill="none"
              />
            )}
          </div>
        </div>
      )}
      {item.child.map((subItem, subItemIndex) => {
        let isSubItemActive = subItem.title === activeSidebarSubItem;
        return (
          <div key={subItemIndex} className="child" onClick={sidebarSubItemClickHandler(subItem.title, subItem.route)}>
            <div className="subItemTitleContainer">
              <p className={`subItemTitle body-regular-semibold ${isSubItemActive ? 'clicked' : ''}`}>
                {subItem.title}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SidebarMainSubItem;
