import axios from 'axios';
import setAuthToken from './setAuthToken';

const getAdmin = async (token: string) => {
  try {
    setAuthToken(sessionStorage.getItem('access_token'));
    const response = await axios.get(`${process.env.VITE_BASEURL_FALC}/employee/login`, {
      headers: {
        Authorization: `Bearer ${token ? token : sessionStorage.getItem('access_token')}`
      }
    });

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export default getAdmin;
