import { useSaveLogsContext } from '@attendance-web-app/global-state/context/LogsContext';
import { useState } from 'react';
import { useCloseAbsoluteDiv } from './useCloseAbsoluteDiv';

const staticLimitArr = [5, 10, 20, 50, 100, 200, 500];
export const useCustomPaginationDropDown = (
  handleLimitChange: (limit: number) => void,
  limArr?: Array<number>,
  pageLimit?: number
) => {
  const { logsState } = useSaveLogsContext();
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(pageLimit ?? 10);
  const onClose = () => {
    setIsOpen(false);
  };
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);
  const limitArr = limArr && limArr.length > 0 ? limArr : staticLimitArr;
  const handleDropdownTrigger = () => {
    setIsOpen(prev => !prev);
  };
  const onLimitChanged = (limitData: number) => () => {
    setIsOpen(false);
    setLimit(limitData);
    handleLimitChange(limitData);
  };

  return {
    isOpen,
    limit,
    limitArr,
    handleDropdownTrigger,
    onLimitChanged,
    dropdownRef
  };
};
