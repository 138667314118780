import React, { useState, useEffect } from 'react';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { downloadCsv } from '@attendance-web-app/services/download.service';
import departmentServices from '@attendance-web-app/services/department.services';
import { IAttendance } from '@attendance-web-app/types/employee/type';

export const useHeaderHook = (selectedAttendance: IAttendance[]) => {

  const [startDate, setStartDate] = useState<string>('');
  const { attendanceState } = useAttendanceContext();
  const { filtersState } = useFiltersContext();
  const [deptoptions, setDeptOtions] = useState([{ value: '', label: '' }]);


  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };
  const attendanceData = selectedAttendance
  const attendanceDataToDownload = attendanceData?.map((data: any) => {
    return {
      EmployeeID: data.employee.employeeIdNo,
      Name: data.employee.name,
      Email: data.employee.email,
      Department: data.employee.email,
      Date: data.date,
      CheckInTime: data.checkInConverted,
      CheckOutTime: data.checkOutConverted,
      Supervisor: data.employee?.supervisor?.name,
      Hod: data.employee?.HOD?.name

    }
  })
  const { Department, timePeriod } = filtersState;

  const getSelectedOptions: any = () =>
    Department.length > 0 ? Department.split(',').map(item => ({ label: item, value: Number(item) })) : [];

  function onSelection() {
    // console.log(`Selected option: ${selectedOption.label}`);
    // Perform action based on selected option here
  }

  const handleDownloadCsv = () => {
    const date = new Date(filtersState.date).toISOString().slice(0, 10);
    const department = filtersState?.Department;
    const name = filtersState?.search;
    const period = timePeriod;
    downloadCsv(date, department, name, period);
  };

  const getTimePeriod=()=>{
   
      const timePeriod= attendanceState?.paramsValue?.period
        switch (timePeriod) {
          case 'week':
            return 'Weekly';
          case 'month':
            return 'Monthly';
          default:
            return 'Daily';
        }
      };

 
  return {
    setStartDate,
    attendanceState,
    handleDownloadCsv,
    getTimePeriod,
    onSelection,
    filtersState,
    attendanceDataToDownload,

  }

}