import CustomFilterChip from '@attendance-web-app/components/Common/CustomFilterChip';
import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import MultiSelectDropDownContainer from '@attendance-web-app/components/Common/MultiSelectDropDownContainer';
import SelectDropDownContainer from '@attendance-web-app/components/Common/SelectDropDownContainer';
import EmployeeListHeader from '@attendance-web-app/components/_pages/_EmployeeList/EmployeeListHeader';
import EmployeeListTable from '@attendance-web-app/components/_pages/_EmployeeList/EmployeeListTable';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import AdvanceEmployeeFilterSidebar from '@attendance-web-app/components/_pages/_header/AdvanceFilterEmployeeSidebar';
import { selectedOptionType } from '@attendance-web-app/types';
import { EmployeeRole } from '@attendance-web-app/types/employee/type';
import { shiftArr } from '@attendance-web-app/utils';
import IndividualRecords from '../individualDashboard';
import { statusTypes, useEmployeeScreenHook } from './hooks/useEmployeeScreenHook';

export default function EmployeeList() {
  const {
    userRole,
    employeeState,
    showAdvancedFilter,
    cancelAdvancedFilter,
    onApplyHandler,
    onChangeSelectHandler,
    onChangeSearchHandler,
    selectedOptions,
    onChipChangeSelectHandler,
    actualShowAdvancedFilterHandler,
    onResetHandler,
    handlePaginateClick,
    handleLimitChange,
    onClearAllFilter,
    onEmployeeUpdate,
    openAdvanceFilter
  } = useEmployeeScreenHook();

  if (userRole === EmployeeRole.INDIVIDUAL) {
    return <IndividualRecords />;
  } else {
    return (
      <PageWrapper
        hasFooter={true}
        footerComponent={
          <CustomPagination
            totalCount={employeeState.totalCount as number}
            totalPages={employeeState.totalPages as number}
            handleLimitChange={handleLimitChange}
            handlePaginateClick={handlePaginateClick}
            offset={employeeState?.paramsValue?.offset}
            limit={employeeState?.paramsValue?.limit}
          />
        }
      >
        <div className="">
          <div>
            {/* <Drawer open={open} handleClose={handleClose} /> */}

            <div className="container-fluid">
              <div className="row">
                <EmployeeListHeader
                  toggleAdvancedFilter={actualShowAdvancedFilterHandler}
                  onChangeSearchHandler={onChangeSearchHandler}
                />
                <div className="customFilterChipContainer">
                  {employeeState?.departmentSelectedOption?.selected === selectedOptionType.DEPARTMENT_TYPE && (
                    <CustomFilterChip
                      title="Department"
                      value={employeeState.departmentSelectedOption.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.DEPARTMENT_TYPE);
                      }}
                      length={employeeState.departmentSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={employeeState.departmentTypes}
                        selectedOptions={employeeState?.departmentSelectedOption?.value}
                        selectOptionType={selectedOptionType.DEPARTMENT_TYPE}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                      />
                    </CustomFilterChip>
                  )}
                  {employeeState.designationSelectedOption?.value && (
                    <CustomFilterChip
                      title="Designation"
                      value={employeeState.designationSelectedOption?.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.DESIGNATION_TYPE);
                      }}
                      length={employeeState.designationSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={employeeState.designationTypes}
                        selectedOptions={employeeState?.designationSelectedOption?.value}
                        selectOptionType={selectedOptionType.DESIGNATION_TYPE}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                      />
                    </CustomFilterChip>
                  )}

                  {employeeState.hodSelectedOption?.value && (
                    <CustomFilterChip
                      title="Hod"
                      value={employeeState.hodSelectedOption?.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.HOD_TYPE);
                      }}
                      length={employeeState.hodSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={employeeState.hodTypes}
                        selectedOptions={employeeState?.hodSelectedOption?.value}
                        selectOptionType={selectedOptionType.HOD_TYPE}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                      />
                    </CustomFilterChip>
                  )}

                  {employeeState.superVisorSelectedOption?.value && (
                    <CustomFilterChip
                      title="Supervisor"
                      value={employeeState.superVisorSelectedOption?.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.SUPERVISOR_TYPE);
                      }}
                      length={employeeState.superVisorSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={employeeState.superVisorTypes}
                        selectedOptions={employeeState?.superVisorSelectedOption?.value}
                        selectOptionType={selectedOptionType.SUPERVISOR_TYPE}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                      />
                    </CustomFilterChip>
                  )}

                  {employeeState.roleSelectedOption?.value && (
                    <CustomFilterChip
                      title="Role"
                      value={employeeState.roleSelectedOption?.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.ROLE_TYPE);
                      }}
                      length={employeeState.roleSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={employeeState.roleTypes}
                        selectedOptions={employeeState?.roleSelectedOption?.value}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                        selectOptionType={selectedOptionType.ROLE_TYPE}
                      />
                    </CustomFilterChip>
                  )}

                  {employeeState.statusSelectedOption?.value && (
                    <CustomFilterChip
                      title="Status"
                      value={employeeState.statusSelectedOption?.value}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.STATUS_TYPE);
                      }}
                      length={employeeState.statusSelectedOption?.value.length}
                    >
                      <MultiSelectDropDownContainer
                        isVisible={true}
                        options={statusTypes}
                        selectedOptions={employeeState?.statusSelectedOption?.value}
                        onChangeSelectHandler={onChipChangeSelectHandler}
                        selectOptionType={selectedOptionType.STATUS_TYPE}
                      />
                    </CustomFilterChip>
                  )}
                  {employeeState.shiftSelectedOption?.value && (
                    <CustomFilterChip
                      title="Shift"
                      value={employeeState.shiftSelectedOption?.label}
                      onCrossClick={() => {
                        onResetHandler(selectedOptionType.SHIFT);
                      }}
                      length={0}
                    >
                      <SelectDropDownContainer
                        onClose={() => {}}
                        isVisible={true}
                        options={shiftArr}
                        selectedOptions={employeeState?.shiftSelectedOption as any}
                        onChangeSelectHandler={options => {
                          onChipChangeSelectHandler(selectedOptionType.SHIFT, options);
                        }}
                        selectOptionType={selectedOptionType.SHIFT}
                      />
                    </CustomFilterChip>
                  )}

                  {(employeeState.departmentSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.hodSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.designationSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.roleSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.statusSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.superVisorSelectedOption?.value.length ?? 0) > 0 ||
                  (employeeState.shiftSelectedOption?.value.length ?? 0) > 0 ? (
                    <div className="customFilterChipContainer__clearAllButton">
                      <span className="body-regular-semibold colors-p02" onClick={onClearAllFilter}>
                        Clear all
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <EmployeeListTable isLoading={false} onEmployeeUpdate={onEmployeeUpdate} />
              </div>
            </div>
          </div>
        </div>
        {showAdvancedFilter && (
          <AdvanceEmployeeFilterSidebar
            showAdvanceFilter={showAdvancedFilter}
            openAdvanceFilter={openAdvanceFilter}
            onApplyHandler={onApplyHandler}
            onChangeSelectHandler={onChangeSelectHandler}
            selectedDepartmentTypes={selectedOptions.departmentSelectedOption}
            departmentTypes={employeeState.departmentTypes}
            selectedDesignationTypes={selectedOptions.designationSelectedOption}
            designationTypes={employeeState.designationTypes}
            hodTypes={employeeState.hodTypes}
            selectedHodTypes={selectedOptions.hodSelectedOption}
            supervisorsTypes={employeeState.superVisorTypes}
            selectedSupervisorsTypes={selectedOptions.supervisorSelectedOption}
            selectedShiftTypes={selectedOptions?.shiftSelectedOption}
            selectedStatusTypes={selectedOptions.statusSelectedOption}
            roleTypes={employeeState.roleTypes}
            selectedRoleTypes={selectedOptions.roleSelectedOption}
            cancelAdvancedFilter={cancelAdvancedFilter}
          />
        )}
      </PageWrapper>
    );
  }
}
