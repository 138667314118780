import Alert from '@attendance-web-app/components/Common/Alert';
import axios from 'axios';
interface IParams {
  department: string;
}

export const getDesignationList = async (params: IParams) => {
  try {
    const response = await axios.get('/designation', {
      params: {
        ...params
      }
    });
    return response.data;
  } catch (error: any) {
    Alert('error', error.response.data.message ?? 'Something went wrong !');
    throw error;
  }
};
