import Alert from '@attendance-web-app/components/Common/Alert';
import { changeEmployeeStatus } from '@attendance-web-app/global-state/actions/employeeAction';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import getAllEmployeeServices from '@attendance-web-app/services/employee.services';
import { Alert as ArchiveAlert, Button, Modal } from 'react-bootstrap';

export const DisableEmployeeModal = (props: any) => {
  const { employeeDispatch } = useEmployeeContext();
  const employee = props.employeeListState?.find((employee: any) => employee?.employeeIdNo === props.id);
  const size = props.customSize === 'md' ? undefined : props.customSize;
  const currentEmployeeId = JSON.parse(localStorage.getItem('admin_user') as string).id;

  const handleDisable = async () => {
    try {
      const diasbleEmployeeBody = {
        name: employee?.name,
        email: employee?.email,
        mobile: employee?.mobile,
        designation: employee?.designation.id,
        department: employee?.department.id,
        role: employee?.role.id,
        employeeIdNo: employee?.employeeIdNo,
        employeeId: JSON.parse(localStorage.getItem('admin_user') as string).id,
        grade: employee?.grade,
        supervisorId: employee?.supervisorId,
        status: !employee?.status
      };
      await getAllEmployeeServices.diasbleEmployee(employee?.employeeIdNo, diasbleEmployeeBody);
      employeeDispatch(
        changeEmployeeStatus({ employeeIdNo: diasbleEmployeeBody.employeeIdNo, status: diasbleEmployeeBody.status })
      );
      Alert('success', `Employee has been ${diasbleEmployeeBody.status ? 'enabled ' : 'disabled'} successfully.`);
    } catch (error: any) {
      Alert('error', error?.message);
    }
    props.setIsMenuOpen(false);
  };

  const handleCancel = () => {
    props.onHide();
    props.setIsMenuOpen(false);
  };

  return (
    <Modal show={props.show} size={size} onExit={handleCancel} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          {employee?.status === true ? 'Disable Employee' : 'Enable Employee'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="body-regular">
          Are you sure you want to {employee?.status === true ? 'disable' : 'enable'} the employee "
          <b>{employee?.name}</b>"
        </span>
        {currentEmployeeId === employee.id && (
          <div className="archiveAlert">
            <ArchiveAlert variant={'warning'} dismissible={true} onClose={() => {}} className="custom-alert">
              {'Unable to disable yourselves !'}
            </ArchiveAlert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={props.onHide}>
          Cancel
        </button>
        <Button onClick={props.onHide} onClickCapture={handleDisable} disabled={currentEmployeeId === employee.id}>
          {employee?.status === true ? 'Disable User' : 'Enable User'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
