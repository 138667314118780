interface IShiftBatchProps {
  shift: 'day' | 'evening';
}

interface ISchedulerProps {
  title: 'Running' | 'Stopped';
}
const ShiftBatch = ({ shift }: IShiftBatchProps) => {
  const finalShiftValue = `${shift?.slice(0, 1)?.toUpperCase()}${shift.slice(1, shift.length)}`;
  const isEvening = finalShiftValue.toLowerCase() === 'evening';
  const isNight = finalShiftValue.toLowerCase() === 'night';
  return (
    <div className={`shiftBatch ${isEvening ? 'evening' : isNight ? 'night' : ''}`}>
      <span className={`shiftBatch__title ${isEvening ? 'eveningTitle' : isNight ? 'nightTitle' : ''}`}>
        {finalShiftValue ?? 'Day'}
      </span>
    </div>
  );
};
export const SchedulerBatch = ({ title }: ISchedulerProps) => {
  const isRunning = title === 'Running';
  return (
    <div className={`schedulerBatch ${isRunning ? 'running' : ''}`}>
      <span className={`schedulerBatch__title ${isRunning ? 'runningTitle' : ''}`}>{title}</span>
    </div>
  );
};

export default ShiftBatch;
