import NavbarMain from '@attendance-web-app/components/Common/NavbarMain';
import MainSidebar from '@attendance-web-app/components/Common/SidebarMain/SidebarMain';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import React from 'react';
const PageWrapper = ({
  hasFooter = false,
  children,
  hasSideBar = true,
  navBarTitle = '',
  footerComponent = null
}: {
  hasFooter?: boolean;
  children: React.ReactNode;
  hasSideBar?: boolean;
  navBarTitle?: string;
  footerComponent?: React.ReactNode;
}) => {
  const { sidebarState } = useInitContext();
  const { sidebarOpen } = sidebarState;
  return (
    <>
      <div className="pageWrapper">
        {hasSideBar && <MainSidebar />}
        <div className="pageWrapper__subContainer">
          <NavbarMain navBarTitle={navBarTitle} />
          <div className={`pageWrapper__children ${hasFooter ? 'hasFooter' : ''}`}>{children}</div>
        </div>
      </div>

      {hasFooter ? (
        <div className={`pageWrapper__children--footer ${sidebarOpen ? 'isOpen' : ''}`}>{footerComponent}</div>
      ) : null}
    </>
  );
};

export default PageWrapper;
