import { useState } from 'react';

export const useCustomModalHook = () => {
  const [modalShow, setModalShow] = useState(false);

  const handleCustomOpenModal = () => {
    setModalShow(true);
  };

  const handleCustomCloseModal = () => {
    setModalShow(false);
  };

  return {
    modalShow,
    handleCustomCloseModal,
    handleCustomOpenModal
  };
};
