import { IgetRoleListResponse } from '@attendance-web-app/types/role/type';
import axios, { AxiosResponse } from 'axios';
const getRoleList = async (): Promise<AxiosResponse<IgetRoleListResponse, Error>> => {
  try {
    const response = await axios.get('/role');
    return response;
  } catch (error) {
    throw error;
  }
};

export default {
  getRoleList
};
