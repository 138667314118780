import { Dispatch, createContext, useContext, useReducer } from 'react';
import { AttendanceAction } from '../actions/attendanceAction.d';
import { attendanceReducer, initialAttendanceReducerState } from '../reducers/attendanceReducer';
import { IEmployeeAttendance } from '../reducers/attendanceReducer.d';

interface AttendanceContextValue {
  attendanceState: IEmployeeAttendance;
  attendanceDispatch: Dispatch<AttendanceAction>;
}
export const AttendanceContext = createContext<AttendanceContextValue>({
  attendanceState: initialAttendanceReducerState,
  attendanceDispatch: () => {
    return;
  }
});

export const AttendanceProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(attendanceReducer, initialAttendanceReducerState);
  return (
    <AttendanceContext.Provider value={{ attendanceState: state, attendanceDispatch: dispatch }}>
      {children}
    </AttendanceContext.Provider>
  );
};

export const useAttendanceContext = () => {
  const context = useContext(AttendanceContext);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
