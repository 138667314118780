import Alert from '@attendance-web-app/components/Common/Alert';
import axios from 'axios';
interface IParams {
  departmentId?: string;
  limit?: 10;
  page?: 1;
  name?: string;
}

export const getSubDepartmentList = async (params?: IParams) => {
  try {
    const response = await axios.get('/sub-department', {
      params: {
        ...params,
        departmentId: params?.departmentId,
        name: params?.name ?? '',
        limit: params?.limit ?? 10,
        page: params?.page || 1
      }
    });

    return response.data;
  } catch (error: any) {
    Alert('error', error.response.data.message ?? 'Something went wrong !');
    throw error;
  }
};
