import { useCloseAbsoluteDiv } from '@attendance-web-app/components/Common/hooks/useCloseAbsoluteDiv';
import { DropdownOption } from '@attendance-web-app/types/employee/type';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
interface DropdownMenuProps {
  options?: DropdownOption[];
  onSelection?: (type: string, selectedOption: DropdownOption) => void;
  getTimePeriod: () => string;
}

function DropdownMenu({ options, onSelection, getTimePeriod }: DropdownMenuProps) {
  const defaultTimePeriod = getTimePeriod();
  const [timePeriod, setTimePeriod] = useState(defaultTimePeriod);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };
  const { dropdownRef } = useCloseAbsoluteDiv(onClose);

  function onMenuClick() {
    setIsOpen(!isOpen);
  }

  function onOptionClick(option: DropdownOption) {
    setTimePeriod(option.label);
    onSelection && onSelection('PERIOD_OPTION', option);
    onClose();
  }

  return (
    <Dropdown ref={dropdownRef}>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <div className="d-flex justify-content-between dropdownMenuToggleButton" onClick={onMenuClick}>
          {timePeriod}
          <span className="dropdown-menu__arrow ">
            {isOpen ? (
              <Icon icon="icon-park:up" height={16} width={16} color="#595959" />
            ) : (
              <Icon icon="icon-park:down" height={16} width={16} color="#595959" />
            )}
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className={isOpen ? 'dropdwn-options show' : 'dropdwn-options'}>
        {options?.map(option => (
          <div
            className="dropdown-item  dropdwn-option regular-body "
            key={option.value}
            onClick={() => {
              onOptionClick(option);
            }}
            style={{ height: '48px' }}
            hidden={!isOpen}
          >
            {option.label}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export { DropdownMenu };
