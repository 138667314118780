import Alert from '@attendance-web-app/components/Common/Alert';
import {
  IDepartmentUpdateBody,
  IcreateDepartmentBody,
  IcreateDepartmentResposne,
  IgetDepartmentListResponse,
  IgetdepartmentListArgs,
  IupdateDepartmentResponse,
  IuploadDepartmentCsvResponse
} from '@attendance-web-app/types/department/type';
import axios, { AxiosResponse } from 'axios';

export const getdepartmentList = async (params?: IgetdepartmentListArgs) => {
  try {
    const response = await axios.get('/department', {
      params: {
        ...params,
        name: params?.name ?? '',
        limit: params?.limit ?? 50,
        offset: params?.offset ?? 0
      }
    });
    return response.data;
  } catch (error: any) {
    Alert('error', error.response.data.message ?? 'Something went wrong !');
    throw error;
  }
};

const createDepartment = (body: IcreateDepartmentBody): Promise<AxiosResponse<IcreateDepartmentResposne, Error>> => {
  return axios.post('/department', body);
};

const updateDepartment = (
  id: string,
  body: IDepartmentUpdateBody
): Promise<AxiosResponse<IupdateDepartmentResponse, Error>> => {
  return axios.patch(`/department/${id}`, body);
};

const archiveDepartment = (id: string, isActive: boolean): Promise<AxiosResponse<IupdateDepartmentResponse, Error>> => {
  const body = { isActive };
  return axios.patch(`/department/${id}`, body);
};

const getspecificDepartment = async (name: string): Promise<AxiosResponse<IgetDepartmentListResponse, Error>> => {
  return axios.get(`/department`, { params: { name } });
};

export const uploadDepartmentCsv = (file: File): Promise<AxiosResponse<IuploadDepartmentCsvResponse, Error>> => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/department/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default {
  getdepartmentList,
  createDepartment,
  updateDepartment,
  getspecificDepartment,
  uploadDepartmentCsv,
  archiveDepartment
};
