import { ChangeEmployeeStatusType, employeeProps } from '../reducers/employeeReducer.d';
import {
  CHANGE_EMPLOYEE_STATUS_ACTION,
  EmployeeActionType,
  RESET_CONTEXT,
  SAVE_PARAMS_ACTION,
  saveEmployeeAction
} from './employeeAction.d';

export const saveEmployeeSuccess = (payload: employeeProps): saveEmployeeAction => {
  return {
    type: EmployeeActionType.SAVE_EMPLOYEE_SUCCESS,
    payload
  };
};

export const saveEmployeeParamsOnly = (payload: employeeProps): SAVE_PARAMS_ACTION => ({
  type: EmployeeActionType.SAVE_PARAMS,
  payload
});

export const changeEmployeeStatus = (payload: ChangeEmployeeStatusType): CHANGE_EMPLOYEE_STATUS_ACTION => ({
  type: EmployeeActionType.CHANGE_EMPLOYEE_STATUS_ACTION,
  payload
});

export const resetEmployeeContext = (): RESET_CONTEXT => ({
  type: EmployeeActionType.RESET_CONTEXT
});
