import Alert from '@attendance-web-app/components/Common/Alert';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import vendorServices from '@attendance-web-app/services/vendor.services';
import { IcreateVendorBody, VendorModal } from '@attendance-web-app/types/vendor/type';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IAddVendorForms {
  vendor: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  vatNumber: string;
  location: string;
  locationUrl: string;
  emailAddress: string;
  openingHour: string;
  closingHour: string;
}
const VendorAddModal = ({ show, onHide, refetchVendorDetails }: VendorModal) => {
  const [showTime, setShowTime] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<IAddVendorForms>({
    defaultValues: {
      vendor: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      location: '',
      locationUrl: '',
      emailAddress: '',
      vatNumber: '',
      openingHour: '',
      closingHour: ''
    }
  });
  const resetFormState = () => {
    reset({
      vendor: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      location: '',
      vatNumber: '',
      locationUrl: '',
      emailAddress: '',
      openingHour: '',
      closingHour: ''
    });
    clearErrors();
  };

  const handleAddVendor: SubmitHandler<IAddVendorForms> = async data => {
    try {
      const createVendorBody: IcreateVendorBody = {
        vendorName: data.vendor,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: +data.phoneNumber,
        location: data.location,
        emailAddress: data.emailAddress,
        closingHour: showTime ? data.closingHour : '23:59',
        locationUrl: data.locationUrl,
        vatOrPanNumber: +data.vatNumber,
        openingHour: showTime ? data.openingHour : '00:00'
      };

      await vendorServices.createVendor(createVendorBody);

      resetFormState();
      refetchVendorDetails();
      onHide();
      Alert('success', 'New Vendor has been added successfully.');
    } catch (err: any) {
      err.response.data.message.map((message: any) => {
        if (message.includes('emailAddress')) {
          setError('emailAddress', { message: message || 'Email Address must be an email' });
        }
        if (message.includes('firstName')) {
          setError('firstName', { message: message });
        }
        if (message.includes('lastName')) {
          setError('lastName', { message: message });
        }
        if (message.includes('location')) {
          setError('location', { message: message });
        }
        if (message.includes('Phone')) {
          setError('phoneNumber', { message: message });
        }
        if (message.includes('vendorName')) {
          setError('vendor', { message: message });
        }
        if (message.includes('locationUrl')) {
          setError('locationUrl', { message: message });
        }
        if (message.includes('openingHour')) {
          setError('openingHour', { message: message });
        }
        if (message.includes('closingHour')) {
          setError('closingHour', { message: message });
        }
        if (message.includes('vatNumber')) {
          setError('vatNumber', { message: message });
        }
        if (message.includes('Email address')) {
          setError('emailAddress', { message: message });
        }
      });
    }
  };
  const handleOpen24Hours = (event: any) => {
    setShowTime(!event.target.checked);
    // setShowTime()
  };
  const handleHideModal = () => {
    resetFormState();
    onHide();
  };

  return (
    <CustomModal
      showModal={show}
      handleHideModal={handleHideModal}
      modalTitle="Add Vendor"
      footerComponent={
        <>
          <button className="btn-tertiary pe-3" onClick={handleHideModal}>
            Cancel
          </button>
          <button className="btn" form="add-vendor-form">
            Add Vendor
          </button>
        </>
      }
    >
      <div className="row mt-1">
        <form className="container mt-3" id="add-vendor-form" onSubmit={handleSubmit(handleAddVendor)}>
          <Form>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Vendor"
                {...register('vendor', { required: true })}
                isInvalid={!!errors.vendor}
                autoComplete="off"
                className="form-control "
              />
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-id customlabel-name ${errors.vendor ? 'text-danger' : ''}`}
              >
                Vendor<span className="asteriskVendor">*</span>
              </label>
              {errors.vendor && <ErrorLabel message={errors.vendor.message || 'Vendor(Restaurant Name) is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="First name"
                {...register('firstName', { required: true })}
                isInvalid={!!errors.firstName}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-id customlabel-name  ${errors.firstName ? 'text-danger' : ''}`}
              >
                First name<span className="asteriskVendor">*</span>
              </label>
              {errors.firstName && <ErrorLabel message={errors.firstName.message || 'First name is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Last name"
                {...register('lastName', { required: true })}
                isInvalid={!!errors.lastName}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.lastName ? 'text-danger' : ''}`}
              >
                Last name<span className="asteriskVendor">*</span>
              </label>
              {errors.lastName && <ErrorLabel message={errors.lastName.message || 'Last name is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Contact number"
                maxLength={10}
                {...register('phoneNumber', {
                  required: true,
                  maxLength: 10,
                  validate: value => value.toString().length == 10 || 'Contact Number should be of 10 digits',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Contact Number should be only digits'
                  }
                })}
                isInvalid={!!errors.phoneNumber}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.phoneNumber ? 'text-danger' : ''}`}
              >
                Contact number<span className="asteriskVendor">*</span>
              </label>
              {errors.phoneNumber && (
                <ErrorLabel message={errors.phoneNumber.message || 'Contact Number is required'} />
              )}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="VAT/PAN Number"
                {...register('vatNumber', {
                  required: true,
                  validate: value => {
                    const isValidInteger = /^[0-9]+$/.test(value);
                    return isValidInteger || 'VAT/PAN Number should be a positive integer';
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'VAT/PAN Number should be only digits'
                  }
                })}
                isInvalid={!!errors.vatNumber}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.vatNumber ? 'text-danger' : ''}`}
              >
                VAT/PAN Number<span className="asteriskVendor">*</span>
              </label>
              {errors.vatNumber && <ErrorLabel message={errors.vatNumber?.message || 'VAT/PAN Number is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Location"
                {...register('location', { required: true })}
                isInvalid={!!errors.location}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.location ? 'text-danger' : ''}`}
              >
                Location<span className="asteriskVendor">*</span>
              </label>
              {errors.location && <ErrorLabel message={errors.location.message || 'Location is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Enter valid google map url."
                {...register('locationUrl')}
                isInvalid={!!errors.location}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.location ? 'text-danger' : ''}`}
              >
                Google Map Url
              </label>
              {errors.location && <ErrorLabel message={errors.location.message || 'Location is required'} />}
            </Form.Group>

            <div className="selectTimeContainer">
              <label className="selectTimeLabel body-small-semibold">Select Time</label>
              <div className="selectTimeContainer__checkbox">
                <input type="checkbox" className="checkbox" onChange={handleOpen24Hours} />{' '}
                <label className="body-small-regular">Open 24 hours</label>
              </div>
              {showTime && (
                <div className="time__container">
                  <Form.Group className="col form-group-id">
                    <Form.Control
                      type="time"
                      placeholder="Open Time"
                      {...register('openingHour', { required: showTime })}
                      isInvalid={!!errors.openingHour}
                      autoComplete="off"
                      className="body-regular"
                    />
                    <label
                      htmlFor="floatingInputCustom"
                      className={` customlabel-id customlabel-name  ${errors.openingHour ? 'text-danger' : ''}`}
                    >
                      Open Time<span className="asteriskVendor">*</span>
                    </label>
                    {errors.openingHour && (
                      <ErrorLabel message={errors.openingHour.message || 'Opening Hour is required'} />
                    )}
                  </Form.Group>
                  <Form.Group className="col form-group-id">
                    <Form.Control
                      type="time"
                      {...register('closingHour', { required: showTime })}
                      isInvalid={!!errors.closingHour}
                      autoComplete="off"
                      className="body-regular"
                    />
                    <label
                      htmlFor="floatingInputCustom"
                      className={` customlabel-id customlabel-name  ${errors.closingHour ? 'text-danger' : ''}`}
                    >
                      Close Time<span className="asteriskVendor">*</span>
                    </label>
                    {errors.closingHour && (
                      <ErrorLabel message={errors.closingHour.message || 'Opening Hour is required'} />
                    )}
                  </Form.Group>
                </div>
              )}
            </div>

            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Email address"
                {...register('emailAddress', { required: true })}
                isInvalid={!!errors.emailAddress}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.emailAddress ? 'text-danger' : ''}`}
              >
                Email address<span className="asteriskVendor">*</span>
              </label>
              {errors.emailAddress && (
                <ErrorLabel message={errors.emailAddress.message || 'Email Address is required'} />
              )}
            </Form.Group>
          </Form>
        </form>
      </div>
    </CustomModal>
  );
};

export default VendorAddModal;
