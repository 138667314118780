import { useState } from 'react';
import { useCloseAbsoluteDiv } from './useCloseAbsoluteDiv';

export const useCustomYearlyCalendar = () => {
  const currentYear = new Date().getFullYear();
  const [isOpen, setIsOpen] = useState(false);

  let getPreviousYearsInNumber = (fromWhichYear: number, howManyYearsBefore: number) => {
    let yearArr = [];
    let exactToDate = fromWhichYear - howManyYearsBefore;
    for (let i = fromWhichYear; i >= exactToDate; i--) {
      yearArr.push(i);
    }
    return yearArr;
  };

  //get range of past 4 years
  const yearRange = getPreviousYearsInNumber(currentYear, 4);

  const onClose = () => {
    setIsOpen(false);
  };

  const { dropdownRef } = useCloseAbsoluteDiv(onClose);

  function onMenuClick() {
    setIsOpen(!isOpen);
  }

  return {
    onMenuClick,
    dropdownRef,
    yearRange,
    isOpen,
    onClose
  };
};
