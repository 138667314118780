import CustomCompCSV from '@attendance-web-app/components/Common/CustomCompCsv';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { DepartmentModal } from '@attendance-web-app/types/department/type';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AddDepartment from './AddDepartmentTab';
import { addDepartmentModalHook } from './hooks/addDepartmentModalHook';

const DepartmentAddModal = ({
  show,
  onHide,
  setSuccessMsg,
  setUploadErrorMsg,
  setErrorData,
  fetchAttendanceData,
  setUpdatesMsg,
  setUpdatedData
}: DepartmentModal) => {
  const {
    handleAddDepartment,
    handleHideModal,
    handleTabChange,
    resetFormState,
    selectedFile,
    setSelectedFile,
    setDepartmenHodID,
    setName,
    setDepartmentId,
    isLoading
  } = addDepartmentModalHook({
    show,
    onHide,
    setSuccessMsg,
    setUploadErrorMsg,
    setErrorData,
    fetchAttendanceData,
    setUpdatesMsg,
    setUpdatedData
  });

  return (
    <CustomModal
      showModal={show}
      handleHideModal={onHide}
      modalTitle=" Add Department"
      footerComponent={
        <>
          <button className="btn-tertiary pe-3" onClick={handleHideModal}>
            Cancel
          </button>
          <LoadingScreen isLoading={isLoading}>
            {selectedFile ? (
              <button className="btn" onClick={handleAddDepartment}>
                Add Department
              </button>
            ) : (
              <button className="btn" form="add-department-form">
                Add Department
              </button>
            )}
          </LoadingScreen>
        </>
      }
    >
      <Tabs onSelect={handleTabChange}>
        <TabList className="modal-tablist">
          <Tab className="body-regular-semibold no-bullets">Add Department</Tab>
          {/* <Tab className="body-regular-semibold no-bullets">Upload .csv </Tab> */}
        </TabList>
        <TabPanel>
          <AddDepartment
            setDepartmentId={setDepartmentId}
            setName={setName}
            setDepartmenHodID={setDepartmenHodID}
            fetchAttendanceData={fetchAttendanceData}
            onHide={onHide}
          />
        </TabPanel>
        <TabPanel>
          <CustomCompCSV
            googleDriveLink="https://docs.google.com/spreadsheets/d/1CS11BTLPtYOFKsrPG4KaD2e-AjG5UyAcFFknyrS-67s/edit#gid=0"
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default DepartmentAddModal;
