import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { SetTimePeriod } from '@attendance-web-app/global-state/actions/filtersAction';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { DropdownOption } from '@attendance-web-app/types/employee/type';
import { useEffect, useRef, useState } from 'react';

interface DropdownMenuProps {
  options: DropdownOption[];
  onSelection: (selectedOption: DropdownOption) => void;

  timePeriod: string;
}

function IndividualDropDown({
  options,
  onSelection,

  timePeriod
}: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { filtersDispatch } = useFiltersContext();
  const dropdownRef = useRef<HTMLDivElement>(null);

  function onMenuClick() {
    setIsOpen(!isOpen);
  }

  function onOptionClick(option: DropdownOption) {
    filtersDispatch(SetTimePeriod(option.value));
    setSelectedOption(option);
    onSelection(option);
    setIsOpen(isOpen => !isOpen);
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const getTimePeriod = () => {
    if (timePeriod === 'week') {
      return 'Weekly';
    } else {
      return 'Monthly';
    }
  };

  return (
    <div className="ind-dropdown-menu body-large-regular individual" ref={dropdownRef}>
      <div className="dropdown-menu__header" onClick={onMenuClick}>
        <div className="dropdown-menu__selected-option">{getTimePeriod()}</div>
        <div className="dropdown-menu__arrow">
          {isOpen ? (
            <Icons.ArrowUp height={20} width={20} stroke="#595959" />
          ) : (
            <Icons.ArrowDown height={20} width={20} stroke="#595959" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="my-dropdown-options">
          {options.map(option => (
            <div className="dropdown-menu__option" key={option.value} onClick={() => onOptionClick(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export { IndividualDropDown };
