import Alert from '@attendance-web-app/components/Common/Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const postSubDepartmentToServer = async (payload: {
  departmentId: number;
  name: string;
  isActive: boolean;
}): Promise<any> => {
  try {
    const response = await axios.post(`sub-department`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const useAddSubDepartment = (departmentId: string, onSuccessHandler: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postSubDepartmentToServer,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['sub_department_list', departmentId]
          },
          (oldQueryData: any) => {
            return {
              ...oldQueryData,
              items: [...oldQueryData.items, data]
            };
          }
        );
        Alert('success', 'Sub Department added successfully.');
        onSuccessHandler();
      }
    }
  });
};
