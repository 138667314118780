import CommonDetails from '@attendance-web-app/components/Common/CommonDetails';
import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import { IHandlePaginateClick } from '@attendance-web-app/types';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SubDepartmentDetail from '../_SubDepartmentSummary/SubDepartmentSummary';
import ViewEmployeeListHeader from '../_ViewEmployeeList/ViewEmployeeListHeader';
import ViewEmployeeListTable from '../_ViewEmployeeList/ViewEmployeeListTable';
import { useManageEmployeeList } from './hooks/useMananageEmployeeList';

export default function EmployeeListFromDesignationList() {
  const { designationId } = useParams();
  const location = useLocation();
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const { subDepartmentName, departmentName, departmentId, departmentIdNo, employeeCount } = location?.state ?? {};

  const { refetchEmployeeList, employeeList } = useManageEmployeeList({
    departmentId,
    designationId
  });

  const onSearchChange = (employeeName: string) => {
    refetchEmployeeList({
      department: departmentId,
      designation: designationId,
      name: employeeName,
      limit: 10
    });
  };

  const handleLimitChange = (limitValue: number) => {
    setLimit(limitValue);
    setOffset(0);
    refetchEmployeeList({
      department: departmentId,
      designation: designationId,
      limit: limitValue
    });
  };

  const handlePaginateClick = (event: IHandlePaginateClick) => {
    const limitValue = limit ?? 10;
    const offsetValue = event.selected * limitValue;
    setOffset(offsetValue);
    const payload = {
      limit: limitValue,
      offset: offsetValue
    };
    refetchEmployeeList({
      department: departmentId,
      designation: designationId,
      ...payload
    });
  };

  return (
    <CommonDetails
      title="Employee list"
      detailsComponent={
        <SubDepartmentDetail
          title="Designation Summary"
          name={departmentName}
          designationCount={0}
          subDepartmentName={subDepartmentName}
          departmentId={departmentIdNo || ''}
          employeeCount={employeeCount || 0}
        />
      }
      headerComponent={<ViewEmployeeListHeader searchKeyword={''} setSearchKeyword={onSearchChange} />}
      onSearchChange={onSearchChange}
      addButtonTitle="Invite Employee"
    >
      <>
        <ViewEmployeeListTable searchKeyword="" employeeData={employeeList?.employee || []} />
      </>
      {employeeCount > 5 && (
        <div
          className="col-sm-12 col-md-8 col-xl-9 col-lg-9  col-xxl-10 p-0"
          style={{
            position: 'absolute',
            bottom: 0,
            background: 'white'
          }}
        >
          <CustomPagination
            totalCount={employeeList?.totalCount as number}
            totalPages={employeeList?.totalPages as number}
            handleLimitChange={handleLimitChange}
            handlePaginateClick={handlePaginateClick}
            offset={offset}
            limit={limit}
          />
        </div>
      )}
    </CommonDetails>
  );
}
