import React from 'react';
import { Modal } from 'react-bootstrap';
interface ICustomModal {
  modalTitle: string;
  children: React.ReactNode;
  footerComponent: React.ReactNode;
  showModal?: boolean;
  handleHideModal: () => void;
  handleMenuOpen?: () => void;
}
const CustomModal = ({ modalTitle, footerComponent, showModal, handleHideModal, children, ...props }: ICustomModal) => {
  return (
    <Modal
      className="invite-modal"
      fullscreen={true}
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleHideModal}
      animation={false}
      onExit={props?.handleMenuOpen}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{footerComponent}</Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
