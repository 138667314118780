import { useState } from 'react';

export const useAdvanceFilterSidebar = () => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const toggleAdvancedFilter = () => {
    setShowAdvancedFilter(!showAdvancedFilter);
  };
  const openAdvanceFilter = () => {
    setShowAdvancedFilter(true);
  };
  const cancelAdvancedFilter = () => {
    setShowAdvancedFilter(false);
  };

  return {
    showAdvancedFilter,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    openAdvanceFilter
  };
};
