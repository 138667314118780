import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import CustomYearlyCalendar from '@attendance-web-app/components/Common/CustomYearlyCalendar';
import ScreenHeader from '@attendance-web-app/components/Common/ScreenHeader';

interface IBalanceSheetHeader {
  onYearChange: (year: number) => void;
  handleExport: (year: number) => void;
  year: number;
}

const BalanceSheetHeader = ({ onYearChange, handleExport, year }: IBalanceSheetHeader) => {
  return (
    <ScreenHeader title="Balance Sheet">
      {/* calender component */}
      <div className="d-flex w-100 gap-2 align-items-center flex-1 justify-content-end">
        <div>
          {' '}
          <CustomYearlyCalendar onYearChange={onYearChange} />
        </div>

        <div>
          {/* Export Button */}
          <button className="btn download-button" onClick={() => handleExport(year)}>
            <div className="d-flex flex-row " style={{ alignItems: 'center' }}>
              <div className="me-2"> Export </div>
              <Icons.Downloading height={16} width={16} />
            </div>
          </button>
        </div>
      </div>
    </ScreenHeader>
  );
};
export default BalanceSheetHeader;
