import { getBanksList } from '@attendance-web-app/services/bank.services';
import { getSpecificVendorTransactionListApi } from '@attendance-web-app/services/transaction.services';
import { getSpecificVendorDetail } from '@attendance-web-app/services/vendor.services';
import { getVendorPayout } from '@attendance-web-app/services/vendorPayout.services';
import { joinArrayWithComma } from '@attendance-web-app/utils';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { format } from 'date-fns';
import { useState } from 'react';

interface IRefetchParams {
  vendorId: string;
  banks?: string;
  chequeNo?: string;
  dateTo?: string;
  dateFrom?: string;
}
type selectedDate = Date | null;

interface IErrorMessage {
  errorMessage: string;
}
export const useVenderView = (id: string) => {
  const [chequeNo, setChequeNo] = useState('');
  const [startDate, setStartDate] = useState<selectedDate>(null);
  const [endDate, setEndDate] = useState<selectedDate>(null);
  const [selectedBank, setSelectedBank] = useState([]);
  const [ErrorMessage, setErrorMessage] = useState<IErrorMessage[]>([]) as any;
  const {
    data: vendorDetail,
    isLoading,
    refetch: refetchVendorDetail
  } = useCustomListApi({
    queryKey: ['specificVendor', id],
    getApiFn: getSpecificVendorDetail,
    enabled: !!id,
    params: { id },
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  });
  // fetch specific vendor's payout
  const { data: vendorPayoutDetail, refetchQuery: refetchVendorPayoutDetail } = useCustomListApi({
    queryKey: ['specificVendorPayout', id],
    getApiFn: getVendorPayout,
    enabled: !!id,
    params: { id },
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  });

  // fetch all banks list
  const { data: bankListData }: { data: any } = useCustomListApi({
    queryKey: ['banksList'],
    getApiFn: getBanksList,
    enabled: true,
    params: {}
  });

  const bankOptions = bankListData?.data?.data.map((bankData: any) => {
    return { label: bankData.fullName, value: bankData.id };
  });

  // params fot vnedor transaction
  const refetchVendorTransactionParams: IRefetchParams = {
    vendorId: id as string
  };
  if (selectedBank.length > 0) {
    const banks = joinArrayWithComma(selectedBank, 'value');
    refetchVendorTransactionParams.banks = banks;
  }
  if (chequeNo) {
    refetchVendorTransactionParams.chequeNo = chequeNo;
  }
  if (startDate) {
    refetchVendorTransactionParams.dateFrom = format(new Date(startDate), 'yyyy-MM-dd');
  }

  if (endDate) {
    refetchVendorTransactionParams.dateTo = format(new Date(endDate), 'yyyy-MM-dd');
  }

  // fetch specific vendor's Transaction Details,
  const {
    data: transactionDetail,
    refetch: refetchVendorTransactionDetail,
    refetchQuery: refetchVendorTransaction,
    isLoading: isTransactionLoading,
    error
  } = useCustomListApi({
    queryKey: ['specificVendorTransactions', id],
    getApiFn: getSpecificVendorTransactionListApi,
    enabled: !!id,
    params: refetchVendorTransactionParams,
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  });

  const onChangeSelectHandler = (_: string, newSelectedOptions: any) => {
    setSelectedBank(newSelectedOptions);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChequeNo(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const onStartDateChangeHandler = (date?: selectedDate) => {
    setStartDate(date ?? null);
  };

  const onEndDateChangeHandler = (date?: selectedDate) => {
    setEndDate(date ?? null);
  };

  // from date > to Date : From-date must be smaller than To-date
  // if only toDateSelected : Please select From-Date
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const onApplyHandler = () => {
    let isInvalidParam = false;
    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrorMessage({ errorMessage: 'From-date must be smaller than To-date. Select valid date range!' });
        isInvalidParam = true;
      } else {
        setErrorMessage([]);
        isInvalidParam = false;
      }
    } else if (!startDate && endDate) {
      setErrorMessage({ errorMessage: 'Please enter From Date.' });
      isInvalidParam = true;
    } else if (startDate && !endDate) {
      setErrorMessage([]);
      isInvalidParam = false;
    }
    !isInvalidParam && refetchVendorTransactionDetail(refetchVendorTransactionParams as any);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const resetFilter = () => {
    setChequeNo('');
    setSelectedBank([]);
    setStartDate(null);
    setSelectedEndDate(null);
    setSelectedStartDate(null);
    setEndDate(null);
    setErrorMessage([]);
  };

  const resetStartDate = () => {
    setStartDate(null);
  };
  const resetEndDate = () => {
    setEndDate(null);
  };

  return {
    vendorDetail,
    refetchVendorDetail,
    vendorPayoutDetail,
    refetchVendorPayoutDetail,
    onChangeSelectHandler,
    onInputChange,
    bankOptions,
    selectedBank,
    setChequeNo,
    chequeNo,
    onStartDateChangeHandler,
    onEndDateChangeHandler,
    startDate,
    endDate,
    onApplyHandler,
    transactionDetail,
    resetFilter,
    ErrorMessage,
    resetEndDate,
    resetStartDate,
    refetchVendorTransaction,
    refetchVendorTransactionDetail,
    selectedStartDate,
    selectedEndDate
  };
};
