import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import DepartmentListHeader from '@attendance-web-app/components/_pages/_DepartmentList/DepartmentListHeader';
import DepartmentListTable from '@attendance-web-app/components/_pages/_DepartmentList/DepartmentListTable';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import { useDepartmentHook } from './hooks/useDepartmentList';

export default function DepartmentList() {
  const {
    refetchDepartmentList,
    isLoading,
    departmentListData,
    handlePaginateClick,
    handleLimitClick,
    pageLimit,
    pageOffset,
    onChangeSearchHandler
  } = useDepartmentHook();

  return (
    <PageWrapper
      hasFooter={true}
      footerComponent={
        <CustomPagination
          totalCount={departmentListData?.totalCount as number}
          totalPages={departmentListData?.totalPages as number}
          handleLimitChange={handleLimitClick}
          handlePaginateClick={handlePaginateClick}
          offset={pageOffset}
          limit={pageLimit}
        />
      }
    >
      <div className="main-wrapper-department">
        <div className="d-flex">
          {/* <Drawer open={open} handleClose={handleClose} /> */}
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                {/* <MobileHeader open={open} handleOpen={handleOpen} handleClose={handleClose} /> */}
                <DepartmentListHeader
                  fetchDepartmentData={refetchDepartmentList as any}
                  onChangeSearchHandler={onChangeSearchHandler}
                />
              </div>
              <div className="row">
                <DepartmentListTable
                  departmentListState={departmentListData}
                  fetchDepartmentData={refetchDepartmentList as any}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
