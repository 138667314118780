interface IAvatarNameProps {
  name: string;
  otherClass?: string;
}
const AvatarName = ({ name = '', otherClass }: IAvatarNameProps) => {
  return (
    <div className={`avatarContainer ${otherClass ?? ''}`}>
      <h5 className="h5-semibold avatarContainer__name ">{name?.slice(0, 1)?.toUpperCase() ?? ''}</h5>
    </div>
  );
};

export default AvatarName;
