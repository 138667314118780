import { saveEmployeeSuccess } from '@attendance-web-app/global-state/actions/employeeAction';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from 'axios';
interface IParams {
  name?: string;
  department?: string;
  designation?: string;
  hod?: string;
  employeeIdNo?: string;
  supervisor?: string;
  role?: string;
  status?: string;
  limit?: number;
  offset?: number;
  shift?: string;
}

export const getEmployeeList = async (params: IParams) => {
  const { name, department, designation, limit, offset } = params ?? {};
  try {
    const response = await axios.get('/employee', {
      params: {
        name,
        department,
        designation,
        limit,
        offset
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeListApi = async (params?: IParams, saveEmployeeDataToContext?: (data: any) => void) => {
  const { name, department, designation, hod, employeeIdNo, supervisor, role, status, limit, offset, shift } =
    params ?? {};
  try {
    const response = await axios.get(`/employee`, {
      params: {
        name: name ?? '',
        department: department ?? '',
        designation: designation ?? '',
        hod: hod ?? '',
        employeeIdNo: employeeIdNo ?? '',
        supervisor: supervisor ?? '',
        role: role ?? '',
        status: status ?? '',
        limit: limit ?? 10,
        offset: offset ?? 0,
        shift: shift ?? ''
      }
    });
    saveEmployeeDataToContext && saveEmployeeDataToContext(response.data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const useEmployeeListApi = (params?: IParams) => {
  const { employeeState, employeeDispatch } = useEmployeeContext();
  const queryClient = useQueryClient();

  // saving data on context
  const saveEmployeeDataToContext = (responseData: any) => {
    employeeDispatch(
      saveEmployeeSuccess({
        paramsValue: {
          ...employeeState.paramsValue,
          name: employeeState?.paramsValue?.name
        },
        data: responseData.employee,
        totalCount: responseData.totalCount,
        totalPages: responseData.totalPages
      })
    );
  };
  const { data, isSuccess, isLoading, status, isRefetching, refetch, isFetching, ...query } = useQuery({
    queryKey: ['employeeList'],
    queryFn: () => getEmployeeListApi(params, saveEmployeeDataToContext),
    retryOnMount: false,
    refetchOnMount: false,
    enabled: false
  });
  const refetchQuery = async (refetchParams: IParams) => {
    await queryClient.fetchQuery({
      queryKey: ['employeeList'],
      queryFn: () => getEmployeeListApi(refetchParams, saveEmployeeDataToContext)
    });
  };

  return {
    ...query,
    isLoading,
    isRefetching,
    data,
    isSuccess,
    refetch,
    refetchQuery
  };
};
export default useEmployeeListApi;
