import CustomFilterChip from '@attendance-web-app/components/Common/CustomFilterChip';
import CustomPagination from '@attendance-web-app/components/Common/CustomPagination';
import MultiSelectDropDownContainer from '@attendance-web-app/components/Common/MultiSelectDropDownContainer';
import SelectDropDownContainer from '@attendance-web-app/components/Common/SelectDropDownContainer';
import AdvanceAttendanceFilterSidebar from '@attendance-web-app/components/_pages/_Dashboard/AdvanceAttendanceFilter';
import AttendanceTable from '@attendance-web-app/components/_pages/_Dashboard/AttendanceTable';
import PageWrapper from '@attendance-web-app/components/_pages/_PageWrapper';
import Header from '@attendance-web-app/components/_pages/_header/Header';
import { selectedOptionType } from '@attendance-web-app/types';
import { EmployeeRole } from '@attendance-web-app/types/employee/type';
import { dropOptionsArr } from '@attendance-web-app/utils';
import IndividualRecords from '../individualDashboard';
import { useAttendanceListScreen } from './hooks/useAttendanceListScreen';
export default function Dashboard() {
  const {
    showAdvancedFilter,
    userRole,
    onApplyHandler,
    onChangeSelectHandler,
    selectedOptions,
    onClearAllFilter,
    cancelAdvancedFilter,
    actualShowAdvancedFilterHandler,
    attendanceState,
    onChipChangeSelectHandler,
    onResetHandler,
    handleDownloadCsv,
    attendanceDataToDownload,
    onChangeSearchHandler,
    handleLimitChange,
    handlePaginateClick,
    departmentSelectedOptions,
    hodSelectedOptions,
    designationSelectedOptions,
    supervisorSelectedOptions,
    dropOffServicesSelectedOptions,
    openAdvanceFilter
  } = useAttendanceListScreen();
  if (userRole === EmployeeRole.INDIVIDUAL) {
    return <IndividualRecords />;
  } else {
    return (
      <PageWrapper
        hasFooter={true}
        footerComponent={
          <CustomPagination
            totalCount={attendanceState.totalCount as number}
            totalPages={attendanceState.totalPages as number}
            handleLimitChange={handleLimitChange}
            handlePaginateClick={handlePaginateClick}
            offset={attendanceState?.paramsValue?.offset}
            limit={attendanceState?.paramsValue?.limit}
          />
        }
      >
        <div className="main-wrapper-attendance">
          <div>
            {/* <Drawer open={open} handleClose={handleClose} /> */}
            <div className="container-fluid">
              <div className="row">
                <Header
                  attendanceDataToDownload={attendanceDataToDownload}
                  onChangeSearchHandler={onChangeSearchHandler}
                  onChangeSelectHandler={onChangeSelectHandler}
                  handleDownloadCsv={handleDownloadCsv}
                  toggleAdvancedFilter={actualShowAdvancedFilterHandler}
                />
              </div>

              <div className="customFilterChipContainer">
                {attendanceState?.departmentSelectedOption?.selected === selectedOptionType.DEPARTMENT_TYPE && (
                  <CustomFilterChip
                    title="Department"
                    value={departmentSelectedOptions}
                    onCrossClick={() => onResetHandler(selectedOptionType.DEPARTMENT_TYPE)}
                    length={departmentSelectedOptions.length}
                  >
                    <MultiSelectDropDownContainer
                      isVisible={true}
                      options={attendanceState.departmentTypes}
                      selectedOptions={departmentSelectedOptions}
                      selectOptionType={selectedOptionType.DEPARTMENT_TYPE}
                      onChangeSelectHandler={onChipChangeSelectHandler}
                    />
                  </CustomFilterChip>
                )}

                {attendanceState?.designationSelectedOption?.selected === selectedOptionType.DESIGNATION_TYPE && (
                  <CustomFilterChip
                    title="Designation"
                    value={designationSelectedOptions}
                    onCrossClick={() => onResetHandler(selectedOptionType.DESIGNATION_TYPE)}
                    length={designationSelectedOptions.length}
                  >
                    <MultiSelectDropDownContainer
                      isVisible={true}
                      options={attendanceState?.designationTypes}
                      selectedOptions={designationSelectedOptions}
                      selectOptionType={selectedOptionType.DESIGNATION_TYPE}
                      onChangeSelectHandler={onChipChangeSelectHandler}
                    />
                  </CustomFilterChip>
                )}
                {attendanceState?.hodSelectedOption?.selected === selectedOptionType.HOD_TYPE && (
                  <CustomFilterChip
                    title="HOD"
                    value={hodSelectedOptions}
                    onCrossClick={() => onResetHandler(selectedOptionType.HOD_TYPE)}
                    length={hodSelectedOptions.length}
                  >
                    <MultiSelectDropDownContainer
                      isVisible={true}
                      options={attendanceState.hodTypes}
                      selectedOptions={hodSelectedOptions}
                      selectOptionType={selectedOptionType.HOD_TYPE}
                      onChangeSelectHandler={onChipChangeSelectHandler}
                    />
                  </CustomFilterChip>
                )}
                {attendanceState?.supervisorSelectedOption?.selected === selectedOptionType.SUPERVISOR_TYPE && (
                  <CustomFilterChip
                    title="Supervisor"
                    value={supervisorSelectedOptions}
                    onCrossClick={() => {
                      onResetHandler(selectedOptionType.SUPERVISOR_TYPE);
                    }}
                    length={supervisorSelectedOptions.length}
                  >
                    <MultiSelectDropDownContainer
                      isVisible={true}
                      options={attendanceState.superVisorTypes}
                      selectedOptions={supervisorSelectedOptions}
                      selectOptionType={selectedOptionType.SUPERVISOR_TYPE}
                      onChangeSelectHandler={onChipChangeSelectHandler}
                    />
                  </CustomFilterChip>
                )}

                {attendanceState.selectedDropOffServices?.value && (
                  <CustomFilterChip
                    title="Drop Off Services"
                    value={attendanceState.selectedDropOffServices?.label}
                    onCrossClick={() => {
                      onResetHandler(selectedOptionType.DROP_OFF_SERVICE);
                    }}
                    length={0}
                  >
                    <SelectDropDownContainer
                      isVisible={true}
                      options={dropOptionsArr}
                      selectedOptions={attendanceState?.selectedDropOffServices as any}
                      onChangeSelectHandler={options => {
                        onChipChangeSelectHandler(selectedOptionType.DROP_OFF_SERVICE, options);
                      }}
                      selectOptionType={selectedOptionType.DROP_OFF_SERVICE}
                    />
                  </CustomFilterChip>
                )}
                {(departmentSelectedOptions.length ?? 0 > 0) ||
                (hodSelectedOptions.length ?? 0 > 0) ||
                (supervisorSelectedOptions?.length ?? 0 > 0) ||
                (designationSelectedOptions.length ?? 0 > 0) ||
                (dropOffServicesSelectedOptions?.length ?? 0) > 0 ? (
                  <div className="customFilterChipContainer__clearAllButton">
                    <span className="body-regular-semibold colors-p02" onClick={onClearAllFilter}>
                      Clear all
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="row">
                <AttendanceTable isLoading={false} />
              </div>
            </div>
          </div>
        </div>

        {showAdvancedFilter && (
          <AdvanceAttendanceFilterSidebar
            onApplyHandler={onApplyHandler}
            onChangeSelectHandler={onChangeSelectHandler}
            selectedDepartmentTypes={selectedOptions.departmentSelectedOption}
            departmentTypes={attendanceState.departmentTypes}
            designationTypes={attendanceState.designationTypes}
            selectedDesignationTypes={selectedOptions.designationSelectedOption}
            selectedHodTypes={selectedOptions.hodSelectedOption}
            hodTypes={attendanceState.hodTypes}
            supervisorsTypes={attendanceState.superVisorTypes}
            selectedSupervisorsTypes={selectedOptions.supervisorSelectedOption}
            cancelAdvancedFilter={cancelAdvancedFilter}
            showAdvancedFilter={showAdvancedFilter}
            openAdvanceFilter={openAdvanceFilter}
            selectedDropOffServices={selectedOptions.selectedDropOffServices}
          />
        )}
      </PageWrapper>
    );
  }
}
