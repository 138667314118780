import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { months } from '@fuse-apps/shared-constants';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import useTransactionCalendar from './hook/useTransactionCalendar';

type selectedDate = Date;
interface ICustomCalendar {
  onSelectedDate: () => void;
  customDatePickerTrigger?: React.ReactNode;
  showLabel?: boolean;
  isIcon?: boolean;
  dateSelected?: any;
  resetDate?: () => void;
  disabled: boolean;
}

const currentYear = new Date().getFullYear();

const TransactionCalendar = ({
  onSelectedDate,
  customDatePickerTrigger,
  showLabel = true,
  dateSelected,
  resetDate,
  disabled = false
}: ICustomCalendar) => {
  const { year, setYear, years, month, setMonth, handleCalendarOpen, setIsOpen } = useTransactionCalendar();

  return (
    <div className="customCalender w-100">
      <DatePicker
        disabled={disabled}
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <div className="header">
            <select
              className="body-regular"
              value={year}
              onChange={({ target: { value } }) => {
                setYear(Number(value));
                changeYear(Number(value));
              }}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="body-regular"
              value={month}
              onChange={({ target: { value } }) => {
                setMonth(value);
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={dateSelected}
        onChange={onSelectedDate}
        useWeekdaysShort
        customInput={
          customDatePickerTrigger ? (
            <div onClickCapture={handleCalendarOpen}>{customDatePickerTrigger}</div>
          ) : (
            <button
              className={`calendar-input advancedFilter body-large-regular w-100 ${disabled ? 'disabledClass' : ''}`}
              onClickCapture={handleCalendarOpen}
            >
              {showLabel && (
                <label className={`log-select-label invite-employee-select `} id="log-selected-dropdown">
                  <span className="select-text body-small-regular px-1 color-g02">Select Date</span>
                </label>
              )}
              <div className="calendar-input-label">
                <span className="ml-2 flex-1 w-100 text-start">
                  {dateSelected ? (
                    format(dateSelected, 'dd MMM, yyyy')
                  ) : (
                    <span className="body-regular" style={{ color: '#B8BBB6' }}>
                      {' '}
                      dd/mm/yyyy
                    </span>
                  )}
                </span>
                {dateSelected && (
                  <Icons.CrossIcon height={16} fill="#595959" className="clear-icon" onClick={resetDate} />
                )}
                <span className="mr-2 pl-2">
                  <Icons.calendarIcon />
                </span>
              </div>
            </button>
          )
        }
        onCalendarClose={() => setIsOpen(false)}
        maxDate={new Date()}
      />
    </div>
  );
};
export default TransactionCalendar;
