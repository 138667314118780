import {
  IDesignation,
  IDesignationCreate,
  IcreateDesignationResponse,
  IupdateDesignationResponse,
  IuploadDesignationCsvResponse
} from '@attendance-web-app/types/designation/type';
import axios, { AxiosResponse } from 'axios';

const getDesignationList = (params: { department: string }): Promise<AxiosResponse<IDesignation[], Error>> => {
  return axios.get('/designation', {
    params
  });
};

export const getDesignationOptionType = (department: any): Promise<AxiosResponse<IDesignation[], Error>> => {
  const departmentId = department.department;
  return axios.get('/designation', {
    params: {
      department: departmentId,
      isDepartmentKeyId: true
    }
  });
};

const createDesignation = (data: IDesignationCreate): Promise<AxiosResponse<IcreateDesignationResponse, Error>> => {
  return axios.post(`/designation`, data);
};

const updateDesignation = (
  designationId: number,
  data: string | boolean
): Promise<AxiosResponse<IupdateDesignationResponse, Error>> => {
  return axios.patch(`/designation/${designationId}`, { data: data });
};

const archiveDesignation = (
  id: string,
  isActive: boolean
): Promise<AxiosResponse<IupdateDesignationResponse, Error>> => {
  const body = { isActive };
  return axios.patch(`/designation/${id}`, body);
};

const uploadDesignationCsv = (file: File): Promise<AxiosResponse<IuploadDesignationCsvResponse, Error>> => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post('/designation/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default {
  getDesignationOptionType,
  getDesignationList,
  createDesignation,
  updateDesignation,
  archiveDesignation,
  uploadDesignationCsv
};
