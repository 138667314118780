import { getAccessToken, getUserDetails } from '@attendance-web-app/config/axios.config';
import React, { Dispatch, createContext, useContext, useReducer } from 'react';
import { InitAction } from '../actions/initAction.d';
import { initInitialState, initReducer } from '../reducers/initReducer';
import { initReducersProps } from '../reducers/initReducer.d';

export interface InitContext {
  accessToken: string;
  userDetails: any;
  sidebarState: initReducersProps;
  sidebarDispatch: Dispatch<InitAction>;
}

export const InitStore = createContext<InitContext>({
  accessToken: getAccessToken() || '',
  userDetails: getUserDetails() || null,
  sidebarState: initInitialState,
  sidebarDispatch: () => {
    return;
  }
});

export function InitProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(initReducer, initInitialState);

  return (
    <InitStore.Provider
      value={{
        accessToken: state.accessToken ?? '',
        sidebarState: state,
        userDetails: state.userDetails,
        sidebarDispatch: dispatch
      }}
    >
      {children}
    </InitStore.Provider>
  );
}
export const useInitContext = () => {
  const context = useContext(InitStore);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
