import Alert from '@attendance-web-app/components/Common/Alert';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import departmentServices from '@attendance-web-app/services/department.services';
import employeeServices from '@attendance-web-app/services/employee.services';
import { IEditDepartmentForms, IEditDepartmentModalProps } from '@attendance-web-app/types/department/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
const EditDepartmentModal = ({
  departmentIdNo,
  fetchDepartmentData,
  show,
  onHide,
  setIsMenuOpen,
  selectedDepartment
}: IEditDepartmentModalProps) => {
  const [optionsnew, setOptionsnew] = useState<any>();
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [typeSuperVisor, setTypedSuperVisor] = useState('');
  const editDepartmentHod = {
    label: selectedDepartment?.hod?.employee?.email,
    id: selectedDepartment?.hod?.employee?.id,
    image: selectedDepartment?.hod?.employee?.profilePhoto
  };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    setError
  } = useForm<IEditDepartmentForms>({
    defaultValues: {
      departmentId: selectedDepartment?.departmentIdNo,
      departmentName: selectedDepartment?.name,
      hod: editDepartmentHod.label === undefined ? null : editDepartmentHod
    }
  });

  useEffect(() => {
    getEmployees();
  }, [typeSuperVisor]);

  const handleEditDepartment: SubmitHandler<IEditDepartmentForms> = async data => {
    try {
      const updatedBody = {
        departmentIdNo: String(data.departmentId),
        name: data.departmentName,
        isActive: true,
        hodId: data.hod?.id
      };
      await departmentServices.updateDepartment(departmentIdNo, updatedBody);
      Alert('success', `Department has been updated successfully.`);
      fetchDepartmentData();
      onHide();
      setIsMenuOpen(false);
    } catch (err: any) {
      err.response.data.message.map((message: any) => {
        if (message.includes('DepartmentIdNo')) {
          setError('departmentId', { message: message });
        }
        if (message.includes('Department name')) {
          setError('departmentName', { message: message });
        }
        if (message.includes('Department HOD')) {
          setError('hod', { message: message });
        }

        if (message.includes('No changes for update')) {
          Alert('error', `No changes for update`);
        }
      });
    }
  };

  const getEmployees = async () => {
    try {
      const employeeListArgs = {
        name: typeSuperVisor,
        department: '',
        limit: 2,
        offset: 0
      };
      const hod = await employeeServices.employeeList(employeeListArgs);
      const hodData = hod.data.employee.map(employee => {
        return {
          label: employee.name,
          email: employee.email,
          image: employee.profilePhoto,
          id: employee.id,
          employeeIdNo: employee.employeeIdNo
        };
      });
      setOptionsnew(hodData);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const handleClose = () => {
    onHide();
    setIsMenuOpen(false);
  };

  return (
    <CustomModal
      showModal={show}
      handleMenuOpen={handleClose}
      handleHideModal={onHide}
      modalTitle="Edit Department"
      footerComponent={
        <>
          <button onClick={() => onHide()} className="btn-tertiary">
            Cancel
          </button>
          <button className="btn" form="edit-department-form" disabled={isDirty ? false : true}>
            Edit Department
          </button>
        </>
      }
    >
      <form className="container pe-0" id="edit-department-form" onSubmit={handleSubmit(handleEditDepartment)}>
        <div className="row  mt-2">
          <Form.Group className="col-6  form-group-id">
            <Form.Control
              className="body-regular"
              type="id"
              placeholder="Department Id"
              {...register('departmentId', { required: true })}
              isInvalid={!!errors.departmentId}
            />
            <label
              htmlFor="floatingInputCustom"
              className={` ms-2 customlabel-id edit-department ${errors.departmentId ? 'text-danger' : ''}`}
            >
              Department IDs <span className="form-astrik">*</span>
            </label>
            {errors.departmentId && <ErrorLabel message={errors.departmentId.message || 'Department ID is required'} />}
          </Form.Group>
          <Form.Group className="col-6  form-group-id">
            <Form.Control
              className="body-regular"
              type="name"
              placeholder="Department Name"
              {...register('departmentName', { required: true })}
              isInvalid={!!errors.departmentName}
            />
            <label
              htmlFor="floatingInputCustom"
              className={`ms-2 customlabel-id edit-department ${errors.departmentName ? 'text-danger' : ''}`}
            >
              Department Name <span className="form-astrik">*</span>
            </label>
            {errors.departmentName && (
              <ErrorLabel message={errors.departmentName.message || 'Department name is required'} />
            )}
          </Form.Group>
        </div>
        <div className="form-floating col-12">
          <Controller
            control={control}
            render={({ field: { onChange, name } }) => {
              const handleSelectChange = (selectedOption: any | null) => {
                if (selectedOption) {
                  onChange(selectedOption);
                  setValue(name, selectedOption);
                  setOptionsOpen(false);
                  setTypedSuperVisor(selectedOption.label);
                }
              };

              const handleInputSupervisor = (valueinput: string) => {
                setTypedSuperVisor(valueinput);
              };

              const Option = (props: any) => {
                const { label, email, image, id } = props.data;
                return (
                  <div
                    className="d-flex pb-2 pt-2 mb-4 ms-3 border-bottom align-items-center"
                    onClick={() => {
                      handleSelectChange({ label: email, id: id, image: image });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="flex-shrink-0">
                      {image ? (
                        <img src={image} alt={label} className=" round-image" />
                      ) : (
                        <AvatarName name={label ?? ''} />
                      )}
                    </div>
                    <div className="flex-shrink-1 ms-3 mt-1">
                      <div>{label}</div>
                      <div className="department-addHod-email">{email}</div>
                    </div>
                  </div>
                );
              };

              return (
                <Select
                  className={` body-regular select-input pe-0`}
                  name={name}
                  options={optionsnew}
                  placeholder="-Select HOD-"
                  isSearchable
                  value={getValues(name)}
                  components={{ Option }}
                  onChange={handleSelectChange}
                  onInputChange={handleInputSupervisor}
                  menuIsOpen={isOptionsOpen}
                  inputValue={typeSuperVisor}
                  onKeyDown={() => {
                    setOptionsOpen(true);
                  }}
                  onFocus={() => {
                    setOptionsOpen(true);
                  }}
                  onBlur={() => {
                    setOptionsOpen(false);
                  }}
                  noOptionsMessage={() => 'No Supervisor Found'}
                />
              );
            }}
            name="hod"
          />
          <label className="select-label body-regular" htmlFor="selectdepartment">
            <span className="select-text   body-regular edit-department-hod">Add Hod</span>
          </label>
          {errors.hod && <ErrorLabel message={errors.hod.message || 'This field is required'} />}{' '}
        </div>
      </form>
    </CustomModal>
  );
};

export default EditDepartmentModal;
