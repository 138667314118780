import ReactPaginate from 'react-paginate';
import CustomPaginationDropDown from './CustomPaginationDropDown';

interface IHandlePaginateClick {
  selected: number;
}
interface ICustomPaginateProps {
  limit?: number;
  totalCount: number;
  offset?: number;
  totalPages: number;
  page?: number;
  isPage?: boolean;
  handlePaginateClick: (event: IHandlePaginateClick) => void;
  handleLimitChange: (limit: number) => void;
}
const CustomPagination = ({
  totalCount,
  limit = 10,
  offset = 0,
  page = 1,
  isPage = false,
  totalPages,
  handlePaginateClick,
  handleLimitChange
}: ICustomPaginateProps) => {
  return totalCount > 10 ? (
    <div className="customPagination">
      <div className="body-small-regular">
        Showing pages {!isPage ? offset / limit + 1 : page ? page : '1'} of {totalPages}
      </div>
      <div className="customPagination__pagination">
        <CustomPaginationDropDown handleLimitChange={handleLimitChange} pageLimit={limit} />
        <ReactPaginate
          nextLabel=">"
          forcePage={!isPage ? offset / limit : page - 1}
          onPageChange={handlePaginateClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link page-link-box"
          previousClassName="page-item page-link-box"
          previousLinkClassName="page-link page-link-box"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link page-link-box"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  ) : (
    ''
  );
};

export default CustomPagination;
