import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import getAllEmployeeServices from '@attendance-web-app/services/employee.services';
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import InviteEmployeeTabForm from '../_EmployeeList/InviteEmployeeTabForm';
import UploadCsvTab from '../_EmployeeList/UploadCsvTab';

interface InviteEmployee {
  show: boolean;
  onHide: () => void;
  setCreatedMsg: (msg: string) => void;
  setUploadErrorMsg: (errorMsg: string) => void;
  setUploadUpdatedMsg: (updatedMsg: string) => void;
  setUploadErrorData: React.Dispatch<React.SetStateAction<object[]>>;
  setUploadedData: React.Dispatch<React.SetStateAction<object[]>>;
}

const ViewEmployeeListInviteModal = ({ show, onHide, ...props }: InviteEmployee) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [designation, setDesignation] = useState({ value: '', label: '' });
  const [department, setDepartment] = useState({ value: 0, label: '' });
  const [supervisor, setSupervisor] = useState({ value: '', label: '' });
  const [role, setRole] = useState({ value: '', label: '' });
  const [employeeId, setEmployeeId] = useState('');
  const [employeeGrade, setEmployeeGrade] = useState('');
  const adminId = JSON.parse(localStorage.getItem('admin_user') as string).id;
  const [formErrors, setFormErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [activeTab, setActiveTab] = useState('addEmployee');
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteEmployee = async () => {
    try {
      if (activeTab === 'upload') {
        if (selectedFile) {
          try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await getAllEmployeeServices.uploadEmployeeCsv(formData);
            props.setUploadErrorData(response.data?.errorMessages);
            props.setUploadedData(response.data?.updatedEmployees);
            props.setCreatedMsg(`Uploaded ${response.data?.totalEmployeeCreated} employees successfully`);
            props.setUploadErrorMsg(`${response.data?.uploadFailed} data upload failed`);
            props.setUploadUpdatedMsg(`${response.data?.totalEmployeeUpdated} data updated`);
          } catch (error) {
            console.error(error);
          } finally {
            setSelectedFile(null);
            setIsLoading(false);
          }
        } else {
          console.log('No file selected');
        }
      } else if (activeTab === 'addEmployee') {
        const createEmployeeBody = {
          email: email,
          mobile: Number(mobileNumber),
          designation: Number(designation.value),
          department: department.value,
          role: Number(role.value),
          employeeIdNo: employeeId,
          status: false,
          supervisorId: Number(supervisor.value),
          employeeId: adminId,
          grade: employeeGrade
        };
        // await getAllEmployeeServices.createEmployee(createEmployeeBody);
      }
      resetFormState();
      onHide();
    } catch (error: any) {
      if (error.inner) {
        const errors: any = {};
        error.inner.forEach((validationError: any) => {
          errors[validationError.path] = validationError.message;
        });
        setFormErrors(errors);
      }
    }
  };

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setActiveTab('addEmployee');
    } else if (index === 1) {
      setActiveTab('upload');
    }
  };
  const resetFormState = () => {
    setEmail('');
    setName('');
    setMobileNumber('');
    setDesignation({ value: '', label: '' });
    setDepartment({ value: 0, label: '' });
    setSupervisor({ value: ' ', label: '' });
    setEmployeeId('');
    setFormErrors([]);
  };

  const handleHideModal = () => {
    resetFormState();
    setSelectedFile(null);
    onHide();
  };

  return (
    <CustomModal
      showModal={show}
      handleHideModal={onHide}
      modalTitle="Invite employee"
      footerComponent={
        <>
          <button className="btn-tertiary pe-3" onClick={handleHideModal}>
            Cancel
          </button>
          <LoadingScreen isLoading={isLoading}>
            {selectedFile ? (
              <button className="btn" onClick={handleInviteEmployee}>
                Invite Employee
              </button>
            ) : (
              <button className="btn" form="invite-form">
                Invite Employee
              </button>
            )}
          </LoadingScreen>
        </>
      }
    >
      <Tabs onSelect={handleTabChange}>
        <TabList className="modal-tablist">
          <Tab className="body-regular-semibold no-bullets">Add Employee</Tab>
          <Tab className="body-regular-semibold no-bullets">Upload .csv </Tab>
        </TabList>
        <TabPanel>
          <InviteEmployeeTabForm onHide={onHide} />
        </TabPanel>
        <TabPanel>
          <div style={{ height: '318px' }}>
            <UploadCsvTab selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
          </div>
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};
export default ViewEmployeeListInviteModal;
