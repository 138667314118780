import React, { Dispatch, createContext, useContext, useReducer } from 'react';
import { SaveLogsAction } from '../actions/logsAction.d';
import { saveLogsInitialState, saveLogsReducer } from '../reducers/logsReducers';
import { saveLogsReducersProps } from '../reducers/logsReducers.d';

export interface SaveLogsContext {
  logsState: saveLogsReducersProps;
  logsDispatch: Dispatch<SaveLogsAction>;
}

export const SaveLogsStore = createContext<SaveLogsContext>({
  logsState: saveLogsInitialState,
  logsDispatch: () => {
    return;
  }
});

export function SaveLogsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(saveLogsReducer, saveLogsInitialState);

  return (
    <SaveLogsStore.Provider
      value={{
        logsState: state,
        logsDispatch: dispatch
      }}
    >
      {children}
    </SaveLogsStore.Provider>
  );
}
export const useSaveLogsContext = () => {
  const context = useContext(SaveLogsStore);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
