import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { useState } from 'react';
import { Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const modalStyle = {
  height: '92%',
  top: '8%',
  borderRadius: '5px'
};

function DepartmentUploadError({ handleCloseModal, showModal, uploadErrorData, updatedData }: any) {
  const [activeTab, setActiveTab] = useState(0);

  const csvErrorData = uploadErrorData.map((item: any) => ({
    departmentIdNo: item?.departmentIdNo,
    name: item?.name
  }));

  const ErrorTooltip = ({ errorKey, errorMessage }: any) => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{errorMessage}</Tooltip>}>
        <span className="d-flex gap-2">
          {errorKey} <Icons.Alert height={20} width={20} className="me-1" />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} fullscreen style={modalStyle}>
        <Modal.Header closeButton>
          <Modal.Title>Failed Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <TabList className="modal-tablist mb-4">
              <Tab
                className="body-regular-semibold no-bullets"
                onClick={() => setActiveTab(0)}
                selected={activeTab === 0}
              >
                {`Upload Failed (${uploadErrorData.length})`}{' '}
              </Tab>
              <Tab
                className="body-regular-semibold no-bullets"
                onClick={() => setActiveTab(1)}
                selected={activeTab === 1}
              >
                {`Updated files (${updatedData.length}) `}{' '}
              </Tab>
            </TabList>
            <TabPanel>
              <Table borderless hover>
                <thead className="body-regular department-csv-header">
                  <tr>
                    <th className="differentiate-th p-3">DepartmentIdNo</th>
                    <th className="differentiate-th p-3">Name</th>
                    <th className="p-3">Exceptional Error</th>
                  </tr>
                </thead>
                <tbody className="body-large-regular">
                  {uploadErrorData?.map((item: any) => {
                    return (
                      <tr key={item.departmentIdNo}>
                        <td className="employee-id p-3">
                          {item.error && item.error.departmentIdNo ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.departmentIdNo} errorMessage={item.error.departmentIdNo} />
                            </span>
                          ) : (
                            <span>{item.departmentIdNo}</span>
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.name ? (
                            <span className="error">
                              <ErrorTooltip errorKey={item.name} errorMessage={item.error.name} />
                            </span>
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </td>
                        <td className="employee-id p-3">
                          {item.error && item.error.exceptionalError && (
                            <span className="error">{item.error.exceptionalError}</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table borderless hover>
                <thead className="body-regular department-csv-header">
                  <tr>
                    <th className="differentiate-th p-3">DepartmentIdNo</th>
                    <th className="differentiate-th p-3">Name</th>
                  </tr>
                </thead>
                <tbody className="body-large-regular">
                  {updatedData.map((item: any) => (
                    <tr key={item.departmentIdNo}>
                      <td className="p-3">{item?.departmentIdNo}</td>
                      <td className="p-3">{item?.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPanel>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="d-flex gap-3">
          {activeTab === 0 ? (
            <>
              <CSVLink
                data={csvErrorData}
                className="btn-outline"
                onClick={handleCloseModal}
                filename={'department_error_data.csv'}
              >
                Download CSV
              </CSVLink>

              <button className="btn department-csv-donebtn" onClick={handleCloseModal}>
                Done
              </button>
            </>
          ) : (
            <>
              <button className="btn-outline" disabled>
                Download CSV
              </button>
              <button className="btn department-csv-donebtn" onClick={handleCloseModal}>
                Done
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DepartmentUploadError;
