import { Dispatch, createContext, useContext, useReducer } from 'react';
import { ViewListAction } from '../actions/viewAction.d';
import { initialViewListReducerState, viewActionReducer } from '../reducers/viewReducer';
import { IViewListReducerState } from '../reducers/viewReducer.d';

interface ViewListActionContextValue {
  viewListState: IViewListReducerState;
  viewListActionDispatch: Dispatch<ViewListAction>;
}

export const ViewListContext = createContext<ViewListActionContextValue>({
  viewListState: initialViewListReducerState,
  viewListActionDispatch: () => {
    return;
  }
});

export const ViewListProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(viewActionReducer, initialViewListReducerState);
  return (
    <ViewListContext.Provider value={{ viewListState: state, viewListActionDispatch: dispatch }}>
      {children}
    </ViewListContext.Provider>
  );
};

export const useViewListContext = () => {
  const context = useContext(ViewListContext);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
