import React from 'react';

interface ScreenHeaderProps {
  children: React.ReactNode;
  title: string;
}
const ScreenHeader = ({ children, title }: ScreenHeaderProps) => {
  return (
    <div className="screenHeader">
      <div>
        <span className="h4-semibold">{title}</span>
      </div>
      <div className="flex-grow-1">{children}</div>
    </div>
  );
};

export default ScreenHeader;
