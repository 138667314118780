import { loginSuccess } from '@attendance-web-app/global-state/actions/initAction';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import Auth0Lock from 'auth0-lock';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { authenticate } from './Auth';

const lock = new Auth0Lock(process.env.AUTH0_CLIENT_ID ?? '', process.env.AUTH0_DOMAIN ?? '', {
  container: 'login-container',
  theme: {
    logo: 'https://insights.fusemachines.com/wp-content/uploads/2020/08/fusemachines-logo.png'
  },
  languageDictionary: {
    title: ''
  },
  allowedConnections: ['google-oauth2'],

  allowSignUp: true,

  auth: {
    redirectUrl: process.env.VITE_AUTH0_CALLBACK_URL_FALC,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile email offline_access'
    },
    audience: process.env.AUTH0_AUDIENCE
  }
});

const Login = (): JSX.Element => {
  const { sidebarDispatch } = useInitContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [autherror, seterror] = useState('');

  useEffect(() => {
    lock.show();
    if (location.pathname === '/callback') {
      if (autherror) {
        navigate('/error');
      }
    }
    lock.on('authenticated', async authResult => {
      if (window.AUTHENTICATED) return false;
      window.AUTHENTICATED = true;
      const result = await authenticate(authResult);
      if (result.ok === false) {
        navigate('/error');
      } else if (result.data !== null) {
        sidebarDispatch(
          loginSuccess({
            accessToken: authResult.accessToken,
            userDetails: result?.data ?? {}
          })
        );
        navigate('/dashboard');
        lock.hide();
      }
    });
  }, [autherror, lock]);

  lock.on('authorization_error', () => {
    seterror('false');
  });

  lock.hide();
  return <div id="login-container"></div>;
};
export { lock };

export default Login;
