import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import { Form } from 'react-bootstrap';
import TransactionCalendar from './TransactionCalendar';

const VendorTransactionFilter = ({
  hideAdvancedFilter,
  cancelAdvancedFilter,
  bankOptions,
  selectedBank,
  onChangeSelectHandler,
  chequeNo,
  onInputChange,
  onStartDateChangeHandler,
  onEndDateChangeHandler,
  startDate,
  endDate,
  onApplyHandler,
  ErrorMessage,
  resetEndDate,
  resetStartDate,
  showFilter
}: any) => {
  const onApplyHandlerContainer = () => {
    onApplyHandler();
    hideAdvancedFilter();
  };
  return (
    <>
      <CustomModal
        showModal={showFilter}
        handleHideModal={hideAdvancedFilter}
        modalTitle="Advance Filter"
        footerComponent={
          <>
            <button className="btn-tertiary" onClick={cancelAdvancedFilter}>
              Cancel
            </button>
            <button
              className={`btn ${!startDate && !endDate && !chequeNo && selectedBank.length < 1 ? 'disabled' : ''}`}
              onClick={onApplyHandlerContainer}
            >
              Apply Filter
            </button>
          </>
        }
      >
        <CustomMultiDropDownSelect
          title="Select Bank"
          options={bankOptions}
          selectedOptions={selectedBank}
          onChangeSelectHandler={onChangeSelectHandler}
          isEditable={!chequeNo}
        />

        <Form.Group className="col-12  mt-4 mb-4 form-group-name">
          <label htmlFor="floatingInputCustom" className={`customlabel-name`} style={{ left: '10px' }}>
            <span className="select-text invite-designation-hod"> Cheque No.</span>
          </label>

          <Form.Control
            type="text"
            placeholder="Cheque no"
            autoComplete="off"
            className="form-control "
            onChange={onInputChange}
            value={chequeNo}
            disabled={selectedBank.length > 0}
          />
        </Form.Group>
        <div className="d-flex">
          <div className="pe-0" style={{ width: '45%' }}>
            <TransactionCalendar
              disabled={chequeNo}
              onSelectedDate={onStartDateChangeHandler}
              showLabel={true}
              isIcon={true}
              dateSelected={startDate}
              resetDate={resetStartDate}
            />
          </div>
          <div className=" p-0 customlabel-name" style={{ margin: 'auto', textAlign: 'center' }}>
            <span className="body-regular " style={{ color: '#7C807A' }}>
              {' '}
              to
            </span>
          </div>
          <div className="ps-0" style={{ width: '45%' }}>
            <TransactionCalendar
              disabled={chequeNo}
              onSelectedDate={onEndDateChangeHandler}
              showLabel={false}
              isIcon={true}
              dateSelected={endDate}
              resetDate={resetEndDate}
            />
          </div>
        </div>
        {ErrorMessage && (
          <div className="body-regular mt-1" style={{ color: 'red' }}>
            {ErrorMessage.errorMessage}
          </div>
        )}
      </CustomModal>
    </>
  );
};
export default VendorTransactionFilter;
