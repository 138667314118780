import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import VendorAddModal from '@attendance-web-app/components/_pages/_VendorList/VendorAddModal';
import vendorServices from '@attendance-web-app/services/vendor.services';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Alert from '@attendance-web-app/components/Common/Alert';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import ScreenHeader from '@attendance-web-app/components/Common/ScreenHeader';
import VendorPaymentModal from '@attendance-web-app/components/_pages/_VendorList/VendorMakePaymentModal';
import { formatAmountToStandardFormat } from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useMutation } from '@tanstack/react-query';
import { Badge } from 'react-bootstrap';
import { useAllVenderView } from './hooks/useAllVendorView';

const VendorWiseView = () => {
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showToolTip, setShowToolTip] = useState('');
  const handleAlertPress = (vendorName: string) => {
    setShowToolTip(vendorName);
  };
  const {
    vendorData,
    payoutData,
    refetchPayoutData,
    refetchVendorDetails,
    refetchVendorPayoutDetail,
    refetchVendorDetail,
    vendorDetail,
    vendorPayoutDetail,
    refetchVendorTransactionDetail,
    transactionDetail,
    vendorLoading,
    vendorRefetching
  } = useAllVenderView() as any;

  const hasRequestedPayout = (vendorId: string): any => {
    return payoutData?.data?.payoutRequests.find((payout: any) => payout.vendor.id === vendorId);
  };

  const [deleteConfirmationId, setDeleteConfirmationId] = useState('');

  const handleDeleteClick = (vendorId: string) => {
    setDeleteConfirmationId(vendorId);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationId('');
  };

  const { mutate } = useMutation({
    mutationFn: vendorServices.deactivateVendorApi,
    onSuccess: () => {
      refetchPayoutData();
      refetchVendorDetails();
    },
    onError: (error: any) => {
      handleErrorMessage(error);
    }
  });

  const handleDeleteVendor = async (vendorId: string) => {
    try {
      const payload = { id: vendorId };
      mutate(payload);
      Alert('success', 'Vendor has been deleted successfully.');
    } catch (error: any) {
      Alert('error', error.response.data.message ?? 'Error: while deleting vendor !');
    }
  };
  const handlePayoutButtonClick = (vendorId: string) => {
    refetchVendorPayoutDetail({ id: vendorId });
    refetchVendorTransactionDetail({ vendorId });
    refetchVendorDetail({ id: vendorId });
    setShowPaymentModal(true);
  };
  const handlePayment = () => {
    refetchPayoutData();
    refetchVendorDetails();
  };

  return (
    <>
      <ScreenHeader title="Vendor's List">
        <div className="d-flex justify-content-end">
          <button className="btn download-button body-regular" onClick={() => setShowAddVendorModal(true)}>
            <Icons.PlusIcon height={16} />
            <span> Add Vendor </span>
          </button>
        </div>
        <VendorAddModal
          refetchVendorDetails={refetchVendorDetails}
          show={showAddVendorModal}
          onHide={() => setShowAddVendorModal(false)}
        />
      </ScreenHeader>

      <LoadingScreen isLoading={vendorLoading || vendorRefetching}>
        <div className="=col-12">
          {vendorData && vendorData.length <= 0 ? (
            <div className="empty-message body-large-regular">No vendor to display.</div>
          ) : (
            <div className="list-wrapper">
              <div className="table-responsive vendor-list-table" style={{ overflowY: 'auto' }}>
                <table className="table">
                  <thead className="thead  body-regular-semibold  department-thead" style={{ zIndex: 2 }}>
                    <tr className="department-header">
                      <th>VENDOR</th>
                      <th>OWNER</th>
                      <th>LOCATION</th>
                      <th className="px-6">CONTACT DETAILS</th>
                      <th>TOTAL TRANSACTION</th>
                      <th>BALANCE</th>
                      <th>PAYMENT DATE </th>
                      <th> </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {vendorData?.map(
                      ({
                        vendorId,
                        vendorName,
                        firstName,
                        lastName,
                        location,
                        phoneNumber,
                        totalTransaction,
                        balance,
                        lastPaymentDate,
                        isActive,
                        profilePhoto
                      }: any) => (
                        <React.Fragment key={vendorId}>
                          <tr key={vendorId} className={deleteConfirmationId === vendorId ? 'no-top-border' : ''}>
                            <td className="align-middle emp-id body-small-regular payoutMessage-relative">
                              <span> {vendorName}</span>
                              {/* {hasRequestedPayout(vendorId) &&
                                hasRequestedPayout(vendorId)?.remainingAmountToPay !== 0 && (
                                  <span
                                    className="payoutMessage"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleAlertPress(vendorName);
                                      setDeleteConfirmationId('');
                                    }}
                                  >
                                    <p>
                                      <Icons.CircleAlert height={20} width={20} className="me-1" />
                                    </p>
                                  </span>
                                )} */}
                            </td>

                            <td className="align-middle emp-id body-small-regular">
                              {firstName} {lastName}
                            </td>
                            <td className="align-middle emp-id body-small-regular">{location}</td>
                            <td className="align-middle emp-id body-small-regular">{phoneNumber}</td>
                            <td className="align-middle emp-id body-small-regular">
                              Rs. {formatAmountToStandardFormat(totalTransaction)}
                            </td>
                            <td className="align-middle emp-id body-small-regular">
                              Rs. {formatAmountToStandardFormat(balance)}
                            </td>
                            <td className="align-middle emp-id body-small-regular">
                              {new Date(lastPaymentDate).toISOString().split('T')[0] === '1970-01-01' ? (
                                <span>-</span>
                              ) : (
                                new Date(lastPaymentDate).toISOString().split('T')[0]
                              )}
                            </td>
                            {isActive === false && profilePhoto === null ? (
                              <>
                                <td colSpan={2}>
                                  <Badge className="archive-badge body-small-regular pending-badge">Pending</Badge>
                                </td>
                              </>
                            ) : (
                              <>
                                <td className="align-middle emp-id body-small-regular" style={{ cursor: 'pointer' }}>
                                  <Link to={`/vendor-list/${vendorId}`} className="body-regular-underline view-profile">
                                    <div className="viewIcon">
                                      <Icons.ViewIcon height={20} />
                                    </div>
                                  </Link>
                                </td>
                                <td className="align-middle emp-id body-small-regular" style={{ cursor: 'pointer' }}>
                                  <div
                                    className="deleteIcon"
                                    onClick={() => {
                                      handleDeleteClick(vendorId);
                                      setShowToolTip('');
                                    }}
                                  >
                                    <Icons.DeleteIcon height={20} />
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                          {deleteConfirmationId === vendorId && (
                            <tr>
                              <td colSpan={9}>
                                <div className="red-line">
                                  <div className="delete-confirmation">
                                    <p className="align-middle emp-id body-small-regular">
                                      Are you sure you want to delete vendor ?
                                    </p>
                                    <p className="body-small-regular balance-sheet-card-title">
                                      This action cannot be undone. All associated records, and transactions linked to
                                      this vendor will also be permanently removed.
                                    </p>
                                    <div className="btn-content">
                                      <button className="btn cancel-button" onClick={handleCancelDelete}>
                                        Cancel
                                      </button>
                                      <button
                                        className="btn download-button  body-regular"
                                        onClick={() => handleDeleteVendor(vendorId)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                          {hasRequestedPayout(vendorId) &&
                            hasRequestedPayout(vendorId)?.remainingAmountToPay !== 0 &&
                            showToolTip === vendorName && (
                              <tr
                                className={showToolTip === vendorName ? '' : ''}
                                style={{
                                  borderTop: '2px solid white'
                                }}
                              >
                                <td colSpan={9}>
                                  <div className="red-line">
                                    <div className="delete-confirmation">
                                      <p className="align-middle emp-id body-small-regular">Payment Request</p>
                                      <p className="body-small-regular balance-sheet-card-title">
                                        {vendorName} has requested for payment of Rs.{' '}
                                        {formatAmountToStandardFormat(
                                          hasRequestedPayout(vendorId)?.remainingAmountToPay
                                        )}
                                      </p>
                                      <div className="btn-content">
                                        <button className="btn cancel-button    " onClick={() => handleAlertPress('')}>
                                          Cancel
                                        </button>
                                        <button
                                          className="btn download-button body-regular "
                                          onClick={() => handlePayoutButtonClick(vendorId)}
                                        >
                                          Make Payment
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <VendorPaymentModal
            refetchPaymentDetails={handlePayment}
            show={showPaymentModal}
            transactionDetail={transactionDetail}
            onHide={() => setShowPaymentModal(false)}
            refetchVendorPayoutDetail={refetchVendorPayoutDetail}
            vendorDetail={vendorDetail ?? { vendorName: '', vendorId: '' }}
            vendorPayoutDetail={(vendorPayoutDetail ?? {}) as any}
          />
        </div>
      </LoadingScreen>
    </>
  );
};

export default VendorWiseView;
