import Alert from '@attendance-web-app/components/Common/Alert';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import {
  resetEmployeeContext,
  saveEmployeeParamsOnly,
  saveEmployeeSuccess
} from '@attendance-web-app/global-state/actions/employeeAction';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import { Option } from '@attendance-web-app/global-state/reducers/employeeReducer.d';
import { IHandlePaginateClick } from '@attendance-web-app/pages/dashboard/type';
import { getdepartmentList } from '@attendance-web-app/services/department.services';
import { getDesignationOptionType } from '@attendance-web-app/services/designation.service';
import employeeService from '@attendance-web-app/services/employee.services';
import { getHodList } from '@attendance-web-app/services/hod.services';
import getRoleService from '@attendance-web-app/services/role.services';
import { selectedOptionType } from '@attendance-web-app/types';
import {
  findArrayDifference,
  joinArrayWithComma,
  returnArrayOfObjectIfValueMatchesFromArrayOfString,
  returnArrayOfObjectIfValueNotMatchesFromArrayOfString,
  returnArrayOfStringFromObjectKey
} from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { useState } from 'react';
import { getEmployeeListApi } from './useEmployeeApiHook';
export const statusTypes: Option[] = [
  {
    label: 'Active',
    value: 'true'
  },
  {
    label: 'Inactive',
    value: 'false'
  }
];

export const useEmployeeScreenHook = () => {
  const userRole = JSON.parse(window.localStorage.getItem('admin_user') as string)?.role.role;

  const { employeeState, employeeDispatch } = useEmployeeContext();
  const { paramsValue: contextParams, departmentTypes } = employeeState;

  // Internal States.
  const [selectedOptions, setSelectedOptions] = useState<{
    departmentSelectedOption: Option[];
    designationSelectedOption: Option[];
    hodSelectedOption: Option[];
    supervisorSelectedOption: Option[];
    statusSelectedOption: Option[];
    roleSelectedOption: Option[];
    shiftSelectedOption: Option;
  }>({
    departmentSelectedOption: [],
    designationSelectedOption: [],
    hodSelectedOption: [],
    supervisorSelectedOption: [],
    statusSelectedOption: [],
    roleSelectedOption: [],
    shiftSelectedOption: {
      value: '',
      label: ''
    }
  });

  const [open, setOpen] = useState(false);

  // custom hooks
  const { openAdvanceFilter, showAdvancedFilter, toggleAdvancedFilter, cancelAdvancedFilter } =
    useAdvanceFilterSidebar();

  const { refetchQuery: refetchEmployeeList } = useCustomListApi({
    queryKey: ['employeeList'],
    getApiFn: getEmployeeListApi,
    enabled: false,
    params: {}
  });
  const { refetchQuery: refetchDepartmentTypes } = useCustomListApi({
    queryKey: ['departmentList'],
    getApiFn: getdepartmentList,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchDesignationTypes } = useCustomListApi({
    queryKey: ['designationList'],
    getApiFn: getDesignationOptionType,
    enabled: false,
    params: { department: '', isDepartmentKeyId: true }
  });

  const { refetchQuery: refetchHodTypes } = useCustomListApi({
    queryKey: ['hodList'],
    getApiFn: getHodList,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchSupervisorTypes } = useCustomListApi({
    queryKey: ['supervisorList'],
    getApiFn: employeeService.supervisorList,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchRoleTypes } = useCustomListApi({
    queryKey: ['roleList'],
    getApiFn: getRoleService.getRoleList,
    enabled: false,
    params: {}
  });

  // reuseable Refetch
  const refetchEmployeeListHelper = (payload: any) => {
    refetchEmployeeList(payload)
      .then((response: any) => {
        employeeDispatch(
          saveEmployeeSuccess({
            data: response.employee,
            totalCount: response.totalCount,
            totalPages: response.totalPages
          })
        );
      })
      .catch((err: any) => {
        Alert('error', err.response.data.message ?? 'Something went wrong');
      });
  };

  const saveDepartmentDataToEmployeeContext = (
    department: { label: string; value: string; departmentIdNo: string }[]
  ) => {
    employeeDispatch(
      saveEmployeeSuccess({
        departmentTypes: department
      })
    );
  };

  const onDepartmentSuccess = (data: any) => {
    const department = data?.departments.map((departmentType: any) => {
      return {
        label: departmentType.name,
        value: departmentType.id,
        departmentIdNo: departmentType.departmentIdNo
      };
    });
    saveDepartmentDataToEmployeeContext(department);
    saveOptionsTypesToEmployeeContextFromServer('departmentTypes', department);
  };

  const saveHodDataToEmployeeContext = (
    hod: { label: string; value: string; departmentIdNo: string; departmentName: string }[]
  ) => {
    employeeDispatch(
      saveEmployeeSuccess({
        hodTypes: hod
      })
    );
  };

  const onHodSuccess = (data: any) => {
    const hod = data?.hod.map((hodType: any) => {
      return {
        label: hodType.employee.name,
        value: hodType.id,
        departmentName: hodType.department.name,
        departmentIdNo: hodType.department.departmentIdNo
      };
    });
    saveHodDataToEmployeeContext(hod);
  };

  const saveRoleDataToEmployeeContext = (role: { label: string; value: string }[]) => {
    employeeDispatch(
      saveEmployeeSuccess({
        roleTypes: role
      })
    );
  };

  const onRoleSuccess = (data: any) => {
    const role = data?.role?.map((roleType: any) => {
      return {
        label: roleType.role,
        value: roleType.id
      };
    });
    saveRoleDataToEmployeeContext(role);
  };

  const actualShowAdvancedFilterHandler = () => {
    if (!showAdvancedFilter) {
      const params = {
        name: '',
        limit: 100,
        offset: 0
      };
      refetchDepartmentTypes(params)
        .then((response: any) => {
          onDepartmentSuccess(response.data);
        })
        .catch(err => {
          handleErrorMessage(err);
        });
      refetchHodTypes({})
        .then((response: any) => {
          onHodSuccess(response.data);
        })
        .catch(err => {
          handleErrorMessage(err);
        });
      refetchRoleTypes({})
        .then((response: any) => {
          onRoleSuccess(response.data);
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
    openAdvanceFilter();
  };

  // event listeners
  const handleOpen = () => {
    setOpen(true);
    document.body.style.overflowY = 'hidden';
  };
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflowY = 'auto';
  };
  const changingSelectedStateHelperFunction = (
    selectedOptionsTitle:
      | 'departmentSelectedOption'
      | 'designationSelectedOption'
      | 'hodSelectedOption'
      | 'supervisorSelectedOption'
      | 'statusSelectedOption'
      | 'roleSelectedOption'
      | 'shiftSelectedOption',
    options: any
  ) => {
    setSelectedOptions({
      ...selectedOptions,
      [selectedOptionsTitle]: options
    });
  };

  const saveOptionsTypesToEmployeeContextFromServer = (
    type: 'departmentTypes' | 'hodTypes' | 'designationTypes' | 'superVisorTypes' | 'shiftTypes',
    options: { label: string; value: string; departmentIdNo?: string; departmentName?: string }[]
  ) => {
    employeeDispatch(
      saveEmployeeSuccess({
        [type]: options
      })
    );
  };

  const saveSelectedDesignationDataToEmployeeContext = (selectedDesignations: any) => {
    employeeDispatch(
      saveEmployeeSuccess({
        designationSelectedOption: {
          selected: 'DESIGNATION_TYPE',
          value: selectedDesignations
        }
      })
    );
  };

  const saveSelectedSupervisorDataToEmployeeContext = (selectedSupervisors: any) => {
    employeeDispatch(
      saveEmployeeSuccess({
        superVisorSelectedOption: {
          selected: 'SUPERVISOR_TYPE',
          value: selectedSupervisors
        }
      })
    );
  };

  const fetchDesignationOfSelectedDepartmentForBothChipAndFilter = ({
    selectedDepartments,
    options,
    isChip = false,
    isCallFromHod = false
  }: {
    selectedDepartments: any;
    options: any;
    isChip?: boolean;
    isCallFromHod?: boolean;
  }) => {
    // if no department is selected remove designation data amd selected designation context
    if (!selectedDepartments) {
      saveOptionsTypesToEmployeeContextFromServer('designationTypes', []);
      saveSelectedDesignationDataToEmployeeContext([]);
      setSelectedOptions(prev => ({
        ...prev,
        designationSelectedOption: []
      }));

      if (isChip) {
        const designationPayload = { designation: '' } as any;
        employeeDispatch(saveEmployeeParamsOnly({ ...designationPayload }));
      }

      if (!isCallFromHod) {
        setSelectedOptions(prev => ({
          ...prev,
          departmentSelectedOption: options
        }));
      }
    } else {
      refetchDesignationTypes({ department: selectedDepartments })
        .then((response: any) => {
          const designationsOfSelectedDepartments = response?.data?.map((designation: { id: number; name: string }) => {
            return {
              label: designation?.name,
              value: designation?.id
            };
          });

          // remove selected designation when its associated department is unselected
          const filteredSelectedDesignations = designationsOfSelectedDepartments.filter((designation: any) =>
            selectedOptions.designationSelectedOption.some(
              (selectedDesignation: any) => selectedDesignation.value === designation.value
            )
          );
          saveOptionsTypesToEmployeeContextFromServer('designationTypes', designationsOfSelectedDepartments);

          setSelectedOptions(prev => ({
            ...prev,
            designationSelectedOption: filteredSelectedDesignations
          }));
          if (!isCallFromHod) {
            setSelectedOptions(prev => ({
              ...prev,
              departmentSelectedOption: options
            }));
          }

          if (isChip) {
            const selectedDesignationParams = joinArrayWithComma(filteredSelectedDesignations, 'value');
            const designationPayload = { designation: selectedDesignationParams } as any;
            employeeDispatch(saveEmployeeParamsOnly({ ...designationPayload }));
            saveSelectedDesignationDataToEmployeeContext(filteredSelectedDesignations);
          }
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
  };

  const fetchSupervisorOfSelectedHodForBothChipAndFilter = ({
    selectedHods,
    isChip = false
  }: {
    selectedHods: any;
    isChip?: boolean;
  }) => {
    // if no hod is selected remove supervisor data amd selected supervisor context
    if (!selectedHods) {
      saveOptionsTypesToEmployeeContextFromServer('superVisorTypes', []);
      saveSelectedSupervisorDataToEmployeeContext([]);
      setSelectedOptions(prev => ({
        ...prev,
        supervisorSelectedOption: []
      }));

      if (isChip) {
        const supervisorPayload = { supervisor: '' } as any;
        employeeDispatch(saveEmployeeParamsOnly({ ...supervisorPayload }));
      }
    } else {
      refetchSupervisorTypes({ hod: selectedHods })
        .then((response: any) => {
          const supervisorOfSelectedHods = response?.data?.data?.supervisors?.map(
            (supervisor: { id: number; name: string }) => {
              return {
                label: supervisor.name,
                value: supervisor.id
              };
            }
          );

          // remove selected designation when its associated department is unselected
          const filteredSelectedSupervisors = supervisorOfSelectedHods.filter((supervisor: any) =>
            selectedOptions.supervisorSelectedOption.some(
              (selectedSupervisor: any) => selectedSupervisor.value === supervisor.value
            )
          );
          saveOptionsTypesToEmployeeContextFromServer('superVisorTypes', supervisorOfSelectedHods);

          setSelectedOptions(prev => ({
            ...prev,
            supervisorSelectedOption: filteredSelectedSupervisors
          }));

          if (isChip) {
            const selectedSupervisorParams = joinArrayWithComma(filteredSelectedSupervisors, 'value');
            const supervisorPayload = { supervisor: selectedSupervisorParams } as any;
            employeeDispatch(saveEmployeeParamsOnly({ ...supervisorPayload }));
            saveSelectedSupervisorDataToEmployeeContext(filteredSelectedSupervisors);
          }
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
  };

  const changingHodSelectedHelperFunction = (hodOptions: Option[], departmentOptions: Option[]) => {
    setSelectedOptions({
      ...selectedOptions,
      hodSelectedOption: hodOptions,
      departmentSelectedOption: departmentOptions
    });
  };

  const chipChangeDepartmentAndHodOptions = (departmentOptions: Option[], hodOptions: Option[]) => {
    const payload = {
      ...contextParams,
      department: joinArrayWithComma(departmentOptions, 'value'),
      hod: joinArrayWithComma(hodOptions, 'value')
    };
    const actionPayload = {
      ...employeeState,
      departmentSelectedOption: {
        selected: selectedOptionType.DEPARTMENT_TYPE,
        value: departmentOptions.length > 0 ? departmentOptions : []
      },
      hodSelectedOption: {
        selected: selectedOptionType.HOD_TYPE,
        value: hodOptions
      }
    } as any;

    // attendanceDispatch(Add(actionPayload));
    employeeCommonHandler(payload, actionPayload);
  };

  const changingBothHodChipAndAdvanceFilterDropDown = (
    options: Option[],
    hodSelectedOption: Option[] = selectedOptions.hodSelectedOption,
    isChip = false
  ) => {
    if (options.length > hodSelectedOption.length) {
      const departmentIdNos = returnArrayOfStringFromObjectKey(options, 'departmentIdNo');
      const filteredDepartments = returnArrayOfObjectIfValueMatchesFromArrayOfString(
        departmentTypes,
        departmentIdNos,
        'departmentIdNo'
      );

      const resultsArr: any = [];
      filteredDepartments.forEach((department: any) => {
        const exists = selectedOptions?.departmentSelectedOption.some(
          selected => selected.departmentIdNo === department.departmentIdNo
        );

        if (!exists) {
          resultsArr.push(department);
        }
      });
      const finalSelectedDepartmentsValue = [...resultsArr, ...selectedOptions?.departmentSelectedOption];
      changingHodSelectedHelperFunction(options, finalSelectedDepartmentsValue);
      fetchDesignationOfSelectedDepartmentForBothChipAndFilter({
        selectedDepartments: joinArrayWithComma(finalSelectedDepartmentsValue, 'value'),
        options: finalSelectedDepartmentsValue,
        isCallFromHod: true
      });

      if (isChip) {
        chipChangeDepartmentAndHodOptions(finalSelectedDepartmentsValue, options);
        const supervisorPayload = { supervisor: '' } as any;
        employeeDispatch(saveEmployeeParamsOnly({ ...supervisorPayload }));
      }
    } else {
      const differenceValue = findArrayDifference(options, hodSelectedOption, 'departmentIdNo');
      const departmentIdNos = returnArrayOfStringFromObjectKey(differenceValue, 'departmentIdNo');

      const filteredDepartments = returnArrayOfObjectIfValueNotMatchesFromArrayOfString(
        selectedOptions.departmentSelectedOption,
        departmentIdNos,
        'departmentIdNo'
      );

      changingHodSelectedHelperFunction(options, filteredDepartments ?? []);
      fetchDesignationOfSelectedDepartmentForBothChipAndFilter({
        selectedDepartments: joinArrayWithComma(filteredDepartments, 'value'),
        options: filteredDepartments,
        isCallFromHod: true
      });
      if (isChip) {
        chipChangeDepartmentAndHodOptions(filteredDepartments, options);
      }
    }
  };
  // when user change any of the value of selected options on chip.
  const onChipChangeSelectHandler = (type: string, options: any) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        chipChangeHandlerHelper('departmentSelectedOption', options, 'department', selectedOptionType.DEPARTMENT_TYPE);
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({ selectedDepartments, options, isChip: true });
        break;

      case selectedOptionType.DESIGNATION_TYPE:
        chipChangeHandlerHelper(
          'designationSelectedOption',
          options,
          'designation',
          selectedOptionType.DESIGNATION_TYPE
        );
        break;

      case selectedOptionType.HOD_TYPE:
        changingBothHodChipAndAdvanceFilterDropDown(options, selectedOptions.hodSelectedOption, true);
        const selectedHods = joinArrayWithComma(options, 'value');
        fetchSupervisorOfSelectedHodForBothChipAndFilter({ selectedHods, isChip: true });
        break;

      case selectedOptionType.SUPERVISOR_TYPE:
        chipChangeHandlerHelper('supervisorSelectedOption', options, 'supervisor', selectedOptionType.SUPERVISOR_TYPE);
        break;
      case selectedOptionType.ROLE_TYPE:
        chipChangeHandlerHelper('roleSelectedOption', options, 'role', selectedOptionType.ROLE_TYPE);
        break;
      case selectedOptionType.STATUS_TYPE:
        chipChangeHandlerHelper('statusSelectedOption', options, 'status', selectedOptionType.STATUS_TYPE);
        break;
      case selectedOptionType.SHIFT:
        chipChangeHandlerHelper('shiftSelectedOption', options, 'shift', selectedOptionType.SHIFT);
        break;

      default:
        break;
    }
  };
  // when user choose from dropdown of logsType on Advanced Filter
  const onChangeSelectHandler = (type: string, options: any) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({ selectedDepartments, options });
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        changingSelectedStateHelperFunction('designationSelectedOption', options);
        break;
      case selectedOptionType.HOD_TYPE:
        if (options.length > selectedOptions.hodSelectedOption.length) {
          const departmentIdNos = returnArrayOfStringFromObjectKey(options, 'departmentIdNo');
          const filteredDepartments = returnArrayOfObjectIfValueMatchesFromArrayOfString(
            departmentTypes,
            departmentIdNos,
            'departmentIdNo'
          );

          const resultsArr: any = [];
          filteredDepartments.forEach((department: any) => {
            const exists = selectedOptions?.departmentSelectedOption.some(
              selected => selected.departmentIdNo === department.departmentIdNo
            );

            if (!exists) {
              resultsArr.push(department);
            }
          });

          changingHodSelectedHelperFunction(options, [...resultsArr, ...selectedOptions?.departmentSelectedOption]);
        } else {
          const differenceValue = findArrayDifference(options, selectedOptions.hodSelectedOption, 'departmentIdNo');
          const departmentIdNos = returnArrayOfStringFromObjectKey(differenceValue, 'departmentIdNo');

          const filteredDepartments = returnArrayOfObjectIfValueNotMatchesFromArrayOfString(
            selectedOptions.departmentSelectedOption,
            departmentIdNos,
            'departmentIdNo'
          );
          changingHodSelectedHelperFunction(options, filteredDepartments ?? []);
        }

        const selectedHods = joinArrayWithComma(options, 'value');
        fetchSupervisorOfSelectedHodForBothChipAndFilter({ selectedHods });
        break;
      case selectedOptionType.SUPERVISOR_TYPE:
        changingSelectedStateHelperFunction('supervisorSelectedOption', options);
        break;
      case selectedOptionType.STATUS_TYPE:
        changingSelectedStateHelperFunction('statusSelectedOption', options);
        break;
      case selectedOptionType.ROLE_TYPE:
        changingSelectedStateHelperFunction('roleSelectedOption', options);
        break;
      case selectedOptionType.SHIFT:
        changingSelectedStateHelperFunction('shiftSelectedOption', options);
        break;
      default:
        break;
    }
  };

  // commonHandler for preparing payload, refetching with that payload and updating context with current action payload
  const employeeCommonHandler = (params: any, actionPayload: any, clearAll = false) => {
    const payload = {
      ...contextParams,
      ...params
    };

    refetchEmployeeListHelper(payload);
    if (!clearAll) {
      employeeDispatch(saveEmployeeSuccess(actionPayload));
      employeeDispatch(saveEmployeeParamsOnly({ ...params }));
    }
  };

  // when user type input in search box
  const onChangeSearchHandler = (name: string) => {
    const payload = {
      name,
      offset: 0
    };
    const actionPayload = {
      department: employeeState.departmentTypes,
      designation: employeeState.designationTypes,
      hod: employeeState.hodTypes,
      supervisor: employeeState.superVisorTypes,
      role: employeeState.roleTypes,
      status: employeeState.statusTypes
    };
    employeeCommonHandler(payload, actionPayload);
  };

  const chipChangeHandlerHelper = (
    selectedOptionsTitle:
      | 'departmentSelectedOption'
      | 'designationSelectedOption'
      | 'hodSelectedOption'
      | 'supervisorSelectedOption'
      | 'statusSelectedOption'
      | 'roleSelectedOption'
      | 'shiftSelectedOption',
    options: any,
    paramTitle: string,
    selectionOptionType: string
  ) => {
    // setting state

    changingSelectedStateHelperFunction(selectedOptionsTitle, options);
    const payload = {
      ...contextParams,
      [paramTitle]:
        selectionOptionType !== selectedOptionType.SHIFT ? joinArrayWithComma(options, 'value') : options.value
    };

    const actionPayload = {
      ...employeeState,
      [selectedOptionsTitle]:
        selectionOptionType !== selectedOptionType.SHIFT
          ? {
              selected: selectionOptionType,
              value: options
            }
          : options
    };
    // fetch
    employeeCommonHandler(payload, actionPayload);
    // save context
    employeeDispatch(saveEmployeeSuccess(actionPayload));
  };

  // when user click the cross button on chip container in employee list
  const onResetHandler = (type: string) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        const departmentPayload = {
          department: '',
          designation: ''
        };
        const departmentActionPayload = {
          departmentSelectedOption: {
            selected: '',
            value: []
          },
          designationSelectedOption: {
            selected: '',
            value: []
          },
          designationTypes: []
        };

        employeeCommonHandler(departmentPayload, departmentActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          departmentSelectedOption: [],
          designationSelectedOption: []
        });
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        const designationPayload = {
          designation: ''
        };
        const designationActionPayload = {
          designationSelectedOption: {
            selected: '',
            value: []
          }
        };
        employeeCommonHandler(designationPayload, designationActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          designationSelectedOption: []
        });
        break;
      case selectedOptionType.HOD_TYPE:
        const hodPayload = {
          hod: ''
        };
        const hodActionPayload = {
          hodSelectedOption: {
            selected: '',
            value: []
          }
        };
        employeeCommonHandler(hodPayload, hodActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          hodSelectedOption: []
        });
        break;
      case selectedOptionType.SUPERVISOR_TYPE:
        const supervisorPayload = {
          supervisor: ''
        };
        const supervisorActionPayload = {
          superVisorSelectedOption: {
            selected: '',
            value: []
          }
        };
        employeeCommonHandler(supervisorPayload, supervisorActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          supervisorSelectedOption: []
        });
        break;
      case selectedOptionType.STATUS_TYPE:
        const statusPayload = {
          status: ''
        };
        const statusActionPayload = {
          statusSelectedOption: {
            selected: '',
            value: []
          }
        };
        employeeCommonHandler(statusPayload, statusActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          statusSelectedOption: []
        });
        break;
      case selectedOptionType.ROLE_TYPE:
        const rolePayload = {
          role: ''
        };
        const roleActionPayload = {
          roleSelectedOption: {
            selected: '',
            value: []
          }
        };
        employeeCommonHandler(rolePayload, roleActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          roleSelectedOption: []
        });
        break;
      case selectedOptionType.SHIFT:
        const shiftPayload = {
          shift: ''
        };
        const shiftActionPayload = {
          shiftSelectedOption: {
            label: '',
            value: ''
          }
        };
        employeeCommonHandler(shiftPayload, shiftActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          shiftSelectedOption: {
            label: '',
            value: ''
          }
        });
        break;
      default:
        break;
    }
  };
  // when user click the apply button on advanced filter
  const onApplyHandler = () => {
    const payload = {
      ...contextParams,
      offset: 0,
      department: joinArrayWithComma(selectedOptions.departmentSelectedOption, 'value'),
      hod: joinArrayWithComma(selectedOptions.hodSelectedOption, 'value'),
      designation: joinArrayWithComma(selectedOptions.designationSelectedOption, 'value'),
      supervisor: joinArrayWithComma(selectedOptions.supervisorSelectedOption, 'value'),
      status: joinArrayWithComma(selectedOptions.statusSelectedOption, 'value'),
      role: joinArrayWithComma(selectedOptions.roleSelectedOption, 'value'),
      shift: selectedOptions.shiftSelectedOption.value ?? ''
    };
    const actionPayload = {
      ...employeeState,
      departmentSelectedOption: {
        selected: selectedOptionType.DEPARTMENT_TYPE,
        value: selectedOptions.departmentSelectedOption
      },
      designationSelectedOption: {
        selected: selectedOptionType.DESIGNATION_TYPE,
        value: selectedOptions.designationSelectedOption
      },
      hodSelectedOption: {
        selected: selectedOptionType.HOD_TYPE,
        value: selectedOptions.hodSelectedOption
      },
      superVisorSelectedOption: {
        selected: selectedOptionType.SUPERVISOR_TYPE,
        value: selectedOptions.supervisorSelectedOption
      },
      statusSelectedOption: {
        selected: selectedOptionType.STATUS_TYPE,
        value: selectedOptions.statusSelectedOption
      },
      roleSelectedOption: {
        selected: selectedOptionType.ROLE_TYPE,
        value: selectedOptions.roleSelectedOption
      },
      shiftSelectedOption: {
        ...selectedOptions.shiftSelectedOption
      }
    } as any;
    // employeeDispatch(saveEmployeeSuccess(actionPayload));
    employeeCommonHandler(payload, actionPayload);
  };

  // when user click the pagination item
  const handlePaginateClick = (event: IHandlePaginateClick) => {
    const limit = employeeState.paramsValue?.limit ?? 10;
    const offset = event.selected * limit;
    const payload = {
      limit,
      offset
    };

    const actionPayload = {
      ...contextParams,
      limit,
      offset
    };

    refetchEmployeeListHelper(actionPayload);
    employeeDispatch(
      saveEmployeeParamsOnly({
        ...payload
      } as any)
    );
  };
  // when user click the pagination item
  const handleLimitChange = (limit: number) => {
    const payload = {
      limit,
      offset: 0
    };
    const actionPayload = {
      ...contextParams,
      ...payload
    };
    refetchEmployeeListHelper(actionPayload);
    employeeDispatch(
      saveEmployeeParamsOnly({
        ...payload
      } as any)
    );
  };

  const resetSelectedOptionsFromAdvanceFilter = () => {
    setSelectedOptions({
      departmentSelectedOption: [],
      designationSelectedOption: [],
      hodSelectedOption: [],
      supervisorSelectedOption: [],
      statusSelectedOption: [],
      roleSelectedOption: [],
      shiftSelectedOption: {
        label: '',
        value: ''
      }
    });
  };

  const onClearAllFilter = () => {
    const payload = {
      department: '',
      designation: '',
      hod: '',
      supervisor: '',
      shift: ''
    };
    const actionPayload = {
      departmentSelectedOption: {
        selected: '',
        value: []
      },
      designationSelectedOption: {
        selected: '',
        value: []
      },
      hodSelectedOption: {
        selected: '',
        value: []
      },
      superVisorSelectedOption: {
        selected: '',
        value: []
      },
      roleSelectedOption: {
        selected: '',
        value: []
      },
      statusSelectedOption: {
        selected: '',
        value: []
      },
      shiftSelectedOption: {
        value: '',
        label: ''
      }
    };
    employeeCommonHandler(payload, actionPayload);
    employeeDispatch(resetEmployeeContext());
    resetSelectedOptionsFromAdvanceFilter();
  };
  const onEmployeeUpdate = () => {
    const payload = {
      ...contextParams
    };
    refetchEmployeeListHelper(payload);
  };
  return {
    open,
    userRole,
    handleOpen,
    handleClose,
    employeeState,
    showAdvancedFilter,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    onApplyHandler,
    onChangeSelectHandler,
    onChangeSearchHandler,
    employeeCommonHandler,
    selectedOptions,
    onChipChangeSelectHandler,
    actualShowAdvancedFilterHandler,
    onResetHandler,
    onClearAllFilter,
    handlePaginateClick,
    handleLimitChange,
    onEmployeeUpdate,
    openAdvanceFilter
  };
};
