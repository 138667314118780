import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import Alert from '@attendance-web-app/components/Common/Alert';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import { isDepartNestedDepartmentName } from '@attendance-web-app/utils';
import { ISubDepartmentItemProps, subDepartmentListProps } from '@attendance-web-app/utils/type';
import React, { useState } from 'react';
import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import { UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SubDepartmentArchiveModal from './SubDepartmentArchiveModal';
import { useUpdateSubDepartment } from './hooks/useUpdateSubDepartment';
interface SubDepartmentTableProps {
  isSubDepartmentLoading: boolean;
  subDepartmentListData: subDepartmentListProps;
  openAdvanceFilter: () => void;
  setSelectedDepartment: UseFormSetValue<{
    subDepartmentName: string;
    subDepartmentId: null | number;
  }>;
  departmentIdNo: string;
}
const SubDepartmentTable = ({
  isSubDepartmentLoading = false,
  subDepartmentListData,
  openAdvanceFilter,
  setSelectedDepartment,
  departmentIdNo
}: SubDepartmentTableProps) => {
  const navigate = useNavigate();

  const [selectedSubDepartment, setSelectedSubDepartment] = useState<number | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  const handleOpen = (id: number) => {
    setSelectedId(id);
    setIsMenuOpen(!isMenuOpen);
  };

  const onSubDepartmentNamePress = (subDepartment: ISubDepartmentItemProps) => {
    if (subDepartment?.department?.departmentIdNo) {
      console.log({ subDepartment });
      navigate(
        `/designation-list/${subDepartment?.department?.departmentIdNo}/${isDepartNestedDepartmentName(
          subDepartment?.department?.name
        )}`,
        {
          state: {
            name: subDepartment?.department?.name ?? '',
            subDepartmentName: subDepartment?.name ?? '',
            subDepartmentId: subDepartment?.id,
            departmentId: subDepartment?.department.id,
            employeeCount: subDepartment?.employeeCount || 0,
            designationCount: subDepartment?.designationCount || 0
          }
        }
      );
    }
  };
  const onEditPress = (subDepartment: ISubDepartmentItemProps) => {
    openAdvanceFilter();
    setSelectedDepartment('subDepartmentId', subDepartment.id);
    setSelectedDepartment('subDepartmentName', subDepartment.name);
  };
  const [subDepartment, setSubDepartment] = useState<ISubDepartmentItemProps>();
  const [archiveError, setArchiveError] = useState<string>('');
  const onUpdateStatusPress = (subDepartment: ISubDepartmentItemProps) => {
    setSubDepartment(subDepartment);
    setModalShow(true);
  };
  const { mutate: updateSubDepartment } = useUpdateSubDepartment(departmentIdNo, () => {
    // Alert('success', 'Sub Department updated successfully.');
  });
  const handleArchive = async (subDepartment: ISubDepartmentItemProps) => {
    try {
      const loggedUser = JSON.parse(localStorage.getItem('admin_user') as string);

      if (
        loggedUser.subDepartment.name === subDepartment.name &&
        loggedUser.department.departmentIdNo === departmentIdNo
      ) {
        setArchiveError('Invalid archive operation by logged in user');
        return console.error('Invalid archive operation by logged in user');
      }
      setModalShow(false);
      subDepartment.isActive = !subDepartment.isActive;
      updateSubDepartment({
        isActive: subDepartment.isActive,
        subDepartmentId: subDepartment.id
      });
      Alert('success', `Sub Department has been ${!subDepartment.isActive ? 'archived' : 'unarchive'} successfully.`);
    } catch (error: any) {
      Alert('error', `${error.response.data.message ?? 'Something went wrong when archiving sub department'}`);
      throw error;
    }
  };

  return (
    <LoadingScreen isLoading={isSubDepartmentLoading}>
      <div></div>
      <div className="list-wrapper-designation">
        <div className="table-responsive  attendance-table">
          <table className="table">
            <thead className="thead body-small-semibold department-thead">
              <tr className="department-header">
                <th>SUB DEPARTMENT</th>
                <th>TOTAL Designation</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {subDepartmentListData?.items?.map((subDepartment: ISubDepartmentItemProps, index) => (
                <React.Fragment key={index}>
                  <tr
                    key={subDepartment.id}
                    onClick={() => {
                      if (subDepartment.id === selectedSubDepartment) {
                        setSelectedSubDepartment(null);
                      } else {
                        setSelectedSubDepartment(subDepartment.id);
                      }
                    }}
                  >
                    <td className="align-middle py-3">
                      <span
                        className="body-small-regular department-list-text"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onSubDepartmentNamePress(subDepartment);
                        }}
                      >
                        {subDepartment?.name?.toUpperCase()}
                      </span>
                    </td>
                    <td className="align-middle p-0">
                      <div className="d-flex align-middle justify-content-between ms-2 " style={{ gap: '20px' }}>
                        <div className="align-middle body-small-regular department-list-text m-auto ms-1">
                          {subDepartment?.designationCount || 0}
                        </div>

                        <div className="d-flex justify-content-around department-option">
                          {!subDepartment.isActive ? (
                            <Badge className="archive-badge body-small-regular mt-3">Archived</Badge>
                          ) : (
                            <div className="archive-badge" style={{ visibility: 'hidden' }}></div>
                          )}
                          <DropdownButton
                            drop="start"
                            id="dropdown-basic-button"
                            title={<Icons.OptionIcon height={20} onClick={() => handleOpen(subDepartment.id)} />}
                            className="options-icon-designation mb-4"
                          >
                            {selectedId === subDepartment.id && (
                              <>
                                <div className="edit-designation body-small-regular">
                                  <Dropdown.Item
                                    onClick={() => onEditPress(subDepartment)}
                                    className={!subDepartment.isActive ? 'disable-designation' : 'editDesignation'}
                                    disabled={!subDepartment.isActive}
                                  >
                                    {<Icons.EditIcon className="dropdown-icon" />}
                                    Edit Sub Department
                                  </Dropdown.Item>
                                </div>
                                <Dropdown.Item
                                  onClick={() => onUpdateStatusPress(subDepartment)}
                                  className="body-small-regular"
                                >
                                  {<Icons.ArchiveIcons className="dropdown-icon" />}
                                  {subDepartment.isActive === true
                                    ? 'Archive sub department'
                                    : 'Unarchive sub department'}
                                </Dropdown.Item>
                              </>
                            )}
                          </DropdownButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modalShow && (
        <SubDepartmentArchiveModal
          show={modalShow}
          customSize={'md'}
          onHide={() => setModalShow(false)}
          handleArchive={handleArchive}
          subDepartment={subDepartment}
          archiveError={archiveError}
          setArchiveError={setArchiveError}
        />
      )}
    </LoadingScreen>
  );
};

export default SubDepartmentTable;
