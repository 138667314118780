import { IcreateVendorBody, IcreateVendorResponse, IupdateVendorBody } from '@attendance-web-app/types/vendor/type';
import axios, { AxiosResponse } from 'axios';

export const getVendorListApi = async () => {
  try {
    const response = await axios.get(`/vendor/list`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSpecificVendorDetail = async (params?: { id: string }) => {
  try {
    const response = await axios.get(`/vendor`, {
      params
    });
    return response?.data?.data;
  } catch (error: any) {
    throw error;
  }
};

const createVendor = (body: IcreateVendorBody): Promise<AxiosResponse<IcreateVendorResponse, Error>> => {
  return axios.post('/vendor', body);
};

const editVendor = (id: string, body: IupdateVendorBody) => {
  return axios.patch(`/vendor/${id}`, body);
};

export const deactivateVendorApi = (payload: { id?: string }) => {
  const { id } = payload;
  return axios.patch(`/vendor/${id}/deactivate`);
};
export default {
  createVendor,
  getVendorListApi,
  getSpecificVendorDetail,
  editVendor,
  deactivateVendorApi
};
