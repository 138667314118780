import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import axios, { AxiosResponse } from 'axios';

interface IGetBankListResponse {
  error: boolean;
  data: {
    id: number;
    fullName: string;
    swiftCode: string;
    phoneNumber: string;
    type: string;
    location: string;
    emailAddress: string;
    profilePhoto: null | string;
    isActive: boolean;
    createdAt: Date;
  }[];
  message: string;
}
export const getBanksList = async (): Promise<AxiosResponse<IGetBankListResponse, Error>> => {
  try {
    const response = await axios.get('/bank/list');
    return response;
  } catch (error) {
    handleErrorMessage(error);
    throw error;
  }
};
