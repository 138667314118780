import { IcreatePaymentVendorBody, IcreatePaymentVendorResponse } from '@attendance-web-app/types/vendor/type';
import axios, { AxiosResponse } from 'axios';

const createPaymentVendor = (
  body: IcreatePaymentVendorBody
): Promise<AxiosResponse<IcreatePaymentVendorResponse, Error>> => {
  return axios.post('/payment', body);
};

export default {
  createPaymentVendor
};
