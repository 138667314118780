import React, { Dispatch, createContext, useContext, useReducer } from 'react';
import { SpendingListAction } from '../actions/spendingListAction.d';
import { spendingListInitialState, spendingListReducer } from '../reducers/spendingListReducer';
import { spendingListReducersProps } from '../reducers/spendingListReducer.d';

export interface SpendingListContext {
  spendingListState: spendingListReducersProps;
  spendingListDispatch: Dispatch<SpendingListAction>;
}

export const SpendingListStore = createContext<SpendingListContext>({
  spendingListState: spendingListInitialState,
  spendingListDispatch: () => {
    return;
  }
});

export function SpendingListProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(spendingListReducer, spendingListInitialState);

  return (
    <SpendingListStore.Provider
      value={{
        spendingListState: state,
        spendingListDispatch: dispatch
      }}
    >
      {children}
    </SpendingListStore.Provider>
  );
}
export const useSpendingListContext = () => {
  const context = useContext(SpendingListStore);
  if (context === undefined) {
    throw new Error('Context not found');
  }
  return context;
};
