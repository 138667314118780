import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { SetDate } from '@attendance-web-app/global-state/actions/filtersAction';
import { useFiltersContext } from '@attendance-web-app/global-state/context/FiltersContext';
import { months } from '@fuse-apps/shared-constants';
import { addDays, format, getYear, startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

function range(start: number, end: number, step = 1): number[] {
  const result = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
}

const currentYear = new Date().getFullYear();

export const Calendar = (): JSX.Element => {
  const { filtersState, filtersDispatch } = useFiltersContext();
  const [startDate, setStartDate] = useState(new Date(filtersState.date));
  const years = range(2013, currentYear);
  const [year, setYear] = useState(getYear(startDate));
  const [month, setMonth] = useState(months[startDate.getMonth()]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedWeekStart, setSelectedWeekStart] = useState(new Date());
  const [selectedWeekEnd, setSelectedWeekEnd] = useState(new Date());

  const handleCalendarOpen = () => {
    if (!isOpen) {
      setIsOpen(isOpen => !isOpen);
      document.querySelector('.react-datepicker__tab-loop')?.setAttribute('style', 'display:block');
    } else {
      setIsOpen(isOpen => !isOpen);
      document.querySelector('.react-datepicker__tab-loop')?.setAttribute('style', 'display:none');
    }
  };
  useEffect(() => {
    setMonth(months[startDate.getMonth()]);
    setYear(getYear(startDate));
    setIsOpen(!isOpen);
    filtersDispatch(SetDate(String(startDate)));
    selectWeek(startDate);
  }, [startDate]);

  const selectWeek = (date: Date) => {
    const startOfWeekDate = startOfWeek(date);
    const endOfWeekDate = addDays(startOfWeekDate, 6);
    setSelectedWeekStart(startOfWeekDate);
    setSelectedWeekEnd(endOfWeekDate);
  };

  const renderDayContents = (dayOfMonth: number, date: Date): JSX.Element => {
    let customClassName;
    if (
      (filtersState.timePeriod === 'week' || filtersState.timePeriod === '') &&
      date >= selectedWeekStart &&
      date <= selectedWeekEnd
    )
      customClassName = 'custom-classname';

    return (
      <div className={customClassName}>
        <span>{dayOfMonth}</span>
      </div>
    );
  };
  return (
    <div className="calendar-right body-regular">
      <DatePicker
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <div className="header">
            <select
              className="body-regular"
              value={year}
              onChange={({ target: { value } }) => {
                setYear(Number(value));
                changeYear(Number(value));
              }}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="body-regular"
              value={month}
              onChange={({ target: { value } }) => {
                setMonth(value);
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={startDate}
        onChange={date => {
          setStartDate(date ?? new Date());
        }}
        useWeekdaysShort
        customInput={
          <button className="calendar-input body-large-regular" onClickCapture={handleCalendarOpen}>
            {filtersState.timePeriod === '' ? format(startDate, 'dd MMM, yyyy') : format(startDate, 'dd MMM, yyyy')}
            {isOpen ? (
              <Icons.ArrowUp height={16} width={16} stroke="#595959" />
            ) : (
              <Icons.ArrowDown height={16} width={16} stroke="#595959" />
            )}
          </button>
        }
        onCalendarClose={() => setIsOpen(false)}
        renderDayContents={renderDayContents}
      />
    </div>
  );
};
