import { initReducersProps } from '../reducers/initReducer.d';
import { InitActionEnum, initAction, loginAction } from './initAction.d';

export const initSuccess = (payload: initReducersProps): initAction => ({
  type: InitActionEnum.SAVE_INIT_SUCCESS,
  payload
});

export const loginSuccess = (payload: initReducersProps): loginAction => ({
  type: InitActionEnum.SAVE_LOGIN_DATA_SUCCESS,
  payload
});
