import { getAccessToken, getUserDetails } from '@attendance-web-app/config/axios.config';
import { InitAction, InitActionEnum } from '../actions/initAction.d';
import { initReducersProps } from '../reducers/initReducer.d';
export const initInitialState: initReducersProps = {
  sidebarOpen: true,
  userDetails: getUserDetails() || null,
  accessToken: getAccessToken() || ''
};
export const initReducer = (state: initReducersProps, action: InitAction): initReducersProps => {
  switch (action.type) {
    case InitActionEnum.SAVE_INIT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case InitActionEnum.SAVE_LOGIN_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        accessToken: action.payload.accessToken
      };
    default:
      return state;
  }
};
