import Alert from '@attendance-web-app/components/Common/Alert';
import departmentServices from '@attendance-web-app/services/department.services';
import { DepartmentModal, IcreateDepartmentBody } from '@attendance-web-app/types/department/type';
import { useState } from 'react';

export const addDepartmentModalHook = ({
  onHide,
  setSuccessMsg,
  setUploadErrorMsg,
  setErrorData,
  fetchAttendanceData,
  setUpdatesMsg,
  setUpdatedData
}: DepartmentModal) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [activeTab, setActiveTab] = useState('addDepartment');
  const [departmentId, setDepartmentId] = useState('');
  const [name, setName] = useState('');
  const [departmenthodId, setDepartmenHodID] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddDepartment = async () => {
    try {
      if (activeTab === 'upload') {
        if (selectedFile) {
          try {
            setIsLoading(true);
            const response = await departmentServices.uploadDepartmentCsv(selectedFile);
            setErrorData(response.data?.errorMessages);
            setSuccessMsg(`Uploaded ${response.data?.totalDepartmentCreated} department successfully`);
            setUploadErrorMsg(`${response.data?.uploadFailed} data upload failed`);
            setUpdatesMsg(`${response.data.totalDepartmentUpdated} data updated`);
            setUpdatedData(response.data?.updatedDepartments);
            fetchAttendanceData();
            Alert('success', 'Department CSV has been uploaded successfully.');
            setIsLoading(false);
          } catch (error: any) {
            Alert('error', `${error.response.data.message}`);
            setIsLoading(false);
          } finally {
            setSelectedFile(null);
          }
        } else {
          console.log('No file selected');
        }
      } else if (activeTab === 'addDepartment') {
        const createDepartmentBody: IcreateDepartmentBody = {
          departmentIdNo: departmentId,
          name: name,
          isActive: true
        };
        if (departmenthodId) {
          createDepartmentBody.hodId = departmenthodId;
        }
        try {
          await departmentServices.createDepartment(createDepartmentBody);
          Alert('success', 'New Department has been added successfully.');
        } catch (error: any) {
          Alert('error', `${error.response.data.message}`);
        }
        fetchAttendanceData();
      }
      resetFormState();
      onHide();
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setActiveTab('addDepartment');
    } else if (index === 1) {
      setActiveTab('upload');
    }
  };

  const resetFormState = () => {
    setName('');
  };

  const handleHideModal = () => {
    resetFormState();
    setSelectedFile(null);
    onHide();
  };

  return {
    handleAddDepartment,
    handleHideModal,
    resetFormState,
    handleTabChange,
    setDepartmenHodID,
    setName,
    setDepartmentId,
    selectedFile,
    setSelectedFile,
    isLoading
  };
};
