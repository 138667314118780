import axios from 'axios';

export const getVendorPayout = async ({ id }: { id: string }) => {
  try {
    const response = await axios.get(`/payout-request/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
