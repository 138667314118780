export const initialSpendingViewCardData = {
  totalBalanceTopUp: '',
  percentChangeInBalanceTopUp: null,
  totalEmployee: '',
  percentChangeInEmployee: null
};
export const spendingViewTable = [
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  },
  {
    employee: {
      imageUrl: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector.png',
      name: 'Pranil Jyoti G Tuladhar',
      email: 'praniljyoti@fusemachines.com',
      position: 'Sr.  UX/UI Designer'
    },
    vendor: {
      _id: 1,
      name: 'Two Sisters'
    },
    transactionId: 123,
    time: '9:00 AM',
    amount: 100,
    remarks: 'Team Lunch'
  }
];
