import { useSaveLogsContext } from '@attendance-web-app/global-state/context/LogsContext';

import { useShowMobileHeader } from '@attendance-web-app/components/Common/hooks/useShowMobileHeader';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import { saveLogsSuccess } from '@attendance-web-app/global-state/actions/logsAction';
import { OptionType } from '@attendance-web-app/global-state/reducers/logsReducers.d';
import { getFormattedDateForParams } from '@attendance-web-app/libs/date';
import { selectedOptionType } from '@attendance-web-app/types';
import { ILogs } from '@attendance-web-app/types/logs/type';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { format } from 'date-fns';
import { useState } from 'react';
import { getLogsListApi } from './useLogsApiHook';
interface IHandlePaginateClick {
  selected: number;
}
export const useLogsScreenHook = () => {
  const userRole = JSON.parse(window.localStorage.getItem('admin_user') as string)?.role.role;

  const [logsActionsTypes, setLogsActionTypes] = useState([]);

  const { isLoading, isRefetching, refetchQuery } = useCustomListApi({
    queryKey: ['logsList'],
    getApiFn: getLogsListApi,
    enabled: false,
    params: {}
  });
  const { logsState, logsDispatch } = useSaveLogsContext();

  const { openAdvanceFilter, showAdvancedFilter, toggleAdvancedFilter, cancelAdvancedFilter } =
    useAdvanceFilterSidebar();
  const { openMobileHeader, handleMobileHeaderOpen, handleMobileHeaderClose } = useShowMobileHeader();

  const [selectedLogs, setSelectedLogs] = useState<ILogs[]>([]);
  const [selectedActionTypes, setSelectedActionTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  // when user choose from dropdown of logsType on Advanced Filter
  const onChangeSelectHandler = (type: string, options: any) => {
    switch (type) {
      case selectedOptionType.LOG_TYPE:
        setSelectedActionTypes(options);
        break;
      case selectedOptionType.DATE:
        setSelectedDate(options);
        break;
      default:
        break;
    }
  };

  // commonHandler for preparing payload, refetching with that payload and updating context with current action payload
  const logsCommonHandler = (params: any, actionPayload: any) => {
    const payload = {
      limit: logsState.limit ?? 10,
      offset: logsState.offset ?? 0,
      name: logsState?.logsSearch,
      ...params
    };
    refetchQuery(payload)
      .then(response => {
        const actionTypes = response?.uniqueActionTypes?.map((logsAction: string) => {
          return {
            label: logsAction,
            value: logsAction
          };
        });
        logsDispatch(
          saveLogsSuccess({
            logsSearch: logsState.logsSearch,
            data: response.logs,
            logsDetails: response
          })
        );
        setLogsActionTypes(actionTypes);
      })
      .catch(err => {
        handleErrorMessage(err);
      });
    logsDispatch(saveLogsSuccess(actionPayload));
  };

  // when user type input in search box
  const onChangeSearchHandler = (input: string) => {
    const payload = {
      timeStamp: getFormattedDateForParams(logsState.selectedDate),
      actionTypes:
        logsState?.logsSelectedOption?.value?.map((actionType: OptionType) => actionType?.value).join(',') ?? '',
      name: input,
      offset: 0
    };
    const actionPayload = {
      logsSearch: input,
      offset: 0
    };
    logsCommonHandler(payload, actionPayload);
  };

  // when user change the logsType from chip
  const onLogsTypeChangeHandlerFromChip = (type: string, options: any) => {
    switch (type) {
      case 'logType':
        setSelectedActionTypes(options);
        const finalSelectedDate = getFormattedDateForParams(logsState?.selectedDate);
        const payload = {
          name: logsState?.logsSearch,
          timeStamp: finalSelectedDate,
          actionTypes: options?.map((actionType: { value: string; label: string }) => actionType?.value).join(',') ?? ''
        };
        const actionPayload = {
          logsSelectedOption: {
            selected: 'Type',
            value: options
          },
          selectedDate: finalSelectedDate
        };
        logsCommonHandler(payload, actionPayload);
        break;
      default:
        break;
    }
  };

  // when user change the date
  const onDateChangeHandler = (_: string, date: Date) => {
    const timeStamp = format(date, 'yyyy-MM-dd');
    const payload = {
      timeStamp,
      actionTypes:
        logsState?.logsSelectedOption?.value?.map((actionType: OptionType) => actionType?.value).join(',') ?? ''
    };
    const actionPayload = {
      selectedDate: timeStamp
    };

    logsCommonHandler(payload, actionPayload);
  };

  // when user click the apply button on advanced filter
  const onApplyHandler = () => {
    const finalSelectedDate = new Date(selectedDate as any);
    const payload = {
      name: logsState?.logsSearch,
      timeStamp: format(finalSelectedDate, 'yyyy-MM-dd'),
      offset: 0,
      actionTypes:
        selectedActionTypes?.map((actionType: { value: string; label: string }) => actionType?.value).join(',') ?? ''
    };
    const actionPayload = {
      logsSelectedOption: {
        selected: 'Type',
        value: selectedActionTypes
      },
      selectedDate: format(finalSelectedDate, 'yyyy-MM-dd'),
      offset: 0
    };
    logsCommonHandler(payload, actionPayload);
  };

  // when user click the pagination item
  const handlePaginateClick = (event: IHandlePaginateClick) => {
    const limitValue = logsState?.limit ?? 10;
    const payload = {
      limit: limitValue,
      offset: event.selected * limitValue,
      name: logsState.logsSearch,
      timeStamp: getFormattedDateForParams(logsState.selectedDate),
      actionTypes:
        logsState?.logsSelectedOption?.value?.map((actionType: OptionType) => actionType?.value).join(',') ?? ''
    };
    const actionPayload = {
      limit: logsState.limit,
      offset: event.selected * limitValue
    };

    logsCommonHandler(payload, actionPayload);
  };

  // when user click the pagination item
  const handleLimitChange = (limit: number) => {
    const payload = {
      limit,
      offset: 0,
      name: logsState.logsSearch,
      timeStamp: getFormattedDateForParams(logsState.selectedDate),
      actionTypes:
        logsState?.logsSelectedOption?.value?.map((actionType: OptionType) => actionType?.value).join(',') ?? ''
    };
    const actionPayload = {
      limit,
      offset: 0
    };
    logsCommonHandler(payload, actionPayload);
  };

  // Reset Functions Handlers

  // when user click cross button in date chip
  const onDateResetHandler = () => {
    const payload = {
      timeStamp: '',
      actionTypes:
        logsState?.logsSelectedOption?.value?.map((actionType: OptionType) => actionType?.value).join(',') ?? ''
    };
    const actionPayload = {
      selectedDate: ''
    };
    logsCommonHandler(payload, actionPayload);
  };

  // when user click cross button in logsType chip
  const onLogsTypeResetHandler = () => {
    const timeStamp = getFormattedDateForParams(logsState?.selectedDate);
    const payload = {
      timeStamp,
      actionTypes: ''
    };
    const actionPayload = {
      logsSelectedOption: {
        selected: '',
        value: []
      }
    };
    logsCommonHandler(payload, actionPayload);
  };

  const onClearAllFilter = () => {
    const timeStamp = null;
    const payload = {
      timeStamp,
      actionTypes: '',
      logsSearch: '',
      limit: 10,
      offset: 0
    };
    const actionPayload = {
      logsSelectedOption: {
        selected: '',
        value: []
      },
      selectedDate: '',
      limit: 10,
      offset: 0
    };
    logsCommonHandler(payload, actionPayload);
  };
  return {
    userRole,
    logsActionsTypes,
    logsState,
    showAdvancedFilter,
    openAdvanceFilter,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    openMobileHeader,
    handleMobileHeaderOpen,
    handleMobileHeaderClose,
    selectedLogs,
    setSelectedLogs,
    onApplyHandler,
    onChangeSelectHandler,
    onChangeSearchHandler,
    logsCommonHandler,
    onDateChangeHandler,
    onDateResetHandler,
    onLogsTypeResetHandler,
    selectedActionTypes,
    onLogsTypeChangeHandlerFromChip,
    handlePaginateClick,
    handleLimitChange,
    onClearAllFilter,
    isLoading,
    isRefetching
  };
};
