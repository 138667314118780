import { changeSchedulerTimeApi, getSchedulerStatus } from '@attendance-web-app/services/schedulerModal.services';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Alert from '../Common/Alert';

export const useSchedulerModal = (onSchedulerClickHandler: () => void) => {
  const queryClient = useQueryClient();

  const { data: statusData, refetchQuery: refetchSchedulerStatus } = useCustomListApi({
    queryKey: ['schedulerStatus'],
    getApiFn: getSchedulerStatus,
    enabled: true,
    params: {}
  }) as any;

  const { mutate: changeSchedulerTime } = useMutation({
    mutationFn: changeSchedulerTimeApi,
    onSuccess: () => {
      onSchedulerClickHandler();
      queryClient.invalidateQueries({
        queryKey: ['schedulerStatus']
      });
      Alert('success', 'Scheduler settings was updated successfully.');
    },
    onError: (error: any) => {
      handleErrorMessage(error);
    }
  });

  return { statusData, changeSchedulerTime, refetchSchedulerStatus };
};
