import { ViewListAction, ViewListActionType } from '../actions/viewAction.d';
import { IViewListReducerState } from './viewReducer.d';
export const initialViewListReducerState: IViewListReducerState = {
  index: 0
};

export const viewActionReducer = (state: IViewListReducerState, action: ViewListAction): IViewListReducerState => {
  switch (action.type) {
    case ViewListActionType.setViewListAction:
      state = { ...state, index: action.payload };
      return state;
    default:
      return state;
  }
};
