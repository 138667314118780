import { formatFileSize } from '@attendance-web-app/utils';
import React, { useState } from 'react';
interface IUseCustomCompCSV {
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}
export const useCustomCompCsv = ({ setSelectedFile }: IUseCustomCompCSV) => {
  const [fileSize, setFileSize] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setSelectedFile(file);

    if (file) {
      setFileSize(formatFileSize(file.size));
      await uploadFile(file);
    } else {
      setFileSize('');
    }
  };

  const uploadFile = async (file: File) => {
    const totalSize = file.size;
    let uploadedSize = 0;
    let progress = 0;

    const interval = setInterval(() => {
      uploadedSize += Math.floor(totalSize / 5);
      progress = Math.min(Math.floor((uploadedSize / totalSize) * 100), 100);
      setUploadProgress(progress);

      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 150);
  };

  const handleResetCsv = () => {
    setSelectedFile(null);
    setUploadProgress(0);
  };

  return { handleResetCsv, handleFileInput, fileSize, uploadProgress };
};
