import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const updateSubDepartmentToServer = async (payload: {
  departmentId?: number;
  name?: string;
  isActive?: boolean;
  subDepartmentId?: number;
}): Promise<any> => {
  const { subDepartmentId, ...restPayload } = payload;
  try {
    const response = await axios.patch(`sub-department/${subDepartmentId}`, restPayload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const useUpdateSubDepartment = (departmentId: string, onSuccessHandler: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSubDepartmentToServer,
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: ['sub_department_list', departmentId]
          },
          (oldQueryData: any) => {
            const changedDataIndex = oldQueryData?.items.findIndex(
              (subDepartment: any) => subDepartment.id === data.id
            );
            if (changedDataIndex > -1) {
              oldQueryData.items[changedDataIndex] = data;
              return {
                ...oldQueryData
              };
            }
          }
        );

        onSuccessHandler();
      }
    }
  });
};
