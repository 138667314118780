import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { loginSuccess } from '@attendance-web-app/global-state/actions/initAction';
import { useInitContext } from '@attendance-web-app/global-state/context/InitContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { authenticate } from './Auth';
import { lock } from './Login';

const Callback = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [autherror, seterror] = useState('');
  const { sidebarDispatch } = useInitContext();

  useEffect(() => {
    lock.on('authenticated', async authResult => {
      if (window.AUTHENTICATED) return false;
      window.AUTHENTICATED = true;
      const result = await authenticate(authResult);
      if (result.ok === false) {
        navigate('/error');
      } else if (result.data !== null) {
        sidebarDispatch(
          loginSuccess({
            accessToken: authResult.accessToken,
            userDetails: result?.data ?? {}
          })
        );
        navigate('/dashboard');
      }
    });
    if (location.pathname === '/callback') {
      if (autherror) {
        navigate('/error');
      }
    }
    lock.hide();
  }, [autherror]);
  lock.on('authorization_error', () => {
    seterror('false');
  });

  return (
    <div className="loading-screen">
      <Icons.Loader height={50} width={50} />
    </div>
  );
};

export default Callback;
