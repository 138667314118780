import CommonDetails from '@attendance-web-app/components/Common/CommonDetails';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import DesignationListTable from '@attendance-web-app/components/_pages/_DesignationList/DesignationListTable';
import SubDepartmentDetail from '@attendance-web-app/components/_pages/_SubDepartmentSummary/SubDepartmentSummary';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import { IDepartment } from '@attendance-web-app/types/department/type';
import { IDesignation } from '@attendance-web-app/types/designation/type';
import { changeNestedDepartmentToActual } from '@attendance-web-app/utils';
import { IDesignationItemsProps } from '@attendance-web-app/utils/type';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { useAddDesignation } from './hooks/useAddDesignation';
import { useManageDesignationList } from './hooks/useManageDesignationList';
import { useUpdateDesignation } from './hooks/useUpdateDesignation';

export default function DesignationList() {
  const { departmentName, departmentIdNo } = useParams();
  const location = useLocation();
  const { subDepartmentName, departmentId, subDepartmentId, employeeCount, designationCount } = location?.state ?? {};
  const { designationListData, designationLoading, refetchDesignationList } = useManageDesignationList(
    departmentIdNo || ''
  );

  const { openAdvanceFilter, showAdvancedFilter, cancelAdvancedFilter } = useAdvanceFilterSidebar();
  const { mutate: addDesignation } = useAddDesignation(departmentIdNo, onResetHandler);
  const { mutate: updateDesignation } = useUpdateDesignation(departmentIdNo, onResetHandler);
  const { ...department } = location.state as IDepartment;

  const [selectedDesignation, setSelectedDesignation] = useState<IDesignation | IDesignationItemsProps>();

  const onSearchChange = (searchSubDepartmentByName: string) => {
    refetchDesignationList({
      designation: searchSubDepartmentByName,
      subDepartment: subDepartmentName
    });
  };
  const onPressAdd = () => {
    setValue('designation', '');
    setValue('grade', '');
    setSelectedDesignation(undefined);
    openAdvanceFilter();
  };
  const onPressAddSubDepartment = (data: any) => {
    const { designation, grade } = data;
    if (!departmentIdNo) {
      return;
    }
    const payload = {
      name: designation,
      department: departmentId,
      subDepartment: subDepartmentId,
      subDepartmentId: subDepartmentId,
      grade
    };

    if (selectedDesignation?.id) {
      updateDesignation({
        ...payload,
        designationId: selectedDesignation?.id,
        name: designation
      });
    } else {
      addDesignation(payload);
    }
  };
  function onResetHandler() {
    setValue('designation', '');
    setSelectedDesignation(undefined);
    cancelAdvancedFilter();
    //onSearchChange('');
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      designation: '',
      grade: ''
    }
  });

  return (
    <CommonDetails
      title="Designation lists"
      detailsComponent={
        <SubDepartmentDetail
          name={department?.name || ''}
          subDepartmentName={subDepartmentName}
          departmentId={departmentIdNo || ''}
          employeeCount={employeeCount || 0}
          designationCount={designationCount || 0}
        />
      }
      onSearchChange={onSearchChange}
      onPressAdd={onPressAdd}
      addButtonTitle="Add Designation"
    >
      <DesignationListTable
        isLoading={designationLoading}
        filteredDesignationData={[]}
        fetchDesignationData={() => onSearchChange('')}
        searchKeyword=""
        departmentIdNo={departmentIdNo || ''}
        departmentId={departmentId}
        designationList={designationListData}
        setValue={setValue}
        openAdvanceFilter={openAdvanceFilter}
        setSelectedDesignation={setSelectedDesignation}
      />
      {showAdvancedFilter && (
        <CustomModal
          showModal={showAdvancedFilter}
          modalTitle={selectedDesignation?.id ? 'Update Designation' : 'Add Designation'}
          handleHideModal={cancelAdvancedFilter}
          footerComponent={
            <>
              <button className="btn-tertiary" onClick={cancelAdvancedFilter}>
                Cancel
              </button>
              <button className="btn " form="add-new-sub-department">
                {selectedDesignation?.id ? 'Update Designation' : 'Add New Designation'}
              </button>
            </>
          }
        >
          <CustomMultiDropDownSelect
            isEditable={false}
            title="Select Department"
            options={[]}
            selectedOptions={[
              {
                label: changeNestedDepartmentToActual(departmentName) ?? '',
                value: departmentIdNo ?? ''
              }
            ]}
            onChangeSelectHandler={() => {}}
          />
          <div className="pt-4">
            <CustomMultiDropDownSelect
              isEditable={false}
              title="Select Sub Department"
              options={[]}
              selectedOptions={[
                {
                  label: subDepartmentName ?? '',
                  value: subDepartmentId ?? ''
                }
              ]}
              onChangeSelectHandler={() => {}}
            />
          </div>
          <form onSubmit={handleSubmit(onPressAddSubDepartment)} id="add-new-sub-department">
            <Form.Group className="col-12 form-group-name mt-4">
              <Form.Control
                type="text"
                placeholder="Enter Designation."
                {...register('designation', {
                  required: true,
                  validate: value => value.length > 2 || 'Invalid designation name.',
                  pattern: {
                    value: /^[a-zA-Z\s&.\-]{4,}$/,
                    message: 'Designation: Invalid format'
                  }
                })}
                isInvalid={!!errors.designation}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-name   ${errors.designation ? 'text-danger' : ''}`}
              >
                Designation<span className="asteriskVendor">*</span>
              </label>
              {errors.designation && <ErrorLabel message={errors.designation.message || 'Required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-name mt-4">
              <Form.Control
                type="text"
                placeholder="Enter Grade."
                {...register('grade', {
                  required: true,
                  validate: value => value.length > 2 || 'Invalid Grade.',
                  pattern: {
                    value: /^[a-zA-Z0-9\s&._\-]{2,}$/,
                    message: 'Grade: Invalid format'
                  }
                })}
                isInvalid={!!errors.designation}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-name   ${errors.designation ? 'text-danger' : ''}`}
              >
                Grade<span className="asteriskVendor">*</span>
              </label>
              {errors.grade && <ErrorLabel message={errors.grade.message || 'Required'} />}
            </Form.Group>
          </form>
        </CustomModal>
      )}
    </CommonDetails>
  );
}
