import { IPayoutList, IgetpayoutListArgs } from '@attendance-web-app/types/payout/type';
import axios, { AxiosResponse } from 'axios';

export const getpayoutListApi = async (params?: IgetpayoutListArgs): Promise<AxiosResponse<IPayoutList, Error>> => {
  try {
    const response = await axios.get('/payout-request', {
      params
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getpayoutListApi
};
