import { useState } from 'react';

export const useViewEmployeeListHeaderHook = (setSearchKeyword: any) => {
  const [createdMsg, setCreatedMsg] = useState('');
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [uploadErrorData, setUploadErrorData] = useState<object[]>([]);
  const [uploadedData, setUploadedData] = useState<object[]>([]);
  const [uploadUpdatedMsg, setUploadUpdatedMsg] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleClearInput = () => {
    setSearchKeyword('');
  };

  return {
    handleOpenModal,
    handleCloseModal,
    onSearchChange,
    handleClearInput,
    setCreatedMsg,
    setUploadErrorMsg,
    setUploadUpdatedMsg,
    setUploadErrorData,
    setUploadedData,
    createdMsg,
    uploadErrorMsg,
    showModal,
    uploadErrorData,
    uploadedData,
    uploadUpdatedMsg
  };
};
