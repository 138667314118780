import { EmployeeRole } from '@attendance-web-app/types/employee/type';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export default function AdminRoute({ children }: Props): JSX.Element {
  const userRole = JSON.parse(window.localStorage.getItem('admin_user') as string)?.role.role;
  const location = useLocation();

  if (!userRole) {
    return <Navigate to="/login" />;
  } else {
    if (userRole !== EmployeeRole.INDIVIDUAL) {
      return children as JSX.Element;
    } else {
      return <Navigate to="/error403" state={{ from: location }} />;
    }
  }
}
