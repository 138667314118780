import { ModalProps } from 'react-bootstrap';
import { IDepartment } from '../department/type';

interface IAttendanceRecord {
  isChecked: boolean;
  id: number;
  employeeId: number;
  profilePhoto: string;
  date: string;
  checkInTime: string;
  checkOutTime: string;
  checkInRemarks: string | null;
  checkOutRemarks: string | null;
}
export interface IAttendance {
  id: number;
  isChecked: boolean;
  employeeId: number;
  date: string;
  employee: {
    id: number;
    profilePhoto: string;
    name: string;
    mobile: string;
    email: string;
    position: string;
    department: string;
  };
  checkInTime: string;
  checkInConverted: string | null;
  checkOutConverted: string | null;
  checkOutTime: string;
  checkInRemarks: null;
  checkOutRemarks: null;
}

export interface IEmployeeAttendanceState {
  attendance: IAttendance[];
  totalCount: number;
  totalPages: number | null;
}

export interface IEmployeeDetails {
  id: number;
  name: string;
  email: string;
  mobile: string;
  department: string;
  employeeIdNo: string | null;
  position: string;
  profilePhoto: string;
  designation: {
    id: number;
    name: string;
  };
  supervisor: {
    id: number;
    name: string;
    email: string;
    mobile: string;
    employeeIdNo: string | null;
    position: string;
    profilePhoto: string;
    department: {
      name: string;
    };
    designation: {
      name: string;
    };
  };
  HOD: {
    id: number;
    name: string;
    email: string;
    mobile: string;
    employeeIdNo: string | null;
    position: string;
    profilePhoto: string;
    department: {
      name: string;
    };
    designation: {
      name: string;
    };
  };
  attendances: IAttendanceRecord[];
}

export interface IEmployeeRole {
  id: number;
  authRoleId: string;
  role: string;
  status: boolean;
}

export interface IEmployee {
  id: number;
  name: string;
  email: string;
  mobile: number | null;
  position: string | null;
  employeeIdNo: string | null;
  officialIdNo: string | null;
  department: IDepartment;
  designation: {
    id: string;
    name: string;
    grade: string;
  };
  roleId: number;
  status: boolean;
  profilePhoto: string;
  role: IEmployeeRole;
  supervisorId: number;
  grade: string;
  supervisor: IEmployee;
  shift: 'day' | 'evening';
  subDepartment?: {
    name: string;
    id: number;
    departmentId: number;
    isActive: boolean;
  };
}
export interface IEmployeeList {
  employee: IEmployee[];
  totalCount: number;
  totalPages: number;
}

export interface IDepartmentList {
  department: IDepartment[];
  totalCount: number;
  totalPages: number;
}

export interface IHod {
  id: number;
  employee: IEmployee;
  department: IDepartment;
  countSubordinates: string;
}
export interface IHodList {
  data: IHod[];
  totalCount: number;
  totalPages: number;
}

export interface DropdownOption {
  value: string;
  label: string;
}

export interface IEmployeeDetailsParams {
  date: string;
  period?: string;
  limit?: number;
}

export enum EmployeeRole {
  INDIVIDUAL = 'individual',
  SUPERADMIN = 'superadmin'
}

export interface IcreateEmployeeResponse {
  department: number;
  designation: number;
  email: string;
  employeeIdNo: string;
  grade: string;
  id: number;
  mobile: number;
  name: null;
  profilePhoto: null;
  role: number;
  status: boolean;
  supervisorId: number;
}

export interface IupdateEmployeeResponse {
  message: string;
}

export interface IuploadEmployeeCsvResponse {
  createdEmployees: IEmployee[];
  errorMessages: ErrorMessage[];
  totalEmployeeCreated: number;
  totalEmployeeUpdated: number;
  updatedEmployees: IEmployee[];
  uploadFailed: number;
}

export type fieldErros = Partial<IEmployee>;
interface ErrorMessage extends IEmployee {
  errors: fieldErros;
}
export interface IemployeeListArgs {
  name?: string;
  department?: string;
  limit?: number;
  offset?: number;
}

export interface IcreateEmployeeBody {
  email: string;
  mobile: number | null;
  //designation: number;
  department: number | null;
  role: number;
  employeeIdNo: string;
  status: boolean;
  supervisorId: number | null;
  employeeId: number;
  // grade: string;
}

export interface IupdateEmployeeBody {
  name: string;
  email: string;
  mobile: number;
  designation: number;
  department: number;
  role: number;
  employeeIdNo: string;
  employeeId: string;
  grade: string;
  supervisorId: number;
}

export interface IdiasbleEmployeeBody {
  name: string;
  email: string;
  mobile: string;
  designation: number;
  department: number;
  role: number;
  employeeIdNo: string;
  employeeId: number;
  grade: string;
  supervisorId: number;
  status: boolean;
}

export interface IemployeeSupervisorDropdownOptions {
  label: string;
  id: number;
  image: string;
}

export interface IDisableEmployeeModalProps extends Omit<ModalProps, 'size'> {
  fetchAttendanceData: () => void;
  show: boolean;
  onHide: () => void;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  employeeListState: IEmployeeList | undefined;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customSize: 'sm' | 'lg' | 'xl' | 'md';
}
