import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { TError, TStatus } from '../../types/error/type';
import { lock } from '../_pages/_Login/Login';

const ERRORS: TError = {
  401: {
    errorHead: 'Oops, No Authorization Found',
    errorText:
      "Your account doesn't have access to this page due to invalid credentials. Use Fusemachines email to login.",
    Icon: Icons[401]
  },
  403: {
    errorHead: "Oops, You're not Authorized",
    errorText: "Your account doesn't have access to this page due to invalid credentials.",
    Icon: Icons[403]
  }
};
const ErrorPage = ({ status }: { status: TStatus }) => {
  const { errorHead, errorText, Icon } = ERRORS[status];

  const backPressHandler = () => {
    lock.logout({ returnTo: `${process.env.VITE_LOGIN_CALLBACK_URL}` });
  };
  return (
    <>
      <Icons.Circle height={388} width={177} className="left-circle" />
      <Icons.SmallCircle height={31} width={31} className="small-circle" />
      <Icons.PaleCircle height={64} width={64} className="pale-circle" />
      <Icons.BlueCircle height={61} width={61} className="blue-circle" />
      <Icons.BlueCircle height={61} width={61} className="blue-circle-right" />
      <Icons.Path height={300} width={960} className="path" />
      <div className="error-container">
        <div className="fourOone-icon">
          <div className="fourOone">{<Icon />}</div>
        </div>

        <div className="desc">
          <div className="desc-1">{errorHead}</div>
          <div className="desc-2">{errorText}</div>
          <button className="back2home" style={{ cursor: 'pointer' }} onClick={backPressHandler}>
            Back to Login
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
