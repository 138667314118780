import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import React, { useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';

interface UploadCsvProps {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const UploadCsvTab = ({ selectedFile, setSelectedFile }: UploadCsvProps) => {
  const [fileSize, setFileSize] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setSelectedFile(file);

    if (file) {
      setFileSize(formatFileSize(file.size));
      await uploadFile(file);
    } else {
      setFileSize('');
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const uploadFile = async (file: File) => {
    const totalSize = file.size;
    let uploadedSize = 0;
    let progress = 0;

    const interval = setInterval(() => {
      uploadedSize += Math.floor(totalSize / 5);
      progress = Math.min(Math.floor((uploadedSize / totalSize) * 100), 100);
      setUploadProgress(progress);

      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 150);
  };

  const handleResetCsv = () => {
    setSelectedFile(null);
    setUploadProgress(0);
  };

  return (
    <div className="mt-4">
      <Form>
        {/* <div className="form-group mb-3"> */}
        <label htmlFor="csv-file-input" className={`upload-csv ${selectedFile ? 'disabled' : ''}`}>
          {selectedFile ? selectedFile.name : 'Upload .CSV file here'}
          <Form.Control
            type="file"
            id="csv-file-input"
            onChange={handleFileInputChange}
            accept="text/csv"
            disabled={selectedFile !== null}
          />
        </label>
        {!selectedFile && (
          <p className="body-large-regular" style={{ marginBottom: '12rem' }}>
            No file uploaded. &nbsp;
            <button
              className="btn-inline"
              onClick={() =>
                window.open(
                  'https://docs.google.com/spreadsheets/d/1FMdDCJ4VoPzAKFtWP6AnWr5NtM8nHnx82PMnMXS3wNc/edit?gid=0#gid=0',
                  '_blank'
                )
              }
            >
              <Icons.GoogleLinkIcon height={16} width={17} stroke="#2074B9" /> Google sheet
            </button>
          </p>
        )}
        {/* </div> */}
      </Form>
      {selectedFile && (
        <div className="upload-container">
          <div className="upload-text">
            <div>
              <p className="body-regular-semibold">{selectedFile.name}</p>
              <p className="body-regular-semibold" style={{ color: '#868686' }}>
                <span>{fileSize}</span>
              </p>
            </div>

            <div>
              <p onClick={handleResetCsv} className="ps-2">
                <Icons.CrossIcon height={20} width={20} fill="#595959" />
              </p>
              <p>{uploadProgress}%</p>
            </div>
          </div>
          <ProgressBar now={uploadProgress} />
        </div>
      )}
    </div>
  );
};

export default UploadCsvTab;
