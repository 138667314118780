import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import CustomSearchBar from '@attendance-web-app/components/_pages/_header/LogSearchBar';

interface CommonDetailsProps {
  detailsComponent?: React.ReactNode;
  children?: React.ReactNode;
  onSearchChange?: (e: any) => void;
  onPressAdd?: () => void;
  addButtonTitle?: string;
  title?: string;
  headerComponent?: React.ReactNode;
}
export default function CommonDetails({
  detailsComponent,
  children,
  onSearchChange,
  onPressAdd,
  addButtonTitle,
  title = '',
  headerComponent
}: CommonDetailsProps) {
  return (
    <div className="mx-2">
      <div className="row flex-wrap mt-4">
        {detailsComponent && <div className="col-sm-12  col-md-4 col-xl-3 col-lg-3 col-xxl-2">{detailsComponent}</div>}
        <div className={`col-sm-12 col-md-8 col-xl-9 col-lg-9  col-xxl-10 p-0`}>
          {!headerComponent && (
            <div className="d-flex flex-wrap justify-content-between mb-4">
              <CustomSearchBar onChangeSearchHandler={onSearchChange} hasIcon={false} />
              <button className="btn invite-button" onClick={onPressAdd}>
                <Icons.PlusIcon height={16} width={16} />
                <span className="body-regular invite-text"> {addButtonTitle} </span>
              </button>
            </div>
          )}
          {headerComponent}
          <div className="mb-2">
            <span className="h4-semibold">{title}</span>
          </div>
          {children && children}
        </div>
      </div>
    </div>
  );
}
