import {
  IEmployeeList,
  IcreateEmployeeBody,
  IcreateEmployeeResponse,
  IdiasbleEmployeeBody,
  IemployeeListArgs,
  IupdateEmployeeBody,
  IupdateEmployeeResponse,
  IuploadEmployeeCsvResponse
} from '@attendance-web-app/types/employee/type';
import axios, { AxiosResponse } from 'axios';

const employeeList = (params: IemployeeListArgs): Promise<AxiosResponse<IEmployeeList, Error>> => {
  return axios.get('/employee', {
    params: {
      ...params
    }
  });
};

const supervisorList = (params: IemployeeListArgs): Promise<AxiosResponse<IEmployeeList, Error>> => {
  return axios.get('/employee/supervisors', {
    params: {
      ...params
    }
  });
};

const createEmployee = async (body: IcreateEmployeeBody): Promise<AxiosResponse<IcreateEmployeeResponse, Error>> => {
  return axios.post(`/employee`, body);
};

const updateEmployee = (
  id: string,
  body: IupdateEmployeeBody
): Promise<AxiosResponse<IupdateEmployeeResponse, Error>> => {
  return axios.patch(`/employee/${id}`, body);
};

const diasbleEmployee = (
  id: string,
  body: IdiasbleEmployeeBody
): Promise<AxiosResponse<IupdateEmployeeResponse, Error>> => {
  return axios.patch(`/employee/${id}`, body);
};

const uploadEmployeeCsv = async (formData: FormData): Promise<AxiosResponse<IuploadEmployeeCsvResponse, Error>> => {
  return axios.post('/employee/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default {
  supervisorList,
  employeeList,
  createEmployee,
  updateEmployee,
  diasbleEmployee,
  uploadEmployeeCsv
};
