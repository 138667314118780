import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import CustomCalendar from '@attendance-web-app/components/Common/CustomCalender';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { CSVLink } from 'react-csv';
import { PERIODICITY_OPTIONS } from '../_MobileView/MobileHeader';
import { DropdownMenu } from '../_header/DropdownMenu';
import CustomSearchBar from './LogSearchBar';
import { useHeaderHook } from './hooks/useHeaderHook';

interface attendanceDataToDownloadProps {
  EmployeeID: string;
  Name: string;
  Email: string;
  Department: string;
  Date: string;
  CheckInTime: string;
  CheckOutTime: string;
  Supervisor: string;
  Hod: string;
}

interface IAttendanceHeader {
  toggleAdvancedFilter: () => void;
  onChangeSearchHandler: (name: string) => void;
  onChangeSelectHandler: (type: string, options: any) => void;
  handleDownloadCsv: () => void;
  attendanceDataToDownload: attendanceDataToDownloadProps[] | undefined;
}
const Header = ({
  toggleAdvancedFilter,
  onChangeSearchHandler,
  onChangeSelectHandler,
  handleDownloadCsv,
  attendanceDataToDownload
}: IAttendanceHeader) => {
  const { attendanceState } = useAttendanceContext();

  const { getTimePeriod } = useHeaderHook([]);

  return (
    <div className="header-container">
      <div className="header-subContainer">
        <div>
          <h3 className="h4-semibold dashboard-head">Attendance list</h3>
        </div>
        <div className="d-flex gap-1 flex-wrap justify-content-end">
          <div className="search mr-4">
            <CustomSearchBar
              toggleAdvancedFilter={toggleAdvancedFilter}
              onChangeSearchHandler={onChangeSearchHandler}
            />
          </div>
          <div className="dropdowns ">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <div>
                <CustomCalendar
                  onSelectedDate={onChangeSelectHandler}
                  showLabel={false}
                  period={attendanceState?.paramsValue?.period}
                  defaultDate={attendanceState?.paramsValue?.date}
                />
              </div>
              <div className="dropdown single-select-dropdown">
                <DropdownMenu
                  options={PERIODICITY_OPTIONS}
                  onSelection={onChangeSelectHandler}
                  getTimePeriod={getTimePeriod}
                />
              </div>
              {attendanceState?.attendance?.length !== 0 ? (
                attendanceDataToDownload?.length !== 0 ? (
                  <CSVLink data={attendanceDataToDownload ?? []} filename={'AtendanceList.csv'}>
                    <button className="btn download-button">
                      <div className="d-flex flex-row " style={{ alignItems: 'center' }}>
                        <span className="me-2"> Export </span>
                        <Icons.Downloading height={16} width={16} />
                      </div>
                    </button>
                  </CSVLink>
                ) : (
                  <button className="btn download-button" onClick={handleDownloadCsv}>
                    <div className="d-flex flex-row " style={{ alignItems: 'center' }}>
                      <span className="me-2"> Export </span>
                      <Icons.Downloading height={16} width={16} />
                    </div>
                  </button>
                )
              ) : (
                <button className="btn" disabled onClick={handleDownloadCsv}>
                  <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span className="me-2 "> Export </span>
                    <Icons.DownloadingDisabled height={16} width={16} />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
