import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { subDepartmentListProps } from '@attendance-web-app/utils/type';
import { UseMutateFunction } from '@tanstack/react-query';
import { useAddSubDepartment } from './useAddSubDepartment';
import { getSubDepartmentList } from './useGetSubDepartmentList';
import { useUpdateSubDepartment } from './useUpdateSubDepartment';

interface useManageSubDepartmentList {
    subDepartmentListData: subDepartmentListProps;
    refetchSubDepartmentList: (refetchParams: {
        name?: string;
        departmentId?: string;
        limit?: number;
        page?: number;
    }) => Promise<any>;
    subDepartmentLoading: boolean;
    addSubDepartment: UseMutateFunction<
        any,
        Error,
        {
            departmentId: number;
            name: string;
            isActive: boolean;
        },
        unknown
    >;
    updateSubDepartment: UseMutateFunction<
        any,
        Error,
        {
            departmentId?: number;
            name?: string;
            isActive?: boolean;
            subDepartmentId?: number;
        },
        unknown
    >;
}
export const useManageSubDepartmentList = (departmentId: string): useManageSubDepartmentList => {
    const prepareParams = {
        departmentId
    };
    const {
        data: subDepartmentListData,
        isLoading: subDepartmentLoading,
        refetchQuery: refetchSubDepartmentList
    } = useCustomListApi({
        queryKey: ['sub_department_list', departmentId],
        getApiFn: getSubDepartmentList,
        enabled: false,
        params: prepareParams
    }) as any;

    const { mutate: addSubDepartment } = useAddSubDepartment(departmentId, () => {});
    const { mutate: updateSubDepartment } = useUpdateSubDepartment(departmentId, () => {});
    return {
        subDepartmentListData,
        refetchSubDepartmentList,
        subDepartmentLoading,
        addSubDepartment,
        updateSubDepartment
    };
};
