import { ISubDepartment } from '@attendance-web-app/types/department/type';
import { IEmployee } from '@attendance-web-app/types/employee/type';

export interface IParams {
  name?: string;
  limit?: number | string;
  offset?: number;
  department?: string;
  designation?: string;
  isDepartmentKeyId?: boolean;
  hod?: string;
  year?: number;
  id?: string;
}
export let initialParams: IParams = {
  name: '',
  limit: 100,
  offset: 0
};

export interface ICustomListApiHookProps {
  queryKey: string[];
  getApiFn: (params: any) => any;
  enabled: boolean;
  params?: any;
  options?: any;
}
export interface ICustomPostApiHookProps {
  queryKey: string[];
  postApiFn: (params: any) => any;
  enabled: boolean;
  payload?: any;
  options?: any;
}
export interface subDepartmentListProps {
  currentPage: number;
  itemCount: number;
  items: ISubDepartmentItemProps[];
}
interface Department {
  id: number;
  uuid: string;
  createdAt: string;
  lastUpdatedAt: string;
  name: string;
  departmentIdNo: string;
  isActive: boolean;
}

export interface ISubDepartmentItemProps {
  createdAt: string;
  department: Department;
  departmentId: number;
  id: number;
  isActive: boolean;
  lastedUpdatedAt: string;
  name: string;
  uuid: string;
  designationCount: number;
  employeeCount: number;
}
export interface IDesignationItemsProps {
  id: number;
  uuid: string;
  createdAt: string;
  lastUpdatedAt: string;
  name: string;
  grade: string;
  isArchived: boolean;
  isActive: boolean;
  employees: IEmployee[];
  department: Department;
  subDepartment?: ISubDepartment;
  employeeCount: number;
}
