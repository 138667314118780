export const BASEAPI = 'http://localhost:3333/api';
export const SCHEDULER_API = 'https://10.0.2.16:3001/api/v1/attendance-scheduler';

export const getAccessToken = () => {
  const token = sessionStorage.getItem('access_token');
  return token;
};
export const getUserDetails = () => {
  const token = localStorage.getItem('admin_user');
  return token;
};
export const HeaderData = async () => {
  const token = sessionStorage.getItem('access_token');
  if (token == null) {
    return {
      'Content-Type': 'application/json'
    };
  } else {
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };
  }
};
