import axios from 'axios';

export const getSpecificVendorTransactionListApi = async (params?: any) => {
  try {
    const response = await axios.get(`/vendor/${params.vendorId}/details`, {
      params
    });
    return response.data.data;
  } catch (error: any) {
    throw error;
  }
};
