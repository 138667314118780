import { IFiltersReducerState } from '../reducers/filtersReducer';

export enum FiltersActionType {
  setHeaderDate = 'setHeaderDate',
  setHeaderDepartment = 'setHeaderDepartment',
  setHeaderSearch = 'setHeaderSearch',
  setTimePeriod = 'setTimePeriod',
  setLimit = 'setLimit',
  setOffset = 'setOffset',
  setEmployeeAttendance = 'setEmployeeAttendance',
  setApplyFilter = 'setApplyFilter'
}

export type SetDateAction = {
  type: FiltersActionType.setHeaderDate;
  payload: IFiltersReducerState;
};

export type SetDepartmentAction = {
  type: FiltersActionType.setHeaderDepartment;
  payload: IFiltersReducerState;
};

export type SetSearchAction = {
  type: FiltersActionType.setHeaderSearch;
  payload: IFiltersReducerState;
};

export type SetTimePeriodAction = {
  type: FiltersActionType.setTimePeriod;
  payload: IFiltersReducerState;
};
export type setLimitAction = {
  type: FiltersActionType.setLimit;
  payload: IFiltersReducerState;
};
export type setOffsetAction = {
  type: FiltersActionType.setOffset;
  payload: IFiltersReducerState;
};

export type setApplyFilter = {
  type: FiltersActionType.setApplyFilter;
  payload: IFiltersReducerState;
};

export type FiltersAction =
  | SetDateAction
  | SetDepartmentAction
  | SetSearchAction
  | SetTimePeriodAction
  | SetLimitAction
  | SetOffsetAction
  | setApplyFilter;
