import Alert from '@attendance-web-app/components/Common/Alert';
import { ILogs } from '@attendance-web-app/types/logs/type';
import { Icon } from '@iconify/react';
import React from 'react';
import { downloadCsv, generateLogsCsvContent } from './hooks/csvUtils';

interface DownloadLogsCsvProps {
  selectedLogs: ILogs[];
  disabled: boolean;
}

const DownloadLogsCsv: React.FC<DownloadLogsCsvProps> = ({ selectedLogs, disabled }) => {
  const handleDownloadCsv = () => {
    const csvContent = generateLogsCsvContent(selectedLogs);

    try {
      downloadCsv(csvContent, 'Logs.csv');
      Alert('success', 'Logs has been downloaded successfully.');
    } catch (error: any) {
      Alert('error', error?.message);
    }
  };

  return (
    <div>
      {/* Button to trigger CSV download */}
      <button className="btn download-button body-regular" onClick={handleDownloadCsv} disabled={disabled}>
        <span>Export</span>
        <span className="csv-button--icon">
          <Icon icon="material-symbols:download" width={'20px'} height="20px" />
        </span>
      </button>
    </div>
  );
};

export default DownloadLogsCsv;
