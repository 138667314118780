import { IEmployeeAttendance } from '../reducers/attendanceReducer.d';

// export const enum AttendanceActionType {
export enum AttendanceActionType {
  Add = 'Add',
  SAVE_PARAMS = 'SAVE_PARAMS',
  RESET_CONTEXT = 'RESET_CONTEXT'
}

export type AddAction = {
  type: AttendanceActionType.Add;
  payload: IEmployeeAttendance;
};
export type SAVE_PARAMS_ACTION = {
  type: AttendanceActionType.SAVE_PARAMS;
  payload: IEmployeeAttendance;
};
export type RESET_CONTEXT = {
  type: AttendanceActionType.RESET_CONTEXT;
};

export type AttendanceAction = AddAction | SAVE_PARAMS_ACTION | RESET_CONTEXT;
