import { HeaderData } from '@attendance-web-app/config/axios.config';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from 'axios';
import { initialSpendingViewCardData } from '../data';

export const getSpendingViewCardApi = async (params: { date: string }) => {
  try {
    const response = await axios.get(`/balance/balance-and-employee-summary`, {
      headers: await HeaderData(),
      params
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const useSpendingViewCardApi = (params: { date: string }) => {
  const queryClient = useQueryClient();
  const { ...query } = useQuery({
    queryKey: ['spendingViewCard'],
    queryFn: () => getSpendingViewCardApi(params),
    initialData: () => initialSpendingViewCardData,
    refetchOnWindowFocus: false
  });
  const refetchQuery = async (refetchParams: { date: string }) => {
    await queryClient.fetchQuery({
      queryKey: ['spendingViewCard'],
      queryFn: () => getSpendingViewCardApi(refetchParams)
    });
  };
  return {
    ...query,
    refetchQuery
  };
};
export default useSpendingViewCardApi;
