import CustomDropDownSelect from '@attendance-web-app/components/Common/CustomDropDownSelect';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import CustomMultiDropDownSelect from '@attendance-web-app/components/Common/CustomMultiDropDownSelect';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { Option } from '@attendance-web-app/global-state/reducers/attendanceReducer.d';
import { selectedOptionType } from '@attendance-web-app/types';
import { dropOptionsArr } from '@attendance-web-app/utils';

import React from 'react';

interface AdvanceAttendanceFilterSidebarProps {
  // event listeners
  onApplyHandler: () => void;
  onChangeSelectHandler: (type: string, options: any) => void;
  cancelAdvancedFilter: () => void;
  openAdvanceFilter?: () => void;

  // showing list on dropdown
  departmentTypes?: Option[];
  designationTypes?: Option[];
  hodTypes?: Option[];
  supervisorsTypes?: Option[];

  // selected options
  selectedDepartmentTypes?: Option[];
  selectedDesignationTypes?: Option[];
  selectedHodTypes?: Option[];
  selectedSupervisorsTypes?: Option[];
  showAdvancedFilter: boolean;
  selectedDropOffServices: Option;
}

const AdvanceAttendanceFilterSidebar: React.FC<AdvanceAttendanceFilterSidebarProps> = ({
  onApplyHandler,
  onChangeSelectHandler,
  selectedDepartmentTypes,
  selectedDesignationTypes,
  selectedHodTypes,
  selectedSupervisorsTypes,
  hodTypes,
  cancelAdvancedFilter,
  openAdvanceFilter,
  showAdvancedFilter,
  selectedDropOffServices
}) => {
  const hideSidebar = () => {
    cancelAdvancedFilter();
  };
  const onApplyMainHandler = () => {
    cancelAdvancedFilter();
    onApplyHandler();
  };
  const { attendanceState } = useAttendanceContext();

  return (
    <CustomModal
      showModal={showAdvancedFilter}
      handleMenuOpen={openAdvanceFilter}
      handleHideModal={hideSidebar}
      modalTitle="Advance Filter"
      footerComponent={
        <>
          <button className="btn-tertiary" onClick={hideSidebar}>
            Cancel
          </button>
          <button className="btn " onClick={onApplyMainHandler}>
            Apply Filter
          </button>
        </>
      }
    >
      <div className="advance-filter-content attendanceAdvanceFilter">
        <div className="search-content w-100">
          <CustomMultiDropDownSelect
            title="Select Department"
            options={attendanceState?.departmentTypes}
            selectedOptions={selectedDepartmentTypes}
            onChangeSelectHandler={onChangeSelectHandler}
          />
          <CustomMultiDropDownSelect
            title="Select Designation"
            options={attendanceState?.designationTypes}
            selectedOptions={selectedDesignationTypes}
            onChangeSelectHandler={onChangeSelectHandler}
            isEditable={attendanceState?.designationTypes?.length ? true : false}
          />
          <div className="flex-option">
            <CustomMultiDropDownSelect
              title="Select HOD"
              options={hodTypes}
              selectedOptions={selectedHodTypes}
              onChangeSelectHandler={onChangeSelectHandler}
            />
            <CustomMultiDropDownSelect
              title="Select Supervisor"
              options={attendanceState?.superVisorTypes ?? []}
              selectedOptions={selectedSupervisorsTypes}
              onChangeSelectHandler={onChangeSelectHandler}
              isEditable={attendanceState?.superVisorTypes?.length ? true : false}
            />
          </div>

          <CustomDropDownSelect
            title="Select Drop Off Option"
            options={dropOptionsArr ?? []}
            selectedOptions={selectedDropOffServices}
            onChangeSelectHandler={options => onChangeSelectHandler(selectedOptionType.DROP_OFF_SERVICE, options)}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AdvanceAttendanceFilterSidebar;
