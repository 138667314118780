import { IEmployeeAttendance } from '../reducers/attendanceReducer.d';
import { AddAction, AttendanceActionType, RESET_CONTEXT, SAVE_PARAMS_ACTION } from './attendanceAction.d';

export const Add = (payload: IEmployeeAttendance): AddAction => ({
  type: AttendanceActionType.Add,
  payload
});

export const saveAttendanceParamsOnly = (payload: IEmployeeAttendance): SAVE_PARAMS_ACTION => ({
  type: AttendanceActionType.SAVE_PARAMS,
  payload
});
export const resetAttendanceContext = (): RESET_CONTEXT => ({
  type: AttendanceActionType.RESET_CONTEXT
});
