import { ISpendingViewCardProps } from '@attendance-web-app/types/expensesOverview/spendingType';
import { Icon } from '@iconify/react';
interface SpendingViewCardProps {
  spendingViewCardsOutput: Array<ISpendingViewCardProps>;
}
const SpendingViewCard = ({ spendingViewCardsOutput = [] }: SpendingViewCardProps) => {
  return (
    <div className="spendingView__cards">
      {spendingViewCardsOutput.map((item: ISpendingViewCardProps, index) => {
        const itemValue = Number(item.percentageChangeInValue) ?? 0;
        const isNegative = Number(itemValue) < 0;
        const isNeutral = Number(itemValue) === 0;

        return (
          <div className="spendingView__cards--card" key={index}>
            <div className="topContainer">
              <div>
                <p className="value body-regular-semibold">{item.value}</p>
                <p className="title body-small-regular color-g03">{item.title}</p>
              </div>

              <div>{item.icon}</div>
            </div>
            <div className="bottomContainer">
              <div
                className={`body-regular-bold ${
                  isNeutral ? 'neutralContainer' : isNegative ? 'negativeContainer' : 'positiveContainer'
                }`}
              >
                {Math.abs(itemValue) !== 0 && (
                  <Icon
                    icon={`fluent-mdl2:${isNegative ? 'down' : 'up'}`}
                    height={14}
                    width={14}
                    className={`${isNeutral ? 'neutralTitle' : isNegative ? 'negativeTitle' : 'positiveTitle'}`}
                  />
                )}
                <p
                  className={`body-regular ${
                    isNeutral ? 'neutralTitle' : isNegative ? 'negativeTitle' : 'positiveTitle'
                  }`}
                >
                  {Math.abs(itemValue)} %
                </p>
              </div>
              <p className="body-small-regular color-g03">vs Yesterday</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SpendingViewCard;
