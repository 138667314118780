import { initReducersProps } from '../reducers/initReducer';
export enum InitActionEnum {
  SAVE_INIT_SUCCESS = 'SAVE_INIT_SUCCESS',
  SAVE_LOGIN_DATA_SUCCESS = 'SAVE_LOGIN_DATA_SUCCESS'
}

export type initAction = {
  type: InitActionEnum.SAVE_INIT_SUCCESS;
  payload: initReducersProps;
};
export type loginAction = {
  type: InitActionEnum.SAVE_LOGIN_DATA_SUCCESS;
  payload: initReducersProps;
};

export type InitAction = initAction | loginAction;
