import { Calendar } from '../_header/Calendar';

import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { IEmployeeDetails } from '@attendance-web-app/types/employee/type';
import { CSVLink } from 'react-csv';
import { DropdownMenu } from '../_header/DropdownMenu';
import { useRecordHeaderHook } from './hooks/useRecordHeaderHook';

export const initialEmployeeDetailsState: IEmployeeDetails = {
  id: 0,
  name: '',
  email: '',
  mobile: '',
  department: '',
  position: '',
  profilePhoto: '',
  employeeIdNo: '',
  designation: {
    id: 0,
    name: ''
  },
  supervisor: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  HOD: {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    employeeIdNo: '',
    position: '',
    profilePhoto: '',
    department: {
      name: ''
    },
    designation: {
      name: ''
    }
  },
  attendances: []
};

const Header = ({
  selectedAttendance,
  employeeRecords
}: {
  selectedAttendance?: IEmployeeDetails[];
  employeeRecords: IEmployeeDetails;
}): JSX.Element => {
  const { Periodicity, attendanceDataToDownload, onSelection, getTimePeriod, handleDownloadCsv } =
    useRecordHeaderHook(selectedAttendance);
  return (
    <div className="record-header d-flex justify-content-between flex-wrap">
      <div className="h3-semibold attendance-header header-left-side ">Attendance Record</div>

      <div className="d-flex header-right-side">
        <div className="cnd dropdown-spacing customCalender">
          <Calendar />
        </div>
        <div className="single-dropdown drop individual-dropdown dropdown-spacing">
          <DropdownMenu options={Periodicity} onSelection={onSelection} getTimePeriod={getTimePeriod} />
        </div>
        {selectedAttendance ? (
          <>
            {employeeRecords?.attendances?.length !== 0 ? (
              attendanceDataToDownload?.length !== 0 ? (
                <CSVLink data={attendanceDataToDownload} filename={'AtendanceList.csv'}>
                  <button className="btn download-button">
                    <div className="d-flex flex-row " style={{ alignItems: 'center' }}>
                      <span className="me-2"> Export </span>
                      <Icons.Downloading height={16} width={16} />
                    </div>
                  </button>
                </CSVLink>
              ) : (
                <button
                  className="btn download-button"
                  onClick={() => {
                    handleDownloadCsv(employeeRecords.employeeIdNo as string);
                  }}
                >
                  <div className="d-flex flex-row " style={{ alignItems: 'center' }}>
                    <span className="me-2"> Export </span>
                    <Icons.Downloading height={16} width={16} />
                  </div>
                </button>
              )
            ) : (
              <button
                className="btn"
                disabled
                onClick={() => {
                  handleDownloadCsv(employeeRecords.employeeIdNo as string);
                }}
              >
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <span className="me-2 "> Export </span>
                  <Icons.DownloadingDisabled height={16} width={16} />
                </div>
              </button>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Header;
