import {
  getIosStringSliceDate,
  getTimePeriodForPayload
} from '@attendance-web-app/components/_pages/_Dashboard/hooks/utils';
import { useAdvanceFilterSidebar } from '@attendance-web-app/components/_pages/_header/hooks/useAdvanceFilterSidebar';
import {
  Add,
  resetAttendanceContext,
  saveAttendanceParamsOnly
} from '@attendance-web-app/global-state/actions/attendanceAction';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { getdepartmentList as getDepartmentList } from '@attendance-web-app/services/department.services';
import { getDesignationOptionType } from '@attendance-web-app/services/designation.service';
import { downloadCsv } from '@attendance-web-app/services/download.service';
import employeeServices from '@attendance-web-app/services/employee.services';
import { getHodList } from '@attendance-web-app/services/hod.services';
import { selectedOptionType } from '@attendance-web-app/types';
import {
  findArrayDifference,
  joinArrayWithComma,
  prepareAttendanceToDownload,
  returnArrayOfObjectIfValueMatchesFromArrayOfString,
  returnArrayOfObjectIfValueNotMatchesFromArrayOfString,
  returnArrayOfStringFromObjectKey
} from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import { format } from 'date-fns';
import { useState } from 'react';
import { IHandlePaginateClick, ISelectedOptionState, newOptionType } from '../type';
import { getAttendanceListApi } from './useAttendanceListApi';

export const useAttendanceListScreen = () => {
  const adminUser =
    window.localStorage.getItem('admin_user') !== 'undefined' ? window.localStorage.getItem('admin_user') : null;

  const userRole = JSON.parse(adminUser as string)?.role?.role ?? '';

  // context State,
  const { attendanceState, attendanceDispatch } = useAttendanceContext();
  const { paramsValue: contextParams, selectedAttendanceList, departmentTypes } = attendanceState;

  const attendanceData = selectedAttendanceList && selectedAttendanceList?.length > 0 ? selectedAttendanceList : [];
  const attendanceDataToDownload = prepareAttendanceToDownload(attendanceData);
  const departmentSelectedOptions = attendanceState?.departmentSelectedOption?.value ?? [];
  const hodSelectedOptions = attendanceState?.hodSelectedOption?.value ?? [];
  const designationSelectedOptions = attendanceState?.designationSelectedOption?.value ?? [];
  const supervisorSelectedOptions = attendanceState?.supervisorSelectedOption?.value ?? [];
  const dropOffServicesSelectedOptions = attendanceState.selectedDropOffServices?.value?.toString();

  // Internal States.
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ISelectedOptionState>({
    selectedDate: '',
    departmentSelectedOption: [],
    designationSelectedOption: [],
    hodSelectedOption: [],
    supervisorSelectedOption: [],
    selectedDropOffServices: {
      label: '',
      value: ''
    }
  });

  // custom hooks
  const { openAdvanceFilter, showAdvancedFilter, toggleAdvancedFilter, cancelAdvancedFilter } =
    useAdvanceFilterSidebar();
  const { refetchQuery: refetchDepartmentTypes } = useCustomListApi({
    queryKey: ['departmentList'],
    getApiFn: getDepartmentList,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchDesignationTypes } = useCustomListApi({
    queryKey: ['designationList'],
    getApiFn: getDesignationOptionType,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchAttendanceList } = useCustomListApi({
    queryKey: ['attendanceList'],
    getApiFn: getAttendanceListApi,
    enabled: false,
    params: {}
  });

  const { refetchQuery: refetchHodTypes } = useCustomListApi({
    queryKey: ['hodList'],
    getApiFn: getHodList,
    enabled: false,
    params: {}
  });
  const { refetchQuery: refetchSupervisorTypes } = useCustomListApi({
    queryKey: ['supervisorList'],
    getApiFn: employeeServices.supervisorList,
    enabled: false,
    params: {}
  });
  // reuseable Refetch
  const refetchAttendanceListHelper = (payload: any) => {
    refetchAttendanceList(payload)
      .then((response: any) => {
        attendanceDispatch(
          Add({
            attendance: response.attendance,
            totalCount: response.totalCount,
            totalPages: response.totalPages
          })
        );
      })
      .catch(err => {
        handleErrorMessage(err);
      });
  };

  // action listeners
  // when advanceFilter is shown fetch some of the types for dropdown
  const saveOptionsTypesToAttendanceContextFromServer = (
    type: 'departmentTypes' | 'hodTypes' | 'designationTypes' | 'superVisorTypes',
    options: { label: string; value: string; departmentIdNo?: string; departmentName?: string }[]
  ) => {
    attendanceDispatch(
      Add({
        [type]: options
      })
    );
  };

  const saveSelectedDesignationDataToAttendanceContext = (selectedDesignations: any) => {
    attendanceDispatch(
      Add({
        designationSelectedOption: {
          selected: 'DESIGNATION_TYPE',
          value: selectedDesignations
        }
      })
    );
  };

  const saveSelectedSupervisorDataToAttendanceContext = (selectedSupervisors: any) => {
    attendanceDispatch(
      Add({
        supervisorSelectedOption: {
          selected: 'SUPERVISOR_TYPE',
          value: selectedSupervisors
        }
      })
    );
  };
  const actualShowAdvancedFilterHandler = () => {
    if (!showAdvancedFilter) {
      const params = {
        name: '',
        limit: 100,
        offset: 0
      };
      refetchDepartmentTypes(params)
        .then((response: any) => {
          const department = response?.data?.departments.map((departmentType: any) => {
            return {
              label: departmentType.name,
              value: departmentType.id,
              departmentIdNo: departmentType.departmentIdNo
            };
          });
          saveOptionsTypesToAttendanceContextFromServer('departmentTypes', department);
        })
        .catch(err => {
          handleErrorMessage(err);
        });

      refetchHodTypes(params)
        .then((response: any) => {
          const hod = response?.data?.hod.map((hodType: any) => {
            return {
              label: hodType.employee.name,
              value: hodType.id,
              departmentName: hodType.department.name,
              departmentIdNo: hodType.department.departmentIdNo
            };
          });
          saveOptionsTypesToAttendanceContextFromServer('hodTypes', hod);
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
    toggleAdvancedFilter();
  };

  // event listeners
  const handleOpen = () => {
    setOpen(true);
    document.body.style.overflowY = 'hidden';
  };
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflowY = 'auto';
  };

  /* 
  @params {name:string,limit:number,offset:number,department:string,hod:string,supervisor:string,period:string}, actionPayload:{actionPayload}, reset:boolean to identify the action for clearAll

  This function is the helper function for handling the data refetching and saving the new change value to context.

  Refetch from server with given params.
  Save params to context for future refetch operations
  */
  const attendanceCommonHandler = (params: any, actionPayload: any, clearAll = false) => {
    const payload = {
      ...contextParams,
      offset: 0,
      ...params
    };

    refetchAttendanceListHelper(payload);

    if (!clearAll) {
      attendanceDispatch(Add(actionPayload));
      attendanceDispatch(
        saveAttendanceParamsOnly({
          ...params,
          offset: 0
        })
      );
    }
  };

  // when user change search input
  const onChangeSearchHandler = (name: string) => {
    const payload = {
      name,
      offset: 0
    };
    const actionPayload = {};
    attendanceCommonHandler(payload, actionPayload);
  };

  const changingSelectedStateHelperFunction = (
    selectedOptionsTitle:
      | 'departmentSelectedOption'
      | 'designationSelectedOption'
      | 'hodSelectedOption'
      | 'supervisorSelectedOption'
      | 'selectedDropOffServices',
    options: any
  ) => {
    setSelectedOptions({
      ...selectedOptions,
      [selectedOptionsTitle]: options
    });
  };

  const fetchSupervisorOfSelectedHodForBothChipAndFilter = ({
    selectedHods,
    isChip = false
  }: {
    selectedHods: any;
    options: any;
    isChip?: boolean;
  }) => {
    // if no hod is selected remove supervisor data and selected supervisor context
    if (!selectedHods) {
      saveOptionsTypesToAttendanceContextFromServer('superVisorTypes', []);
      saveSelectedSupervisorDataToAttendanceContext([]);
      setSelectedOptions(prev => ({
        ...prev,
        supervisorSelectedOption: []
      }));

      if (isChip) {
        const supervisorPayload = { supervisor: '' } as any;
        attendanceDispatch(saveAttendanceParamsOnly({ ...supervisorPayload }));
      }
    } else {
      refetchSupervisorTypes({ hod: selectedHods })
        .then((response: any) => {
          const supervisorOfSelectedHods = response?.data?.data?.supervisors?.map(
            (supervisor: { id: number; name: string }) => {
              return {
                label: supervisor.name,
                value: supervisor.id
              };
            }
          );

          // remove selected supervisor when its associated hod is unselected
          const filteredSelectedSupervisors = supervisorOfSelectedHods.filter((supervisor: any) =>
            selectedOptions.supervisorSelectedOption.some(
              (selectedSupervisor: any) => selectedSupervisor.value === supervisor.value
            )
          );
          saveOptionsTypesToAttendanceContextFromServer('superVisorTypes', supervisorOfSelectedHods);

          setSelectedOptions(prev => ({
            ...prev,
            supervisorSelectedOption: filteredSelectedSupervisors
          }));

          if (isChip) {
            const selectedSupervisorParams = joinArrayWithComma(filteredSelectedSupervisors, 'value');
            const supervisorPayload = { supervisor: selectedSupervisorParams } as any;
            attendanceDispatch(saveAttendanceParamsOnly({ ...supervisorPayload }));
            saveSelectedSupervisorDataToAttendanceContext(filteredSelectedSupervisors);
          }
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
  };
  const fetchDesignationOfSelectedDepartmentForBothChipAndFilter = ({
    selectedDepartments,
    options,
    isChip = false,
    isCallFromHod = false
  }: {
    selectedDepartments: any;
    options: any;
    isChip?: boolean;
    isCallFromHod?: boolean;
  }) => {
    // if no department is selected remove designation data amd selected designation context
    if (!selectedDepartments) {
      saveOptionsTypesToAttendanceContextFromServer('designationTypes', []);
      saveSelectedDesignationDataToAttendanceContext([]);
      setSelectedOptions(prev => ({
        ...prev,
        designationSelectedOption: []
      }));

      if (isChip) {
        const designationPayload = { designation: '' } as any;
        attendanceDispatch(saveAttendanceParamsOnly({ ...designationPayload }));
      }

      if (!isCallFromHod) {
        setSelectedOptions(prev => ({
          ...prev,
          departmentSelectedOption: options
        }));
      }
    } else {
      refetchDesignationTypes({ department: selectedDepartments })
        .then((response: any) => {
          const designationsOfSelectedDepartments = response?.data?.map((designation: { id: number; name: string }) => {
            return {
              label: designation?.name,
              value: designation?.id
            };
          });

          // remove selected designation when its associated department is unselected
          const filteredSelectedDesignations = designationsOfSelectedDepartments.filter((designation: any) =>
            selectedOptions.designationSelectedOption.some(
              (selectedDesignation: any) => selectedDesignation.value === designation.value
            )
          );
          saveOptionsTypesToAttendanceContextFromServer('designationTypes', designationsOfSelectedDepartments);

          setSelectedOptions(prev => ({
            ...prev,
            designationSelectedOption: filteredSelectedDesignations
          }));
          if (!isCallFromHod) {
            setSelectedOptions(prev => ({
              ...prev,
              departmentSelectedOption: options
            }));
          }

          if (isChip) {
            const selectedDesignationParams = joinArrayWithComma(filteredSelectedDesignations, 'value');
            const designationPayload = { designation: selectedDesignationParams } as any;
            attendanceDispatch(saveAttendanceParamsOnly({ ...designationPayload }));
            saveSelectedDesignationDataToAttendanceContext(filteredSelectedDesignations);
          }
        })
        .catch(err => {
          handleErrorMessage(err);
        });
    }
  };

  const changingHodSelectedHelperFunction = (hodOptions: newOptionType[], departmentOptions: newOptionType[]) => {
    setSelectedOptions({
      ...selectedOptions,
      hodSelectedOption: hodOptions,
      departmentSelectedOption: departmentOptions
    });
  };

  const onChangeSelectHandler = (type: string, options: any) => {
    const dateActionPayload = {
      department: attendanceState.departmentTypes,
      hod: attendanceState.hodTypes
    };

    switch (type) {
      case selectedOptionType.DATE:
        const date = options ? format(new Date(options), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');
        setSelectedOptions({
          ...selectedOptions,
          selectedDate: date
        });
        const datePayload = {
          date: date
        };
        attendanceCommonHandler(datePayload, dateActionPayload);
        break;

      case selectedOptionType.PERIOD_OPTION:
        const periodPayload = {
          period: getTimePeriodForPayload(options.label ?? '')
        };
        attendanceCommonHandler(periodPayload, dateActionPayload);
        break;

      case selectedOptionType.DEPARTMENT_TYPE:
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({ selectedDepartments, options });
        break;

      case selectedOptionType.DESIGNATION_TYPE:
        changingSelectedStateHelperFunction('designationSelectedOption', options);
        break;

      case selectedOptionType.HOD_TYPE:
        if (options.length > selectedOptions.hodSelectedOption.length) {
          const departmentIdNos = returnArrayOfStringFromObjectKey(options, 'departmentIdNo');
          const filteredDepartments = returnArrayOfObjectIfValueMatchesFromArrayOfString(
            departmentTypes,
            departmentIdNos,
            'departmentIdNo'
          );

          const resultsArr: any = [];
          filteredDepartments.forEach((department: any) => {
            const exists = selectedOptions?.departmentSelectedOption.some(
              selected => selected.departmentIdNo === department.departmentIdNo
            );

            if (!exists) {
              resultsArr.push(department);
            }
          });

          changingHodSelectedHelperFunction(options, [...resultsArr, ...selectedOptions?.departmentSelectedOption]);
        } else {
          const differenceValue = findArrayDifference(options, selectedOptions.hodSelectedOption, 'departmentIdNo');
          const departmentIdNos = returnArrayOfStringFromObjectKey(differenceValue, 'departmentIdNo');

          const filteredDepartments = returnArrayOfObjectIfValueNotMatchesFromArrayOfString(
            selectedOptions.departmentSelectedOption,
            departmentIdNos,
            'departmentIdNo'
          );
          changingHodSelectedHelperFunction(options, filteredDepartments ?? []);
        }

        const selectedHods = joinArrayWithComma(options, 'value');
        fetchSupervisorOfSelectedHodForBothChipAndFilter({ selectedHods, options });
        break;

      case selectedOptionType.SUPERVISOR_TYPE:
        changingSelectedStateHelperFunction('supervisorSelectedOption', options);
        break;
      case selectedOptionType.DROP_OFF_SERVICE:
        changingSelectedStateHelperFunction('selectedDropOffServices', options);
        break;
      default:
        break;
    }
  };

  /* 
  @params selectedOptionsTitle:string, options:any, paramTitle:string
  This function is the helper function for handling the chipChangeHandler Events.

  1) which change the state of selectedOptions with selectedOptionsTitle as key.
  2)and options value is value which can be anything like date, string, array of value and label object
  3) which prepare the params for getting server request with paramTitle.
  4) and the value is also saved to the context for future get request.

  */
  const chipChangeHandlerHelper = (
    selectedOptionsTitle:
      | 'departmentSelectedOption'
      | 'designationSelectedOption'
      | 'hodSelectedOption'
      | 'supervisorSelectedOption'
      | 'selectedDropOffServices',
    options: any,
    paramTitle: string,
    selectionOptionType: string
  ) => {
    // setting state
    changingSelectedStateHelperFunction(selectedOptionsTitle, options);

    // setting context
    const payload = {
      ...contextParams,
      offset: 0,
      [paramTitle]:
        selectionOptionType !== selectedOptionType.DROP_OFF_SERVICE
          ? joinArrayWithComma(options, 'value')
          : options.value
    };
    const actionPayload = {
      ...attendanceState,
      [selectedOptionsTitle]:
        selectionOptionType !== selectedOptionType.DROP_OFF_SERVICE
          ? {
              selected: selectionOptionType,
              value: options
            }
          : options
    };

    // fetch
    attendanceCommonHandler(payload, actionPayload);
  };

  const chipChangeDepartmentAndHodOptions = (departmentOptions: newOptionType[], hodOptions: newOptionType[]) => {
    const payload = {
      ...contextParams,
      department: joinArrayWithComma(departmentOptions, 'value'),
      hod: joinArrayWithComma(hodOptions, 'value')
    };
    const actionPayload = {
      ...attendanceState,
      departmentSelectedOption: {
        selected: selectedOptionType.DEPARTMENT_TYPE,
        value: departmentOptions.length > 0 ? departmentOptions : []
      },
      hodSelectedOption: {
        selected: selectedOptionType.HOD_TYPE,
        value: hodOptions
      }
    } as any;

    attendanceCommonHandler(payload, actionPayload);
  };

  const changingBothHodChipAndAdvanceFilterDropDown = (
    options: newOptionType[],
    hodSelectedOption: newOptionType[] = selectedOptions.hodSelectedOption,
    isChip = false
  ) => {
    if (options.length > hodSelectedOption.length) {
      const departmentIdNos = returnArrayOfStringFromObjectKey(options, 'departmentIdNo');
      const filteredDepartments = returnArrayOfObjectIfValueMatchesFromArrayOfString(
        departmentTypes,
        departmentIdNos,
        'departmentIdNo'
      );

      const resultsArr: any = [];
      filteredDepartments.forEach((department: any) => {
        const exists = selectedOptions?.departmentSelectedOption.some(
          selected => selected.departmentIdNo === department.departmentIdNo
        );

        if (!exists) {
          resultsArr.push(department);
        }
      });
      const finalSelectedDepartmentsValue = [...resultsArr, ...selectedOptions?.departmentSelectedOption];

      changingHodSelectedHelperFunction(options, finalSelectedDepartmentsValue);
      fetchDesignationOfSelectedDepartmentForBothChipAndFilter({
        selectedDepartments: joinArrayWithComma(finalSelectedDepartmentsValue, 'value'),
        options: finalSelectedDepartmentsValue,
        isCallFromHod: true
      });

      if (isChip) {
        chipChangeDepartmentAndHodOptions(finalSelectedDepartmentsValue, options);
        const supervisorPayload = { supervisor: '' } as any;
        attendanceDispatch(saveAttendanceParamsOnly({ ...supervisorPayload }));
      }
    } else {
      const differenceValue = findArrayDifference(options, hodSelectedOption, 'departmentIdNo');
      const departmentIdNos = returnArrayOfStringFromObjectKey(differenceValue, 'departmentIdNo');

      const filteredDepartments = returnArrayOfObjectIfValueNotMatchesFromArrayOfString(
        selectedOptions.departmentSelectedOption,
        departmentIdNos,
        'departmentIdNo'
      );

      changingHodSelectedHelperFunction(options, filteredDepartments ?? []);
      fetchDesignationOfSelectedDepartmentForBothChipAndFilter({
        selectedDepartments: joinArrayWithComma(filteredDepartments, 'value'),
        options: filteredDepartments,
        isCallFromHod: true
      });
      if (isChip) {
        chipChangeDepartmentAndHodOptions(filteredDepartments, options);
      }
    }
  };
  // when user change any of the value of selected options on chip.
  const onChipChangeSelectHandler = (type: string, options: any) => {
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        chipChangeHandlerHelper('departmentSelectedOption', options, 'department', selectedOptionType.DEPARTMENT_TYPE);
        const selectedDepartments = joinArrayWithComma(options, 'value');
        fetchDesignationOfSelectedDepartmentForBothChipAndFilter({ selectedDepartments, options, isChip: true });
        break;

      case selectedOptionType.DESIGNATION_TYPE:
        chipChangeHandlerHelper(
          'designationSelectedOption',
          options,
          'designation',
          selectedOptionType.DESIGNATION_TYPE
        );
        break;

      case selectedOptionType.HOD_TYPE:
        changingBothHodChipAndAdvanceFilterDropDown(options, selectedOptions.hodSelectedOption, true);
        const selectedHods = joinArrayWithComma(options, 'value');
        fetchSupervisorOfSelectedHodForBothChipAndFilter({ selectedHods, options, isChip: true });
        break;

      case selectedOptionType.SUPERVISOR_TYPE:
        chipChangeHandlerHelper('supervisorSelectedOption', options, 'supervisor', selectedOptionType.SUPERVISOR_TYPE);
        break;
      case selectedOptionType.DROP_OFF_SERVICE:
        chipChangeHandlerHelper(
          'selectedDropOffServices',
          options,
          'dropOffOption',
          selectedOptionType.DROP_OFF_SERVICE
        );
        break;

      default:
        break;
    }
  };

  // when user click the cross button on chip container in attendance list
  const onResetHandler = (type: string) => {
    let payload,
      actionPayload = {};
    switch (type) {
      case selectedOptionType.DEPARTMENT_TYPE:
        payload = {
          department: '',
          designation: ''
        };
        actionPayload = {
          departmentSelectedOption: {
            selected: '',
            value: []
          },
          designationSelectedOption: {
            selected: '',
            value: []
          },
          designationTypes: []
        };
        setSelectedOptions(prev => ({
          ...prev,
          departmentSelectedOption: []
        }));
        break;
      case selectedOptionType.DESIGNATION_TYPE:
        payload = {
          designation: ''
        };
        actionPayload = {
          designationSelectedOption: {
            selected: '',
            value: []
          }
        };
        setSelectedOptions({
          ...selectedOptions,
          designationSelectedOption: []
        });
        break;
      case selectedOptionType.HOD_TYPE:
        payload = {
          hod: ''
        };
        actionPayload = {
          hodSelectedOption: {
            selected: '',
            value: []
          }
        };
        setSelectedOptions({
          ...selectedOptions,
          hodSelectedOption: []
        });
        break;

      case selectedOptionType.DROP_OFF_SERVICE:
        payload = {
          dropOffOption: ''
        };
        actionPayload = {
          selectedDropOffServices: {
            label: '',
            value: ''
          }
        };
        setSelectedOptions({
          ...selectedOptions,
          selectedDropOffServices: {
            label: '',
            value: ''
          }
        });
        break;

      case selectedOptionType.SUPERVISOR_TYPE:
        const supervisorPayload = {
          supervisor: ''
        };
        const supervisorActionPayload = {
          supervisorSelectedOption: {
            selected: '',
            value: []
          }
        };
        attendanceCommonHandler(supervisorPayload, supervisorActionPayload);
        setSelectedOptions({
          ...selectedOptions,
          supervisorSelectedOption: []
        });
        break;
      default:
        break;
    }
    attendanceCommonHandler(payload, actionPayload);
  };

  const resetSelectedOptionsFromAdvanceFilter = () => {
    setSelectedOptions({
      selectedDate: '',
      departmentSelectedOption: [],
      designationSelectedOption: [],
      hodSelectedOption: [],
      supervisorSelectedOption: [],
      selectedDropOffServices: {
        label: '',
        value: ''
      }
    });
  };

  // when user click clear all button
  const onClearAllFilter = () => {
    const payload = {
      department: '',
      designation: '',
      hod: '',
      supervisor: '',
      dropOffOption: ''
    };

    attendanceCommonHandler(payload, {}, true);
    attendanceDispatch(resetAttendanceContext());
    resetSelectedOptionsFromAdvanceFilter();
  };

  // when user click the apply button on advanced filter
  const onApplyHandler = () => {
    const payload = {
      ...contextParams,
      offset: 0,
      department: joinArrayWithComma(selectedOptions.departmentSelectedOption, 'value'),
      hod: joinArrayWithComma(selectedOptions.hodSelectedOption, 'value'),
      designation: joinArrayWithComma(selectedOptions.designationSelectedOption, 'value'),
      supervisor: joinArrayWithComma(selectedOptions.supervisorSelectedOption, 'value'),
      dropOffOption: selectedOptions.selectedDropOffServices.value
    };
    const actionPayload = {
      ...attendanceState,
      departmentSelectedOption: {
        selected: selectedOptionType.DEPARTMENT_TYPE,
        value: selectedOptions.departmentSelectedOption
      },
      designationSelectedOption: {
        selected: selectedOptionType.DESIGNATION_TYPE,
        value: selectedOptions.designationSelectedOption
      },
      hodSelectedOption: {
        selected: selectedOptionType.HOD_TYPE,
        value: selectedOptions.hodSelectedOption
      },
      supervisorSelectedOption: {
        selected: selectedOptionType.SUPERVISOR_TYPE,
        value: selectedOptions.supervisorSelectedOption
      },
      selectedDropOffServices: {
        ...selectedOptions.selectedDropOffServices
      }
    } as any;

    // attendanceDispatch(Add(actionPayload));
    attendanceCommonHandler(payload, actionPayload);
  };

  const handleDownloadCsv = () => {
    const date = getIosStringSliceDate(contextParams?.date);
    const department = attendanceState?.departmentTypes?.map(item => item.value).join('') ?? '';
    const name = contextParams?.name ?? '';
    const period = contextParams?.period ?? '';
    downloadCsv(date, department, name, period);
  };

  // when user click the pagination item
  const handlePaginateClick = (event: IHandlePaginateClick) => {
    const limit = attendanceState.paramsValue?.limit ?? 10;
    const offset = event.selected * limit;
    const payload = {
      // ...contextParams,
      limit,
      offset
    };
    const actionPayload = {
      ...contextParams,
      limit,
      offset
    };
    refetchAttendanceListHelper(actionPayload);
    attendanceDispatch(
      saveAttendanceParamsOnly({
        ...payload
      } as any)
    );
  };

  // when user click the pagination item
  const handleLimitChange = (limit: number) => {
    const payload = {
      limit,
      offset: 0
    };
    const actionPayload = {
      ...contextParams,
      ...payload
    };
    refetchAttendanceListHelper(actionPayload);
    attendanceDispatch(
      saveAttendanceParamsOnly({
        ...payload
      } as any)
    );
  };

  return {
    open,
    userRole,
    handleClose,
    handleOpen,
    selectedOptions,
    showAdvancedFilter,
    actualShowAdvancedFilterHandler,
    toggleAdvancedFilter,
    cancelAdvancedFilter,
    onChangeSelectHandler,
    onChipChangeSelectHandler,
    onResetHandler,
    onApplyHandler,
    attendanceState,
    onChangeSearchHandler,
    handleDownloadCsv,
    attendanceDataToDownload,
    departmentTypes,
    handleLimitChange,
    handlePaginateClick,
    onClearAllFilter,
    departmentSelectedOptions,
    hodSelectedOptions,
    designationSelectedOptions,
    supervisorSelectedOptions,
    openAdvanceFilter,
    dropOffServicesSelectedOptions
  };
};
