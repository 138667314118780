import React from 'react';
interface IBalanceSheetCard {
  amount: number;
  title: string;
  icon: React.ReactNode;
}

const BalanceSheetCard = ({ amount, title, icon }: IBalanceSheetCard) => {
  return (
    <div className="balance-sheet-card">
      <div className="balance-sheet-card-content">
        <span className="body-regular-semibold">Rs. {amount}</span>
        <span className="body-small-regular balance-sheet-card-title">{title}</span>
      </div>
      <div>{icon}</div>
    </div>
  );
};

export default BalanceSheetCard;
