import { saveLogsReducersProps } from '../reducers/logsReducers.d';
export enum SaveLogsActionEnum {
  SAVE_LOGS_SUCCESS = 'SAVE_LOGS_SUCCESS'
}

export type saveLogsAction = {
  type: SaveLogsActionEnum.SAVE_LOGS_SUCCESS;
  payload: saveLogsReducersProps;
};

export type SaveLogsAction = saveLogsAction;
