import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IProps {
  handleAddDesignation?: (name?: string) => void;
  handleEdit?: (editableData: { id: number; designation: string }) => void;
  initialDesignation?: string;
  id?: number;
}
interface FormData {
  designation: string;
}

const DesignationForm = ({ handleAddDesignation, handleEdit, initialDesignation, id }: IProps) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FormData>();

  useEffect(() => {
    if (initialDesignation) {
      setValue('designation', initialDesignation);
    }
  }, [initialDesignation]);

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    try {
      if (handleEdit && id) {
        await handleEdit({ id, designation: data.designation });
      } else if (handleAddDesignation) {
        await handleAddDesignation(data.designation);
      }
      reset();
    } catch (error: any) {
      if (error.response && error.response.data) {
        setError('designation', { message: error.response.data.message });
      } else {
        setError('designation', { message: 'An error occurred' });
      }
    }
  };

  return (
    <div className="designation-form">
      <form action="" onSubmit={handleSubmit(onSubmit)} id="invite-designation-form">
        <Form.Floating>
          <Form.Control
            className="body-regular pb-2"
            id="designations"
            type="text"
            autoFocus
            {...register('designation', { required: true })}
            isInvalid={!!errors.designation}
            autoComplete="off"
          />
          <label
            htmlFor="floatingInputCustom"
            className={`ms-2 customlabel-id designation-lable ${errors.designation ? 'text-danger' : ''}`}
          >
            <span className=" designation-lable">
              Designation <span className="form-astrik">*</span>
            </span>
          </label>
          {errors.designation && <ErrorLabel message={errors.designation.message || 'Designation is required'} />}
        </Form.Floating>
      </form>
    </div>
  );
};

export default DesignationForm;
