type Option = {
  value: string | number;
  label: string;
};
interface ISelectDropDownContainer {
  selectedOptions: Option;
  options?: Array<Option>;
  isVisible: boolean;
  onChangeSelectHandler: (options: Option) => void;
  selectOptionType?: string;
  onClose?: any;
  isChip?: boolean;
  isAbsolute?: boolean;
}
const SelectDropDownContainer = ({
  options = [],
  selectedOptions,
  isVisible,
  onChangeSelectHandler,
  onClose,
  isAbsolute = true
}: ISelectDropDownContainer) => {
  const isOptionSelected = (option: Option) => {
    return selectedOptions.value === option.value;
  };
  const onClickHandler = (option: Option) => {
    onChangeSelectHandler(option);
    onClose();
  };

  return (
    isVisible &&
    options?.length > 0 && (
      <div
        className={`dropdown-options w-100 py-1 px-1 flex ${isAbsolute ? 'position-absolute' : 'less-height'} `}
        style={{
          zIndex: 111,
          backgroundColor: 'white',
          top: 50,
          border: '1px solid #edeeec',
          borderRadius: '4px'
        }}
      >
        {options?.map((option: any) => {
          const isSelected = isOptionSelected(option);
          return (
            <label
              key={option.value}
              className={`dropdown-option ${isSelected ? 'selected' : ''}`}
              onClick={() => onClickHandler(option)}
            >
              <span className={`${isSelected ? 'dropdown_selected' : ''}`}>{option.label}</span>
            </label>
          );
        })}
      </div>
    )
  );
};

export default SelectDropDownContainer;
