import { colors } from '@attendance-web-app/theme/colors';
import { Icon } from '@iconify/react';
import { useCustomPaginationDropDown } from './hooks/useCustomPaginationDropdown';

interface ICustomPaginationDropdown {
  handleLimitChange: (limit: number) => void;
  limArr?: Array<number>;
  pageLimit?: number;
}
const CustomPaginationDropDown = ({ handleLimitChange, limArr, pageLimit }: ICustomPaginationDropdown) => {
  const { isOpen, limit, limitArr, handleDropdownTrigger, onLimitChanged, dropdownRef } = useCustomPaginationDropDown(
    handleLimitChange,
    limArr,
    pageLimit
  );

  return (
    <div className="customPaginationDropDown" ref={dropdownRef}>
      <div className={`customPaginationDropDown__trigger ${isOpen ? 'isOpened' : ''}`} onClick={handleDropdownTrigger}>
        <span className="limit body-regular colors-p02">{limit}</span>
        <span className="limitImage">
          {isOpen ? (
            <Icon icon="teenyicons:up-solid" height={12} width={12} color={colors.colorP02} />
          ) : (
            <Icon icon="teenyicons:down-solid" height={12} width={12} color={colors.colorP02} />
          )}
        </span>
      </div>
      {isOpen && (
        <div className="customPaginationDropDown__dropdown">
          {limitArr.map((item, index) => (
            <div
              className={`text-center border-bottom p-1 ${item === limit ? 'isSelected' : ''}`}
              onClick={onLimitChanged(item)}
              key={index}
            >
              <span className="body-regular colors-p01">{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomPaginationDropDown;
