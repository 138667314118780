import axios from 'axios';

const setAuthToken = (accesstoken: string | null): void => {
  if (accesstoken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accesstoken}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;
