export enum ViewListActionType {
  setViewListAction = 'setViewListAction'
}

export type SetViewListAction = {
  type: ViewActionType.setViewListAction;
  payload: number;
};

export type ViewListAction = SetViewListAction;
