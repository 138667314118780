import { getpayoutListApi } from '@attendance-web-app/services/payout.services';
import { getSpecificVendorTransactionListApi } from '@attendance-web-app/services/transaction.services';
import {
  deactivateVendorApi,
  getSpecificVendorDetail,
  getVendorListApi
} from '@attendance-web-app/services/vendor.services';
import { getVendorPayout } from '@attendance-web-app/services/vendorPayout.services';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';

export const useAllVenderView = (id?: string) => {
  const {
    data: vendorData,
    refetch: refetchVendorDetails,
    isLoading: vendorLoading,
    isRefetching: vendorRefetching
  } = useCustomListApi({
    queryKey: ['vendorsList'],
    getApiFn: getVendorListApi,
    enabled: true,
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: true
    },
    params: {}
  });

  const { data: payoutData, refetch: refetchPayoutData } = useCustomListApi({
    queryKey: ['payoutList'],
    getApiFn: getpayoutListApi,
    enabled: true,
    params: {}
  });

  const { data: deactivateVendor } = useCustomListApi({
    queryKey: ['deactivateVendorList'],
    getApiFn: deactivateVendorApi,
    enabled: false,
    params: { id }
  });
  // fetch specific vendor's payout
  const { data: vendorPayoutDetail, refetchQuery: refetchVendorPayoutDetail } = useCustomListApi({
    queryKey: ['specificVendorPayout', id ?? ''],
    getApiFn: getVendorPayout,
    enabled: false,
    params: { id }
  });
  const { data: vendorDetail, refetchQuery: refetchVendorDetail } = useCustomListApi({
    queryKey: ['specificVendor'],
    getApiFn: getSpecificVendorDetail,
    enabled: false,
    params: { id }
  });

  const { data: transactionDetail, refetchQuery: refetchVendorTransactionDetail } = useCustomListApi({
    queryKey: ['specificVendorTransactions'],
    getApiFn: getSpecificVendorTransactionListApi,
    enabled: false,
    params: {
      vendorId: ''
    }
  });
  return {
    vendorData,
    payoutData,
    refetchPayoutData,
    refetchVendorDetails,
    deactivateVendor,
    refetchVendorPayoutDetail,
    vendorDetail,
    refetchVendorDetail,
    vendorPayoutDetail,
    transactionDetail,
    refetchVendorTransactionDetail,
    vendorLoading,
    vendorRefetching
  };
};
