import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import { colors } from '@attendance-web-app/theme/colors';
import { SidebarItem } from '../commonData';

export interface ISidebarMainItem {
  item: SidebarItem;
  sideBarClose: string;
  isActive: boolean;
  isChildLengthGreaterThanZero: boolean;
  sidebarItemClickHandler: (title: string, isChildLengthGreaterThanZero: boolean, route: string | null) => any;
  childLength: number;
  activeSidebar: string;
}

const SidebarMainItem = ({
  item,
  sideBarClose,
  isActive,
  isChildLengthGreaterThanZero,
  sidebarItemClickHandler,
  childLength,
  activeSidebar
}: ISidebarMainItem) => {
  return (
    <div
      className={`sidebarMain__items ${sideBarClose} ${isActive ? 'clicked' : ''} ${
        isChildLengthGreaterThanZero ? 'hasChild' : ''
      }`}
      onClick={sidebarItemClickHandler(item.title, isChildLengthGreaterThanZero, item.route)}
    >
      {isActive && childLength <= 0 && <div className="line" />}

      {/* Sidebar Item */}
      <div className={`sidebarMain__items--iconContainer ${sideBarClose}`}>
        <div>{item.icon(isActive ? colors.colorP02 : colors.colorG02)}</div>
      </div>

      <div className={`sidebarMain__items--titleContainer ${sideBarClose}`}>
        <p className={`sidebarMain__items--title body-regular-semibold ${sideBarClose} ${isActive ? 'clicked' : ''}`}>
          {item.title}
        </p>
        <div
          className={`sidebarMain__items--rightIcon ${sideBarClose} ${item.title === activeSidebar ? 'rotate' : ''}`}
        >
          {isChildLengthGreaterThanZero && (
            <Icons.ArrowRightActive
              height={16}
              width={16}
              stroke={isActive ? colors.colorP02 : colors.colorG02}
              fill="none"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarMainItem;
