import { ISpendingTransaction } from '@attendance-web-app/services/spendingList.services';
import { ILogs } from '@attendance-web-app/types/logs/type';

export const generateSpendingTransactionsCsvContent = (spendingTransactions: ISpendingTransaction[]): string => {
  let csvContent =
    'Biometric ID,Office Id,Employee Name,Email,Department,Transaction Id,Vendor Name,Transaction Date,Amount\n';

  spendingTransactions.forEach(spendingTransaction => {
    const employeeId = spendingTransaction.employeeId;
    const employeeName = spendingTransaction.employeeName;
    const email = spendingTransaction.email;
    const department = spendingTransaction.department;
    const officialIdNo = spendingTransaction?.officialIdNo;
    //const designation = spendingTransaction.designation;

    if (Array.isArray(spendingTransaction.payment)) {
      spendingTransaction.payment.forEach(payment => {
        const transactionIdNo = payment.transactionIdNo;
        const vendorName = payment.vendorName;
        const transactionDate = payment.transactionDate;
        const amount = payment.amount;
        // const remarks = payment.remarks;

        csvContent += `${employeeId},"${officialIdNo}","${employeeName}",${email},"${department}",${transactionIdNo},"${vendorName}","${transactionDate}",${amount}\n`;
      });
    } else {
      console.error(`Invalid payment data for employeeId: ${employeeId}`);
    }
  });

  return csvContent;
};

export const generateLogsCsvContent = (logs: ILogs[]): string => {
  let csvContent = 'Id,Type,Description,Last Updated,Updated by\n';
  logs.forEach(log => {
    csvContent += `${log.id},${log.actionType},"${log.remarks}",${log.timeStamp},${log.employee.name}\n`;
  });
  return csvContent;
};

export const downloadCsv = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
};
