import { Add } from '@attendance-web-app/global-state/actions/attendanceAction';
import { useAttendanceContext } from '@attendance-web-app/global-state/context/AttendanceContext';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
interface IParams {
  date?: string;
  name?: string;
  limit?: number;
  period?: string;
  offset?: number;
  designation?: string;
  hod?: string;
  supervisor?: string;
  department?: string;
  shift?: string;
  dropOffOption?: string;
}
const getDate = (date: string | Date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const getAttendanceListApi = async (params?: IParams, saveAttendanceDataToContext?: (data: any) => void) => {
  const { date, name, limit, offset, department, hod, designation, supervisor, period, shift, dropOffOption } =
    params ?? {};
  try {
    const response = await axios.get(`/attendance`, {
      params: {
        date: date ? getDate(date) : getDate(new Date()),
        name: name ?? '',
        limit: limit ?? 10,
        offset: offset ?? 0,
        department: department ?? '',
        supervisor: supervisor ?? '',
        designation: designation ?? '',
        hod: hod ?? '',
        period: period ?? '',
        shift: shift ?? '',
        dropOffOption: dropOffOption ?? ''
      }
    });
    saveAttendanceDataToContext && saveAttendanceDataToContext(response.data);
    return response.data;
  } catch (error: any) {
    handleErrorMessage(error);
    throw error;
  }
};

const useAttendanceListApi = (params?: IParams) => {
  const queryClient = useQueryClient();
  const { attendanceDispatch } = useAttendanceContext();

  // saving data on context
  const saveAttendanceDataToContext = (responseData: any) => {
    attendanceDispatch(
      Add({
        attendance: responseData.attendance,
        totalCount: responseData.totalCount,
        totalPages: responseData.totalPages
      })
    );
  };
  const { data, isSuccess, isLoading, status, isRefetching, refetch, isFetching, ...query } = useQuery({
    queryKey: ['attendanceList'],
    queryFn: () => getAttendanceListApi(params, saveAttendanceDataToContext),
    enabled: false,
    retryOnMount: false,
    refetchOnMount: false
  });

  // hero function for refetching data with different parameters
  const refetchQuery = async (refetchParams: IParams) => {
    await queryClient.fetchQuery({
      queryKey: ['attendanceList'],
      queryFn: () => getAttendanceListApi(refetchParams, saveAttendanceDataToContext)
    });
  };

  // saving server data in context

  return {
    ...query,
    isLoading,
    isRefetching,
    data,
    isSuccess,
    refetch,
    refetchQuery
  };
};
export default useAttendanceListApi;
