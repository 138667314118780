interface DepartmentDetail {
  name: string;
  departmentId: string;
  employeeCount: number;
  subDepartmentCount: number;
  hodName: string;
  hodDesignation: string;
  hodEmail: string;
}

const DepartmentDetail = ({
  name: departmentName,
  departmentId,
  employeeCount,
  hodName,
  hodDesignation,
  hodEmail,
  subDepartmentCount
}: DepartmentDetail) => {
  return (
    <div className="department-summary pb-1">
      <div className="body-large-regular department-summary-header"> Department Summary</div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Department </div>
        <div className="department-summary-detail">{departmentName?.toUpperCase()}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Total number of employees </div>
        <div className="department-summary-detail">{employeeCount}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Total number of sub department </div>
        <div className="department-summary-detail">{subDepartmentCount}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> Department ID </div>
        <div className="department-summary-detail">{departmentId}</div>
      </div>
      <div className="department-summary-description body-small-regular">
        <div className="department-summary-title"> HOD </div>
        <div className="department-summary-detail">{hodName ? hodName : <span>Unassigned</span>}</div>
        <div className="department-summary-detail">{hodDesignation}</div>
        <div className="department-hod-email">{hodEmail}</div>
      </div>
    </div>
  );
};
export default DepartmentDetail;
